<template>
  <modal
    name="photo-analysis-add-photo-modal"
    :classes="['modal-window']"
    transition="scale"
    scrollable
    adaptive
    :click-to-close="false"
    width="800px"
    height="auto"
  >
    <div class="modal-container">
      <photo-analysis-add-photo
        :is-header-with-back-button="isHeaderWithBackButton"
        :previous-modal-name="previousModalName"
      />
    </div>
  </modal>
</template>

<script>
import PhotoAnalysisAddPhoto from '@/modules/dashboard/components/dashboard/create-treatment/photo-analysis/PhotoAnalysisAddPhoto';

export default {
  name: 'PhotoAnalysisAddPhotoModal',
  components: { PhotoAnalysisAddPhoto },
  props: {
    isHeaderWithBackButton: {
      type: Boolean,
      default: false
    },
    previousModalName: {
      type: String,
      default: ''
    }
  }
};
</script>

<style scoped></style>
