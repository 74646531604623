<template>
  <treatment-composition
    :composition="treatmentComposition"
    class="pt5"
    :display-multipliers="displayMultipliers"
    :treatment-currency="treatment.currencyCode"
    :treatment-amount="treatment.amount"
    :treatment-delivery-price="treatment.deliveryPrice"
    :treatment-promo-code-amount="treatment.appliedPromoCodeAmount"
    :treatment-order-amount="treatment.orderPaidAmount"
    :should-calculate-composition-total="shouldCalculateCompositionTotal"
    :should-show-additional-payment-info="shouldShowAdditionalPaymentInfo"
    show-total-price
  />
</template>

<script>
import { isEmpty, isArray, flatMap, isNumber } from 'lodash';

import CompositionProduct from '@/modules/dashboard/components/common/CompositionProduct';
import TreatmentComposition from '@/modules/dashboard/components/common/TreatmentComposition';

import {
  getBottleDescription,
  getComplementaryCareDescription,
  getMicroneedlingConfigFromBottles
} from '@/modules/dashboard/utils/create-treatment-utils';

import {
  SERUM_USAGE_KEYS,
  TREATMENT_COMPOSITION_SECTION_KEY,
  CREATE_TREATMENT_SECTION_NAMES,
  TREATMENT_PRODUCT_STATUS,
  PRODUCT_REFERENCE_TO_DISPLAY_TITLE_MAP
} from '@/modules/dashboard/api/constants';

const TWO_BOTTLES_NUMBER = 2;

export default {
  name: 'TreatmentCompositionTab',
  components: { TreatmentComposition },
  props: {
    treatment: {
      type: Object,
      default: () => ({})
    },
    displayMultipliers: {
      type: Boolean,
      default: false
    },
    displayInitialTreatment: {
      type: Boolean,
      default: false
    },
    shouldCalculateCompositionTotal: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    treatmentComposition() {
      const { treatmentDetails } = this.treatment;

      if (!treatmentDetails) {
        return [];
      }

      const { treatFace, treatEyes, cleanse, moisturize, protect } = treatmentDetails;

      const treatSections = [
        { section: treatFace, name: TREATMENT_COMPOSITION_SECTION_KEY.TREAT_FACE },
        { section: treatEyes, name: TREATMENT_COMPOSITION_SECTION_KEY.TREAT_EYES }
      ];

      const complementaryCareSections = [
        { section: cleanse, name: CREATE_TREATMENT_SECTION_NAMES.CLEANSE },
        { section: moisturize, name: CREATE_TREATMENT_SECTION_NAMES.MOISTURIZE },
        { section: protect, name: CREATE_TREATMENT_SECTION_NAMES.PROTECT }
      ];

      const treatSectionsComposition = flatMap(treatSections, ({ section, name }) =>
        this.getTreatComposition(section, name)
      );

      const complementaryCareComposition = flatMap(complementaryCareSections, ({ section, name }) =>
        this.getComplementaryCareProductsComposition(section, name)
      );

      return [...treatSectionsComposition, ...complementaryCareComposition];
    },
    shouldShowAdditionalPaymentInfo() {
      return (
        this.treatment.onlineOffer && this.treatment.paid && !this.shouldCalculateCompositionTotal
      );
    }
  },
  methods: {
    getDisplayedTreatmentProductStatus(initialStatus) {
      return this.displayInitialTreatment
        ? TREATMENT_PRODUCT_STATUS.DOCTOR_RECOMMENDATION
        : initialStatus;
    },
    getTreatComposition(treat, compositionName) {
      const { cost, formulasOptions, status } = treat;

      if (!formulasOptions) {
        return null;
      }

      const { twoFormulas, oneFormula } = formulasOptions;

      if (twoFormulas.isFound) {
        return [this.getFormulaComposition(twoFormulas, cost, compositionName, status)];
      }

      if (oneFormula.isFound) {
        return [this.getFormulaComposition(oneFormula, cost, compositionName, status)];
      }

      return [];
    },
    getFormulaComposition(formula, cost, compositionName, status) {
      const { usage, nbOfBottles, compositionBottle1, compositionBottle2 } = formula;
      const microneedling = getMicroneedlingConfigFromBottles({
        compositionBottle1,
        compositionBottle2
      });
      const price = parseFloat(cost);
      const formulaProductStatus = this.getDisplayedTreatmentProductStatus(status);
      const formulaMultiplier =
        formulaProductStatus === TREATMENT_PRODUCT_STATUS.REMOVED_BY_PATIENT ? 0 : 1;

      if (nbOfBottles === TWO_BOTTLES_NUMBER) {
        const [firstBottleUsage, secondBottleUsage] =
          usage === SERUM_USAGE_KEYS.ONE_MORNING_ONE_EVENING
            ? [SERUM_USAGE_KEYS.MORNING_ONLY, SERUM_USAGE_KEYS.EVENING_ONLY]
            : [usage, usage];

        const firstDescription = getBottleDescription({
          ...compositionBottle1,
          usage: firstBottleUsage
        });

        const secondDescription = getBottleDescription({
          ...compositionBottle2,
          usage: secondBottleUsage
        });

        return new CompositionProduct({
          name: compositionName,
          descriptions: [firstDescription, secondDescription],
          price,
          isSerum: true,
          microneedling,
          status: formulaProductStatus,
          multiplier: formulaMultiplier
        });
      }

      const formulaBottle = compositionBottle1 || compositionBottle2;
      const formulaBottleDescription = getBottleDescription({ ...formulaBottle, usage });

      return new CompositionProduct({
        name: compositionName,
        descriptions: [formulaBottleDescription],
        price,
        isSerum: true,
        microneedling,
        status: formulaProductStatus,
        multiplier: formulaMultiplier
      });
    },
    getComplementaryCareProductsComposition(complementaryCare, sectionName) {
      const { products } = complementaryCare;

      if (isEmpty(products) || !isArray(products)) {
        return [];
      }

      return products.reduce((compositionProducts, product) => {
        const { name, multiplier, orderMultiplier, status, price } = product;

        if (this.displayInitialTreatment && status === TREATMENT_PRODUCT_STATUS.ADDED_BY_PATIENT) {
          return compositionProducts;
        }

        const productMultiplier = isNumber(orderMultiplier) ? orderMultiplier : multiplier;
        const productStatus = this.getDisplayedTreatmentProductStatus(status);
        const displayName = PRODUCT_REFERENCE_TO_DISPLAY_TITLE_MAP[name];

        return [
          ...compositionProducts,
          new CompositionProduct({
            name: this.$t(displayName),
            descriptions: [getComplementaryCareDescription(sectionName, product)],
            multiplier: productMultiplier,
            status: productStatus,
            price,
            productName: name
          })
        ];
      }, []);
    }
  }
};
</script>
