<template>
  <div class="diagnostic-header">
    <div class="diagnostic-header__content-photo flex--align-center">
      <div class="diagnostic-header__content-text" :class="{ 'no-photo': hasNoPhoto }">
        {{ $t('dashboard.label.photo') }}
      </div>
      <div v-if="!hasNoPhoto" class="diagnostic-header__content-image" @click="showZoomedPhoto">
        <div
          class="diagnostic-header__content-avatar centered-background-photo"
          :style="centeredPhotoStyles"
        ></div>
      </div>
      <i v-if="!hasNoPhoto" class="diagnostic-header__photo-hint cursor-default">
        {{ $t('dashboard.label.clickPhotoToZoom') }}
      </i>
      <div class="diagnostic-header__content-control" :class="{ 'no-photo': hasNoPhoto }">
        <div v-if="hasNoPhoto" class="diagnostic-header__content-button no-search-field">
          <span v-if="isPhotoNotSaved">
            {{ $t('dashboard.label.photoWasntSaved') }}
          </span>
          <span v-else-if="hasNoPhoto">
            {{ $t('dashboard.label.noPhoto') }}
          </span>
          <span v-else>Click to zoom</span>
        </div>
      </div>
    </div>
    <photo-zoom-modal class="onlyXS" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { types as dashTypes } from '@/modules/dashboard/store/types';

import PhotoZoomModal from '@/modules/dashboard/components/common/PhotoZoomModal';

import { buildImageUrl, buildPatientPhotoStyles } from '@/modules/dashboard/utils';

import { PHOTO_VUE_TYPES } from '@/modules/dashboard/api/constants';

export default {
  name: 'DiagnosticDetailsHeader',
  components: { PhotoZoomModal },
  props: {
    photo: PHOTO_VUE_TYPES
  },
  computed: {
    ...mapGetters({
      currentPatient: dashTypes.getters.CURRENT_PATIENT
    }),
    hasNoPhoto() {
      return !this.photo || this.isPhotoNotSaved;
    },
    isPhotoNotSaved() {
      return this.photo && !this.photo.convertedToBase64Photo;
    },
    displayedPhoto() {
      return buildImageUrl(this.photo);
    },
    centeredPhotoStyles() {
      return buildPatientPhotoStyles(this.photo);
    }
  },
  methods: {
    showZoomedPhoto() {
      this.$modal.show('photo-zoom-modal', { photoUrl: this.displayedPhoto });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/helpers/colors';
@import '../../../../assets/scss/common/photos';

.diagnostic-header {
  &__content-photo {
    justify-content: flex-start;
    margin-bottom: 12px;
    padding: 0 14px 0 15px;
  }

  &__content-text {
    width: 100%;
    max-width: 130px;
    margin-right: 17px;
    font-size: 15px;
    line-height: 17px;
    color: $active-title-color;
    text-align: right;

    &.no-photo {
      width: 130px;
      margin-bottom: 20px;
      text-align: right;
    }
  }

  &__content-image {
    height: 54px;
    width: 54px;
    margin-right: 6px;
    cursor: pointer;
  }

  &__content-avatar {
    border-radius: 7px;
  }

  &__photo-hint {
    max-width: 120px;
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    color: $grey-color;
  }

  &__content-control {
    display: flex;
    align-items: center;

    &.no-photo {
      flex-grow: 1;
      margin-bottom: 20px;
    }
  }

  &__content-button {
    padding: 5px;
    font-size: 12px;
    font-style: italic;
    line-height: 14px;
    color: $grey-color;
  }
}

@media (max-width: 767px) {
  .diagnostic-header {
    &__content-photo {
      justify-content: center;
      padding-left: 0;
      padding-right: 61px;
      margin-bottom: 20px;
    }

    &__content-text {
      width: 109px;
      margin: 0 10px 0 0;

      font-size: 13px;
      line-height: 15px;
      text-align: right;

      &.no-photo {
        width: 109px;
      }
    }

    &__content-button {
      padding: 8px 0 5px;

      font-size: 10px;
      line-height: 12px;
    }

    &__photo-hint {
      font-size: 10px;
      line-height: 12px;
    }
  }
}

@media (max-width: 369px) {
  .diagnostic-header {
    &__content-photo {
      justify-content: flex-start;
      margin-left: 5px;
      padding: 0;
    }
  }
}
</style>
