var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.currentDiagnosticId && _vm.diagnostic)?_c('div',[(!_vm.isValidated)?_c('diagnostic-issues',{attrs:{"diagnostic":_vm.diagnostic,"photo":_vm.photo}}):(_vm.showValidatedDiagnostics)?_c('validated-diagnostic-issues',{attrs:{"diagnostic":_vm.diagnostic,"photo":_vm.photo},on:{"hide-validated-diagnostics":_vm.toggleShowValidatedDiagnostic}}):_c('div',{staticClass:"diagnostic-details"},[_c('diagnostic-header',{attrs:{"diagnostic":_vm.diagnostic,"photo":_vm.photo},on:{"show-validated-diagnostics":_vm.toggleShowValidatedDiagnostic}}),_c('skin-dysfunctions-list',{attrs:{"diagnostic":_vm.diagnostic,"is-questionnaire-diagnostic":_vm.isQuestionnaireDiagnostic}}),_c('div',{staticClass:"diagnostic-details__footer",class:{
        'diagnostic-details__footer--photo-analysis': !_vm.isQuestionnaireDiagnostic
      }},[_c('div',{staticClass:"diagnostic-details__footer-controls"},[_c('button',{staticClass:"diagnostic-details__footer-button rounded-button-white button_orange",on:{"click":_vm.createTreatment}},[_vm._v(" "+_vm._s(_vm.$t('dashboard.action.createTreatment'))+" ")]),(_vm.isQuestionnaireDiagnostic)?_c('button',{staticClass:"diagnostic-details__footer-button rounded-button-blue button_pdf",on:{"click":_vm.downloadPdfSummary}},[_c('span',{staticClass:"icon-button icon-pdf-button"}),_vm._v(" "+_vm._s(_vm.$t('dashboard.action.pdfSummary'))+" ")]):_vm._e(),_c('div',{staticClass:"diagnostic-details__footer-button diagnostic-details__footer-button_delete no-search-field",class:{
            'diagnostic-details__footer-button_delete--photo-analysis': !_vm.isQuestionnaireDiagnostic
          }},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
              content: _vm.$t('dashboard.label.diagnosticCannotBeDeleted'),
              classes: 'delete-diagnostic-tooltip custom-tooltip',
              placement: _vm.tooltipConfig.placement,
              trigger: _vm.tooltipConfig.trigger,
              autoHide: false
            }),expression:"{\n              content: $t('dashboard.label.diagnosticCannotBeDeleted'),\n              classes: 'delete-diagnostic-tooltip custom-tooltip',\n              placement: tooltipConfig.placement,\n              trigger: tooltipConfig.trigger,\n              autoHide: false\n            }"}],class:{ none: !_vm.hasTreatment }},[_c('span',{staticClass:"far fa-trash-alt"}),_c('span',{staticClass:"onlyXS diagnostic-details__footer-button_delete__label"},[_vm._v(" "+_vm._s(_vm.$t('dashboard.action.deleteDiagnostic'))+" ")])]),_c('span',{class:{ none: _vm.hasTreatment },on:{"click":_vm.showDeleteDiagnosticModal}},[_c('span',{staticClass:"far fa-trash-alt"}),_c('span',{staticClass:"onlyXS diagnostic-details__footer-button_delete__label"},[_vm._v(" "+_vm._s(_vm.$t('dashboard.action.deleteDiagnostic'))+" ")])])])])]),_c('delete-diagnostic-modal',{attrs:{"patient-id":_vm.currentPatientId,"diagnostic-id":_vm.currentDiagnosticId}})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }