<template>
  <modal
    name="add-photo-modal"
    :classes="['modal-window']"
    transition="scale"
    adaptive
    scrollable
    :width="800"
    height="auto"
  >
    <div class="modal-container add-photo-window">
      <div class="modal-window-content add-photo-window__container flex-column">
        <div class="title  add-photo-window__title no-search-field">
          {{ $t('dashboard.label.addPhoto') }}
          <a class="cancel-button" @click="hideModal"></a>
        </div>
        <div class="add-photo-window__content">
          <div class="add-photo-window__text no-search-field text-center">
            {{ $t('dashboard.label.highQualityPhoto') }}
          </div>
          <div class="add-photo-window__img-container">
            <img
              src="@/assets/images/dashboard/icons/add-photo.svg"
              alt="camera"
              class="add-photo-window__img"
            />
          </div>
        </div>
        <upload-photo
          class="add-photo-window__controls"
          @handle-photo-validation-error="hideModal"
          @photo-uploaded="photoUploaded"
        />
      </div>
    </div>
  </modal>
</template>

<script>
import UploadPhoto from '@/modules/dashboard/components/dashboard/common/UploadPhoto';

export default {
  name: 'AddPhotoModal',
  components: { UploadPhoto },
  methods: {
    hideModal() {
      this.$modal.hide('add-photo-modal');
    },
    photoUploaded() {
      this.$emit('photo-upload');
      this.hideModal();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/common/buttons';

.modal-window-content {
  width: 100%;
  background-color: $white;
  font-family: var(--u-font-family-Gilroy);
  border-radius: 8px;
}

.v--modal-background-click {
  height: 100vh;
}

.cancel-button {
  position: relative;
  font-size: 15px;
  line-height: 17px;
  text-decoration: none;
  color: #8da4b0;
  width: 12px;
  height: 12px;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 12px;
    height: 12px;
    background-image: url('~@/assets/images/dashboard/icons/close-icon.svg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
}

.rounded-button-white {
  font-size: 15px;
  line-height: 17px;
}

.buttons-group {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 30px;
}

.add-photo-window {
  &__container {
    padding: 0 0 50px;
  }
  &__title {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 16px 20px;
    border-bottom: 1px solid $dim-white;
    font-size: 15px;
    line-height: 17px;
    color: $active-title-color;
  }
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 90px;
    padding: 15px 0 55px;
    border-bottom: 1px solid $dim-white;
  }
  &__text {
    padding: 40px 0;
    color: $grey-color;
    font-size: 15px;
    line-height: 17px;
  }
  &__controls {
    margin-top: 55px;
  }
}

.nonei {
  display: none !important;
}
</style>
