<template>
  <div v-if="photo" class="photo-container">
    <img
      class="dragme"
      alt="dragPhoto"
      :src="imageUrl"
      @mousedown="startDrag"
      @mouseup="stopDrag"
      @touchstart="startDrag"
      @touchend="stopDrag"
    />
  </div>
</template>

<script>
import { buildImageUrl, isMobileDevice } from '@/modules/dashboard/utils';

import { PHOTO_VUE_TYPES } from '@/modules/dashboard/api/constants';

const NO_TRANSLATE = 'translate(0px, 0px)';

export default {
  name: 'ZoomedPhoto',
  props: {
    photo: PHOTO_VUE_TYPES
  },
  data() {
    return {
      imageContainer: null,
      event: null,
      targ: null,
      offsetX: null,
      offsetY: null,
      coordX: null,
      coordY: null,
      dragDistance: null,
      dragDistanceY: null,
      imageXPos: null,
      imageYPos: null,
      imgContainerHeight: null,
      drag: null
    };
  },
  computed: {
    imageUrl() {
      return buildImageUrl(this.photo);
    }
  },
  created() {
    this.imageContainer = document.getElementsByClassName('photo-container');
  },
  methods: {
    setDefaultPosition(targ) {
      if (!targ.style.left) {
        targ.style.left = `${this.dragDistance / 2}px`;
        targ.style.transform = NO_TRANSLATE;
      }

      if (!targ.style.top) {
        targ.style.top = `${this.dragDistanceY / 2}px`;
        targ.style.transform = NO_TRANSLATE;
      }
    },
    dragDiv(event) {
      if (!this.drag) {
        return false;
      }

      if (!event) {
        this.event = window.event;
      }

      this.targ.style.left = `${this.coordX + event.clientX - this.offsetX}px`;
      this.imageXPos = parseInt(this.targ.style.left, 10);

      if (this.imageXPos > 0) {
        this.targ.style.left = '0px';
      }

      if (this.imageXPos < this.dragDistance) {
        this.targ.style.left = `${this.dragDistance}px`;
      }

      this.targ.style.top = `${this.coordY + event.clientY - this.offsetY}px`;
      this.imageYPos = parseInt(this.targ.style.top, 10);

      if (this.imageYPos > 0) {
        this.targ.style.top = '0px';
      }

      if (this.imageYPos < -this.imgContainerHeight) {
        this.targ.style.top = `${-this.imgContainerHeight}px`;
      }

      return false;
    },
    dragDivMob(event) {
      if (!this.drag) {
        return false;
      }

      if (!event) {
        this.event = window.event;
      }

      this.targ.style.left = `${this.coordX + event.touches[0].clientX - this.offsetX}px`;
      this.imageXPos = parseInt(this.targ.style.left, 10);

      if (this.imageXPos > 0) {
        this.targ.style.left = '0px';
      }

      if (this.imageXPos < this.dragDistance) {
        this.targ.style.left = `${this.dragDistance}px`;
      }

      this.targ.style.top = `${this.coordY + event.touches[0].clientY - this.offsetY}px`;
      this.imageYPos = parseInt(this.targ.style.top, 10);

      if (this.imageYPos > 0) {
        this.targ.style.top = '0px';
      }

      if (this.imageYPos < this.dragDistanceY) {
        this.targ.style.top = `${this.dragDistanceY}px`;
      }

      return false;
    },
    startDrag(event) {
      if (isMobileDevice()) {
        return false;
      }

      if (!event) {
        this.event = window.event;
      }

      if (event.preventDefault) {
        event.preventDefault();
      }

      this.targ = event.target ? event.target : event.srcElement;
      this.imageContainer = this.targ.parentNode;

      this.offsetX = event.clientX;
      if (!this.offsetX) {
        this.offsetX = event.touches[0].clientX;
      }
      this.offsetY = event.clientY;
      if (!this.offsetY) {
        this.offsetY = event.touches[0].clientY;
      }

      this.coordX = parseInt(this.targ.style.left, 10);
      this.coordY = parseInt(this.targ.style.top, 10);
      this.drag = true;
      this.dragDistance = -(this.targ.offsetWidth - this.imageContainer.offsetWidth);
      this.dragDistanceY = -(this.targ.offsetHeight - this.imageContainer.offsetHeight);
      this.imgContainerHeight = this.imageContainer.offsetHeight;

      this.setDefaultPosition(this.targ);

      document.onmousemove = this.dragDiv;
      this.imageContainer.ontouchmove = this.dragDivMob;

      return false;
    },
    stopDrag() {
      this.drag = false;
    }
  }
};
</script>

<style scoped>
.photo-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.dragme {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  height: 200%;
  object-fit: cover;
  cursor: move;
}

@media only screen and (max-width: 767px) {
  .dragme {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
</style>
