<template>
  <modal
    name="compare-photo-modal"
    :classes="['modal-window', 'no-mobile-padding-modal']"
    class="compare-modal-window mobile-fullscreen-modal"
    transition="scale"
    adaptive
    scrollable
    :width="modalWidth"
    :height="modalHeight"
    @opened="resizeSlider"
  >
    <div class="overlay" @click="previewAllHide"></div>
    <div class="modal-container compare-photo-window">
      <div class="modal-window-content compare-photo-window__container flex-column">
        <div class="title  compare-photo-window__title">
          {{ $t('dashboard.label.comparePhotos') }}
          <a class="cancel-button" @click="cancel"></a>
        </div>
        <div class="compare-photo-window__content otherXS">
          <div class="compare-photo-window__column">
            <div class="compare-photo-window__zoomed">
              <div
                v-for="compareZoomedPhoto in photos"
                :key="compareZoomedPhoto.photoId"
                :photo="compareZoomedPhoto"
                class="compare-photo-window__zoomed-item"
              >
                <div
                  v-if="isActiveFirstTab(compareZoomedPhoto)"
                  class="compare-photo-window__zoom-container"
                >
                  <zoomed-photo
                    :key="compareZoomedPhoto.photoId"
                    :photo="compareZoomedPhoto"
                  ></zoomed-photo>
                </div>
              </div>
            </div>
            <div class="photo-tab__photos-preview photos-preview">
              <ul class="photos-preview__list-compare">
                <li
                  v-for="comparePreviewPhoto in photos"
                  :key="comparePreviewPhoto.photoId"
                  :photo="comparePreviewPhoto"
                  class="photos-preview__list-item photo-preview-item"
                  :class="{
                    'photos-preview__list-item_active':
                      activeFirstTab === comparePreviewPhoto.photoId
                  }"
                  @click="activeFirstTab = comparePreviewPhoto.photoId"
                >
                  <div class="photo-preview-item__image">
                    <photo :key="comparePreviewPhoto.photoId" :photo="comparePreviewPhoto"></photo>
                  </div>
                  <div class="photo-preview-item__title">
                    <span class="photo-preview-item__title-text">
                      {{ comparePreviewPhoto.creationDate | formatDate }}
                    </span>
                    <div
                      v-if="comparePreviewPhoto.diagnosticId"
                      class="photo-preview-item__diagnostic-link"
                      @click="goToDiagnostic(comparePreviewPhoto)"
                    >
                      {{ `D${comparePreviewPhoto.diagnosticNumber}` }}
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="compare-photo-window__column">
            <div class="compare-photo-window__zoomed">
              <div
                v-for="compareZoomedPhoto in photos"
                :key="compareZoomedPhoto.photoId"
                :photo="compareZoomedPhoto"
                class="compare-photo-window__zoomed-item"
              >
                <div
                  v-if="isActiveSecondTab(compareZoomedPhoto)"
                  class="compare-photo-window__zoom-container"
                >
                  <zoomed-photo
                    :key="compareZoomedPhoto.photoId"
                    :photo="compareZoomedPhoto"
                  ></zoomed-photo>
                </div>
              </div>
            </div>
            <div class="photo-tab__photos-preview photos-preview">
              <ul class="photos-preview__list-compare">
                <li
                  v-for="comparePreviewPhoto in photos"
                  :key="comparePreviewPhoto.photoId"
                  :photo="comparePreviewPhoto"
                  class="photos-preview__list-item photo-preview-item"
                  :class="{
                    'photos-preview__list-item_active':
                      activeSecondTab === comparePreviewPhoto.photoId
                  }"
                  @click="activeSecondTab = comparePreviewPhoto.photoId"
                >
                  <div class="photo-preview-item__image">
                    <photo :key="comparePreviewPhoto.photoId" :photo="comparePreviewPhoto"></photo>
                  </div>
                  <div class="photo-preview-item__title">
                    <span class="photo-preview-item__title-text">
                      {{ comparePreviewPhoto.creationDate | formatDate }}
                    </span>
                    <div
                      v-if="comparePreviewPhoto.diagnosticId"
                      class="photo-preview-item__diagnostic-link"
                      @click="goToDiagnostic(comparePreviewPhoto)"
                    >
                      {{ `D${comparePreviewPhoto.diagnosticNumber}` }}
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="compare-photo-window__content onlyXS">
          <div class="slider-compare">
            <VueCompareImage :left-image="leftImage" :right-image="rightImage" />
          </div>
          <div class="flex flex--space-between--center controls-slider-compare">
            <div class="photo-tab__photos-left-preview-button" @click="previewLeftPopupShow">
              <img :src="leftImage" alt="img" />
              <div class="photo-tab__photos-left-preview-arrow"></div>
              <span class="photo-preview-item__title-text">
                {{ leftImageDate | formatDate }}
              </span>
              <div v-if="leftImageDiagnostic" class="photo-preview-item__diagnostic-link">
                {{ `D${leftImageDiagnostic}` }}
              </div>
            </div>
            <div class="photo-tab__photos-right-preview-button" @click="previewRightPopupShow">
              <img :src="rightImage" alt="img" />
              <div class="photo-tab__photos-right-preview-arrow"></div>
              <span class="photo-preview-item__title-text">
                {{ rightImageDate | formatDate }}
              </span>
              <div v-if="rightImageDiagnostic" class="photo-preview-item__diagnostic-link">
                {{ `D${rightImageDiagnostic}` }}
              </div>
            </div>
          </div>
          <div
            class="photo-tab__photos-preview photos-preview preview-left-popup"
            @click="previewLeftPopupHide"
          >
            <ul class="photos-preview__list-compare flex-column--align-center">
              <li
                v-for="comparePreviewLeftPhoto in photos"
                :key="comparePreviewLeftPhoto.photoId"
                :photo="comparePreviewLeftPhoto"
                class="photos-preview__list-item photo-preview-item"
                :class="{
                  'photos-preview__list-item_active':
                    activeFirstTab === comparePreviewLeftPhoto.photoId
                }"
                @click="updateLeftPhoto(comparePreviewLeftPhoto)"
              >
                <div class="photo-preview-item__image">
                  <photo
                    :key="comparePreviewLeftPhoto.photoId"
                    :photo="comparePreviewLeftPhoto"
                  ></photo>
                </div>
                <div class="photo-preview-item__title">
                  <span class="photo-preview-item__title-text">
                    {{ comparePreviewLeftPhoto.creationDate | formatDate }}
                  </span>
                  <div
                    v-if="comparePreviewLeftPhoto.diagnosticId"
                    class="photo-preview-item__diagnostic-link"
                    @click="goToDiagnostic(comparePreviewLeftPhoto)"
                  >
                    {{ `D${comparePreviewLeftPhoto.diagnosticNumber}` }}
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div
            class="photo-tab__photos-preview photos-preview preview-right-popup"
            @click="previewRightPopupHide"
          >
            <ul class="photos-preview__list-compare flex-column--align-center">
              <li
                v-for="comparePreviewRightPhoto in photos"
                :key="comparePreviewRightPhoto.photoId"
                :photo="comparePreviewRightPhoto"
                class="photos-preview__list-item photo-preview-item"
                :class="{
                  'photos-preview__list-item_active':
                    activeSecondTab === comparePreviewRightPhoto.photoId
                }"
                @click="updateRightPhoto(comparePreviewRightPhoto)"
              >
                <div class="photo-preview-item__image">
                  <photo
                    :key="comparePreviewRightPhoto.photoId"
                    :photo="comparePreviewRightPhoto"
                  ></photo>
                </div>
                <div class="photo-preview-item__title">
                  <span class="photo-preview-item__title-text">
                    {{ comparePreviewRightPhoto.creationDate | formatDate }}
                  </span>
                  <div
                    v-if="comparePreviewRightPhoto.diagnosticId"
                    class="photo-preview-item__diagnostic-link"
                    @click="goToDiagnostic(comparePreviewRightPhoto)"
                  >
                    {{ `D${comparePreviewRightPhoto.diagnosticNumber}` }}
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import PerfectScrollbar from 'perfect-scrollbar';
import VueCompareImage from 'vue-compare-image';
import Photo from '@/modules/dashboard/components/photo-tab/Photo';
import ZoomedPhoto from '@/modules/dashboard/components/photo-tab/ZoomedPhoto';

import { buildImageUrl, isMobileDevice } from '@/modules/dashboard/utils';

import rootTypes from '@/store/types';
import { types as dashTypes } from '@/modules/dashboard/store/types';

import { PATIENT_TAB_NAMES, PHOTO_VUE_TYPES } from '@/modules/dashboard/api/constants';

export default {
  name: 'ComparePhotoModal',
  components: {
    Photo,
    ZoomedPhoto,
    VueCompareImage
  },
  props: {
    patientId: {
      type: Number,
      required: true
    },
    needUpdate: {
      type: Boolean,
      required: false,
      default: false
    },
    photos: {
      type: Array,
      required: true
    },
    photo: PHOTO_VUE_TYPES
  },
  data() {
    return {
      activeFirstTab: 0,
      activeSecondTab: 0,
      leftImage: buildImageUrl(this.photos[0]),
      rightImage: buildImageUrl(this.photos[0]),
      leftImageDate: this.photos[0].creationDate,
      leftImageDiagnostic: this.photos[0].diagnosticNumber,
      rightImageDate: this.photos[0].creationDate,
      rightImageDiagnostic: this.photos[0].diagnosticNumber,
      slideListScrollbar: null,
      currentPhoto: null,
      modalWidth: '900px',
      modalHeight: 'auto'
    };
  },
  computed: {
    hasPhotos() {
      return this.photos.length > 0;
    },
    ...mapGetters({
      currentPatient: dashTypes.getters.CURRENT_PATIENT
    })
  },
  created() {
    if (isMobileDevice()) {
      this.modalWidth = '100%';
      this.modalHeight = '100%';
    }

    const [{ photoId: firstPhotoId }] = this.photos;

    this.activeFirstTab = firstPhotoId;
    this.activeSecondTab = firstPhotoId;
  },
  async mounted() {
    this.leftImage = buildImageUrl(this.photos[0]);
    this.rightImage = buildImageUrl(this.photos[0]);
    this.leftImageDate = this.photos[0].creationDate;
    this.leftImageDiagnostic = this.photos[0].diagnosticNumber;
    this.rightImageDate = this.photos[0].creationDate;
    this.rightImageDiagnostic = this.photos[0].diagnosticNumber;

    const slidList = document.body.querySelectorAll('.photos-preview__list');

    this.slideListScrollbar = new PerfectScrollbar(slidList[slidList.length - 1], {
      suppressScrollX: true,
      wheelPropagation: false
    });
  },
  methods: {
    ...mapActions({
      setLoading: rootTypes.actions.SET_LOADING,
      setActiveDiagnosticId: dashTypes.actions.SET_ACTIVE_DIAGNOSTIC_ID,
      setActivePatientTab: dashTypes.actions.SET_ACTIVE_PATIENT_TAB
    }),
    updateLeftPhoto(comparePreviewPhoto) {
      this.leftImage = buildImageUrl(comparePreviewPhoto);
      this.leftImageDate = comparePreviewPhoto.creationDate;
      this.leftImageDiagnostic = comparePreviewPhoto.diagnosticNumber;
    },
    previewLeftPopupShow() {
      const previewLeftPopup = document.querySelectorAll('.preview-left-popup');
      const overPopup = document.querySelectorAll('.overlay');

      previewLeftPopup[0].style.display = 'flex';
      overPopup[0].style.display = 'flex';
    },
    previewRightPopupShow() {
      const previewRigtnPopup = document.querySelectorAll('.preview-right-popup');
      const overPopup = document.querySelectorAll('.overlay');

      overPopup[0].style.display = 'flex';
      previewRigtnPopup[0].style.display = 'flex';
    },
    previewLeftPopupHide() {
      const previewLeftPopup = document.querySelectorAll('.preview-left-popup');
      const overPopup = document.querySelectorAll('.overlay');

      overPopup[0].style.display = 'none';
      previewLeftPopup[0].style.display = 'none';
    },
    previewRightPopupHide() {
      const previewRigtnPopup = document.querySelectorAll('.preview-right-popup');
      const overPopup = document.querySelectorAll('.overlay');

      overPopup[0].style.display = 'none';
      previewRigtnPopup[0].style.display = 'none';
    },
    previewAllHide() {
      const previewLeftPopup = document.querySelectorAll('.preview-left-popup');
      const previewRigtnPopup = document.querySelectorAll('.preview-right-popup');
      const overPopup = document.querySelectorAll('.overlay');

      overPopup[0].style.display = 'none';
      previewRigtnPopup[0].style.display = 'none';
      previewLeftPopup[0].style.display = 'none';
    },
    updateRightPhoto(comparePreviewPhoto) {
      this.rightImage = buildImageUrl(comparePreviewPhoto);
      this.rightImageDate = comparePreviewPhoto.creationDate;
      this.rightImageDiagnostic = comparePreviewPhoto.diagnosticNumber;
    },
    isActiveFirstTab(photo) {
      return this.activeFirstTab === photo.photoId;
    },
    isActiveSecondTab(photo) {
      return this.activeSecondTab === photo.photoId;
    },
    goToDiagnostic({ diagnosticId }) {
      this.setActiveDiagnosticId(diagnosticId);
      this.setActivePatientTab(PATIENT_TAB_NAMES.DIAGNOSTICS);

      if (isMobileDevice()) {
        this.$router.push({
          name: 'DiagnosticDetails',
          params: {
            diagnosticId
          }
        });
      }

      this.hideModal();
    },
    cancel() {
      const [{ photoId }] = this.photos;

      this.hideModal();

      this.activeFirstTab = photoId;
      this.activeSecondTab = photoId;
    },
    resizeSlider() {
      document.body.querySelectorAll('.compare-photo-window')[0].style.width = 'calc(100% - 1px)';
    },
    hideModal() {
      const overPopup = document.querySelectorAll('.overlay');

      this.$modal.hide('compare-photo-modal');
      overPopup[0].style.display = 'none';
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/common/buttons';

.overlay {
  position: fixed;
  display: none;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: transparent;
  z-index: 9999;
}

.modal-window-content {
  width: 100%;
  background-color: $white;
  font-family: var(--u-font-family-Gilroy);
  border-radius: 8px;
}

.v--modal-background-click {
  height: 100vh;
}

.cancel-button {
  position: relative;
  font-size: 15px;
  line-height: 17px;
  text-decoration: none;
  color: #8da4b0;
  width: 12px;
  height: 12px;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 12px;
    height: 12px;
    background-image: url('~@/assets/images/dashboard/icons/close-icon.svg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
}

.compare-photo-window {
  &__title {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 16px 20px;
    border-bottom: 1px solid $dim-white;
    font-size: 15px;
    line-height: 17px;
    color: $active-title-color;
  }
  &__content {
    display: flex;
    position: relative;
    margin: 40px 50px 30px;
  }
  &__column {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(50% - 25px);
    margin-right: 50px;

    &:last-child {
      margin-right: 0;
    }
  }
  &__zoomed {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    min-height: 420px;
  }
  &__zoomed-item {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }
  &__zoom-container {
    display: flex;
    max-width: 380px;
    height: 380px;
    margin-bottom: 40px;
  }
  &__text {
    padding: 40px 0;
    color: $grey-color;
  }
}

.photos-preview {
  width: 100%;

  &__list-compare {
    display: flex;
    width: 100%;
    margin: 0;
    padding: 0 20px;
    overflow: auto;
    list-style: none;
  }

  &__list-item {
    &_active {
      .photo-preview-item__image {
        border: 1px solid $orange-color;
      }
    }

    &:last-child {
      padding-bottom: 0;
    }
  }
}

.photo-preview-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60px;
  min-width: 60px;
  margin-right: 10px;
  cursor: pointer;

  &:last-child {
    margin-right: 0;
  }

  &__title {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 100%;
    margin-top: 10px;
  }

  &__title-text {
    max-width: 100%;
    font-size: 13px;
    font-weight: 300;
    line-height: 15px;
    text-align: center;
    color: $active-title-color;
    margin-bottom: 10px;
  }

  &__diagnostic-link {
    padding: 0 10px 10px;

    color: $grey-color;
    font-size: 15px;
    line-height: 19px;

    z-index: 1;
  }

  &__image {
    width: 60px;
    height: 60px;
    border-radius: 7px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.photo-tab__photos-left-preview-button,
.photo-tab__photos-right-preview-button {
  position: relative;
  width: 60px;
  height: 60px;
  object-fit: contain;
  border-radius: 7px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 7px;
  }
}

.photo-tab__photos-left-preview-arrow,
.photo-tab__photos-right-preview-arrow {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 10px;
  right: -5px;
  transform: translateX(100%);
  background-image: url('~@/assets/images/dashboard/icons/compare-arrow.svg');
  background-position: center center;
  background-repeat: no-repeat;
}

.slider-compare {
  max-width: 768px;
  height: 380px;

  .vci-container {
    height: 380px;
  }

  /deep/ .right-image,
  /deep/ .left-image {
    height: 100%;
    object-fit: cover;
  }

  /deep/ .left-arrow {
    margin-left: 0 !important;
    margin-right: 4px !important;
    width: 2px;
    height: 15px;
    background: $grey-color;
    border: none !important;
  }

  /deep/ .right-arrow {
    margin-right: 0 !important;
    width: 2px;
    height: 15px;
    border: none !important;
    background: $grey-color;
  }

  /deep/ .handle {
    background: white;
    border: 0.75px solid $light-blue !important;
  }
}

.preview-left-popup {
  display: none;
  position: fixed;
  left: 0;
  bottom: 0;
  max-height: calc(100vh - 100px);
  min-height: calc(100vh - 450px);
  max-width: 50%;
  background: white;
  padding: 22px 0;
  border-radius: 8px;
}

.preview-right-popup {
  display: none;
  position: fixed;
  right: 0;
  bottom: 0;
  max-height: calc(100vh - 100px);
  min-height: calc(100vh - 450px);
  max-width: 50%;
  background: white;
  padding: 22px 0;
  border-radius: 8px;
}

.controls-slider-compare {
  padding: 20px 50px;
}

.onlyXS {
  display: none;
}

@media (max-width: 767px) {
  .compare-photo-window {
    width: 100vw;
    height: 100vh;

    border-radius: 0;

    &__container {
      height: 100%;

      padding: 0 0 70px;
    }

    &__title {
      font-size: 0;
      justify-content: center;
      border-bottom: none;

      padding: 12px 20px;
    }

    &__content {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      justify-content: center;
      margin: 0 0 50px;
    }

    &__zoomed {
      position: static;
      display: flex;
      width: 100%;
      height: 100%;
      min-height: 420px;
    }

    &__zoom-container {
      max-width: 100%;
    }

    .cancel-button {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      border: 1px solid $light-blue;
    }

    .photos-preview {
      width: 100%;
      z-index: 99999999;

      &__list-compare {
        padding: 0 50px;
      }

      &__list-item {
        &_active {
          .photo-preview-item__image {
            border: none;
          }
        }
      }
    }

    .slider-compare {
      display: flex;
      flex-grow: 1;
      align-items: center;
    }

    .photo-preview-item {
      margin-right: 0;
      min-height: 130px;

      &__title-text {
        font-size: 10px;
        line-height: 12px;
        display: block;
        width: 100%;
        text-align: center;
        margin-top: 6px;
      }

      &__diagnostic-link {
        font-size: 12px;
        line-height: 14px;
        text-align: center;
      }
    }
  }

  .modal-window-content {
    border-radius: 0;
  }

  .compare-modal-window {
    height: 100%;
    width: 100%;
  }

  .otherXS {
    display: none;
  }
}
</style>
