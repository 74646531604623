<template>
  <div>
    <div v-if="treatment" class="treatment-details">
      <treatment-details-header
        :treatment="treatment"
        :treatment-diagnostic="treatmentDiagnostic"
        @return-back="returnBack"
      />

      <div v-if="isStartDateVisible" class="treatment-details__start-date flex--align-center mb10">
        <span>
          {{ treatmentStartDateText }}
        </span>
        <span
          v-tooltip="{
            content: treatmentStartDateHintText,
            classes: 'treatment-detail-tooltip treatment-detail-tooltip-top'
          }"
          class="custom-icon icon-info mr15"
        ></span>
        <date-picker
          v-model="date"
          class="date-details__datepicker"
          lang="en"
          type="date"
          :not-before="datePickerStartDate"
          :format="dateFormat"
          :disabled="isTreatmentStarted"
          @input="updateTreatmentData"
        />
      </div>

      <treatment-details-timeline-sections :treatment="treatment" />

      <treatment-paid-checkbox
        v-if="isOfflineTreatment"
        v-model="isTreatmentPaid"
        class="treatment-details__paid-checkbox mb15"
        :treatment-status="treatment.statusEvent"
        :disabled="isPaidCheckboxDisabled"
        @input="updateTreatmentPaidStatus"
      />

      <loyalty-participant-select
        v-if="shouldDisplayParticipantSelector"
        class="treatment-details__participant-select"
        :selected-participant="selectedParticipant"
        :disabled="isLoyaltyParticipantSelectDisabled"
        @select-participant="updateTreatmentParticipant"
      />

      <treatment-details-footer
        class="treatment-details__footer"
        :is-offline-treatment="isOfflineTreatment"
        @renew-treatment="renewTreatment"
        @print-label="printPdfLabel"
        @delete-treatment="showDeleteTreatmentModal"
      />

      <delete-treatment-modal :patient-id="currentPatientId" :treatment-id="currentTreatmentId" />
    </div>

    <summary-treatment @after-modal-close="onAfterRenewModalClose" />
    <review-message-modal @after-modal-close="onAfterRenewModalClose" />
    <finish-treatment-validation-modal />
    <past-treatment-created-modal class="otherXS" />
  </div>
</template>

<script>
import { get, some } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import DatePicker from 'vue2-datepicker';

import isAfter from 'date-fns/is_after';

import DeleteTreatmentModal from '@/modules/dashboard/components/treatment-history-tab/DeleteTreatmentModal';
import TreatmentDetailsHeader from '@/modules/dashboard/components/treatment-history-tab/treatment-details/TreatmentDetailsHeader';
import SummaryTreatment from '@/modules/dashboard/components/create-treatment-tab/SummaryTreatmentModal';
import ReviewMessageModal from '@/modules/dashboard/components/create-treatment-tab/ReviewMessageModal';
import FinishTreatmentValidationModal from '@/modules/dashboard/components/create-treatment-tab/FinishTreatmentValidationModal';
import TreatmentPaidCheckbox from '@/modules/dashboard/components/treatment-history-tab/treatment-details/TreatmentPaidCheckbox';
import TreatmentDetailsFooter from '@/modules/dashboard/components/treatment-history-tab/treatment-details/TreatmentDetailsFooter';
import TreatmentDetailsTimelineSections from '@/modules/dashboard/components/treatment-history-tab/treatment-details/TreatmentDetailsTimelineSections';
import LoyaltyParticipantSelect from '@/modules/dashboard/components/create-treatment-tab/LoyaltyParticipantSelect';
import PastTreatmentCreatedModal from '@/modules/dashboard/components/create-treatment-tab/PastTreatmentCreatedModal';

import { microneedlingValidations } from '@/modules/dashboard/mixins/microneedling/microneedlingValidations';

import {
  getInciPdfLabelAsBlob,
  updateTreatment,
  getLoyaltyProgramParticipant
} from '@/modules/dashboard/api';

import { isMobileDevice, openFileInNewTab } from '@/modules/dashboard/utils';
import { formatDate } from '@/modules/dashboard/utils/date-utils';

import rootTypes from '@/store/types';
import { types as dashTypes } from '@/modules/dashboard/store/types';

import {
  DATE_FORMAT,
  PATIENT_TAB_NAMES,
  TREATMENT_STATUSES,
  TREATMENT_OFFLINE_ORDER_STATUSES,
  TREATMENT_SECTION_NAME
} from '@/modules/dashboard/api/constants';

import {
  doesFormulaContainMicroneedling,
  getTreatmentSectionFormulaFromTreatment
} from '@/modules/dashboard/utils/create-treatment-utils';

import { isAutopilotTreatment } from '@/modules/dashboard/utils/treatment-utils';
import { validateContraindications } from '@/modules/dashboard/business-logic/microneedling';

export default {
  name: 'TreatmentDetails',
  components: {
    LoyaltyParticipantSelect,
    TreatmentDetailsTimelineSections,
    TreatmentDetailsHeader,
    TreatmentDetailsFooter,
    TreatmentPaidCheckbox,
    FinishTreatmentValidationModal,
    ReviewMessageModal,
    SummaryTreatment,
    DeleteTreatmentModal,
    DatePicker,
    PastTreatmentCreatedModal
  },
  mixins: [microneedlingValidations],
  props: {
    treatmentId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      currentTreatmentId: null,
      date: '',
      datePickerStartDate: '',
      isTreatmentStarted: false,
      isTreatmentPaid: false,
      selectedParticipant: null,
      dateFormat: DATE_FORMAT
    };
  },
  computed: {
    ...mapGetters({
      userId: rootTypes.getters.GET_USER_ID,
      currentPatientId: dashTypes.getters.CURRENT_PATIENT_ID,
      activeTreatmentId: dashTypes.getters.ACTIVE_TREATMENT_ID,
      getDiagnosticById: dashTypes.getters.GET_CURRENT_PATIENT_DIAGNOSTIC_BY_ID,
      treatments: dashTypes.getters.CURRENT_PATIENT_TREATMENTS
    }),
    treatment() {
      return this.treatments.find(({ id }) => id === this.currentTreatmentId);
    },
    shouldDisplayParticipantSelector() {
      return !isAutopilotTreatment(this.treatment);
    },
    hasMicroneedling() {
      const faceFormula = getTreatmentSectionFormulaFromTreatment(
        this.treatment,
        TREATMENT_SECTION_NAME.TREAT_FACE
      );
      const eyesFormula = getTreatmentSectionFormulaFromTreatment(
        this.treatment,
        TREATMENT_SECTION_NAME.TREAT_EYES
      );

      return some([faceFormula, eyesFormula], doesFormulaContainMicroneedling);
    },
    treatmentDiagnostic() {
      const diagnosticId = get(this.treatment, 'diagnosticId');

      return diagnosticId ? this.getDiagnosticById(diagnosticId) : {};
    },
    isPaidCheckboxDisabled() {
      const { statusEvent, transferred } = this.treatment;
      const isOngoingTransferredTreatment =
        statusEvent === TREATMENT_STATUSES.ONGOING_TREATMENT && transferred;

      return this.isTreatmentPaid || isOngoingTransferredTreatment;
    },
    isLoyaltyParticipantSelectDisabled() {
      const { statusEvent, transferred } = this.treatment;
      const isTreatmentCompleted = statusEvent === TREATMENT_STATUSES.TREATMENT_COMPLETED;
      const isOngoingTransferredTreatment =
        statusEvent === TREATMENT_STATUSES.ONGOING_TREATMENT && transferred;

      return this.isTreatmentPaid || isTreatmentCompleted || isOngoingTransferredTreatment;
    },
    isStartDateVisible() {
      const { statusEvent, startDate } = this.treatment;
      const isNewTreatment = statusEvent === TREATMENT_STATUSES.TREATMENT_STARTS_IN;
      const displayForOfflineTreatment = this.isOfflineTreatment && isNewTreatment;
      const displayForOnlineTreatment = !this.isOfflineTreatment && startDate && isNewTreatment;

      return displayForOfflineTreatment || displayForOnlineTreatment;
    },
    isOfflineTreatment() {
      return TREATMENT_OFFLINE_ORDER_STATUSES.includes(this.treatment.orderStatus);
    },
    treatmentStartDateText() {
      const keyPostfix = this.isOfflineTreatment ? 'treatmentStart' : 'estimatedTreatmentStartDate';

      return this.$t(`dashboard.label.${keyPostfix}`);
    },
    treatmentStartDateHintText() {
      const keyPostfix = this.isOfflineTreatment
        ? 'treatmentStartDay'
        : 'estimatedTreatmentStartDate';

      return this.$t(`dashboard.hint.${keyPostfix}`);
    }
  },
  watch: {
    async treatment({ participantId }) {
      this.updateTreatmentDateInfo();

      if (!participantId) {
        this.selectedParticipant = null;

        return;
      }

      this.setLoading(true);

      const {
        data: { id, name }
      } = await getLoyaltyProgramParticipant(this.userId, participantId);

      if (id || name) {
        this.selectedParticipant = { value: id, label: name };
      }

      this.setLoading(false);
    }
  },
  created() {
    this.currentTreatmentId = this.activeTreatmentId || this.treatmentId;
  },
  methods: {
    ...mapActions({
      setLoading: rootTypes.actions.SET_LOADING,
      resetActiveTreatment: dashTypes.actions.RESET_ACTIVE_TREATMENT,
      setActivePatientTab: dashTypes.actions.SET_ACTIVE_PATIENT_TAB,
      loadPatientTreatments: dashTypes.actions.LOAD_CURRENT_PATIENT_TREATMENTS,
      resetCreateTreatmentSelections: dashTypes.actions.RESET_CREATE_TREATMENT_SELECTIONS,
      setSummaryTreatmentBasedOnTreatment:
        dashTypes.actions.SET_SUMMARY_TREATMENT_BASED_ON_CREATED_TREATMENT
    }),
    async returnBack() {
      await this.resetActiveTreatment();
      await this.setActivePatientTab(PATIENT_TAB_NAMES.CREATE_TREATMENT);
    },
    showDeleteTreatmentModal() {
      this.$modal.show('delete-treatment-modal');
    },
    async printPdfLabel() {
      this.setLoading(true);

      const blob = await getInciPdfLabelAsBlob({
        patientId: this.currentPatientId,
        treatmentId: this.currentTreatmentId
      });

      openFileInNewTab(blob);

      this.setLoading(false);
    },
    async renewTreatment() {
      this.setSummaryTreatmentBasedOnTreatment(this.treatment);

      if (this.hasMicroneedling) {
        const { confirm } = await this.askForMicroneedlingValidation();

        if (!confirm) {
          return;
        }
      }

      const {
        shouldReceiveConsent: shouldShowContradictionsModal,
        shouldRejectOnReceivingConsent: shouldRejectOnReceivingContradictionsConsent
      } = validateContraindications({
        isMicroneedling: this.hasMicroneedling
      });

      if (shouldShowContradictionsModal) {
        const { confirm } = await this.$asyncModal.show('contraindications-warning-modal');

        if (shouldRejectOnReceivingContradictionsConsent || !confirm) {
          return;
        }
      }

      if (isMobileDevice()) {
        this.$router.push({
          name: 'SummaryTreatmentComposition',
          query: { summaryRenewTreatment: true }
        });

        return;
      }

      this.$modal.show('summary-treatment-modal');
    },
    async updateTreatmentData(treatmentData = {}) {
      await updateTreatment({
        patientId: this.currentPatientId,
        treatmentId: this.currentTreatmentId,
        updatedTreatment: {
          ...treatmentData,
          patientId: this.currentPatientId,
          startDate: this.date
        }
      });
    },
    async updateTreatmentPaidStatus() {
      this.setLoading(true);

      await this.updateTreatmentData({ paid: this.isTreatmentPaid });
      await this.loadPatientTreatments();

      this.setLoading(false);
    },
    async updateTreatmentParticipant(participant) {
      const participantId = get(participant, 'value', null);

      this.selectedParticipant = participant;

      this.setLoading(true);
      await this.updateTreatmentData({ participantId });
      this.setLoading(false);
    },
    updateTreatmentDateInfo() {
      const { startDate, creationDate, paid } = this.treatment;

      this.date = startDate;
      this.datePickerStartDate = formatDate(creationDate, DATE_FORMAT);

      this.isTreatmentStarted = isAfter(new Date(), new Date(startDate));
      this.isTreatmentPaid = paid;
    },
    onAfterRenewModalClose({ cancel }) {
      if (cancel) {
        this.resetCreateTreatmentSelections();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/helpers/colors';
@import '../../../../assets/css/common/icons.css';
@import '../../../../assets/css/common/tooltips.css';
@import '../../../../assets/css/common/datepicker.css';

.treatment-details {
  width: 100%;
  min-width: 320px;
  padding: 15px 20px 30px;
  background: $white;
  border-radius: 8px;

  &__start-date {
    padding: 7px 20px;
    border-radius: 8px;
    background-color: $background;

    font-size: 13px;
    line-height: 19px;
    color: $text-color;
  }

  &__participant-select {
    max-width: 300px;
  }

  &__footer {
    margin-top: 20px;
  }
}

.date-details {
  &__datepicker {
    width: 108px;
    flex-shrink: 0;
  }
}

@media (max-width: 767px) {
  .treatment-details {
    position: relative;
    min-height: 100vh;
    padding: 50px 0 150px;

    &__start-date {
      border-radius: 0;
    }

    &__paid-checkbox,
    &__participant-select {
      padding: 0 20px;
    }

    &__participant-select {
      max-width: 100%;
    }

    &__footer {
      margin-top: 30px;
    }
  }
}
</style>
