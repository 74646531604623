<template>
  <div class="clarification__container">
    <modal-header
      :should-show-close-icon="false"
      class="clarification__modal-header"
      current-modal-name="analyzing-photo-modal"
    >
      <span slot="header-title">{{ $t('dashboard.photoAnalysis.label.analyzingPhoto') }}</span>
    </modal-header>
    <main v-show="photoAnalysisPhotoUrl" class="clarification flex--center--center">
      <div class="analyzing-photo__container">
        <div class="analyzing-photo__img">
          <div class="fingerprint scanning">
            <canvas ref="photoCanvas" class="canvas" width="200" height="200"></canvas>
            <div class="analyzing-photo-user" :style="photoAnalysisPhotoStyles"></div>
          </div>
        </div>
        <div class="progress-bar__wrapper">
          <div
            v-if="!isPhotoAnalysis"
            class="progress-bar__face-container flex-column--align-center"
          >
            <div :key="faceDetectorMessagePostfix" class="progress-bar__message message-animation">
              {{ $t(`dashboard.photoAnalysis.label.${faceDetectorMessagePostfix}`) }}
            </div>
            <div class="loaderContainer">
              <div class="face-loader">
                <div class="dot first"></div>
                <div class="dot second"></div>
                <div class="dot third"></div>
                <div class="dot fourth"></div>
                <div class="dot fifth"></div>
              </div>
            </div>
          </div>
          <div v-else class="progress-bar__analysis-container">
            <ul id="skill">
              <li>
                <span class="bar graphic-design"></span>
                <h3 :key="photoAnalysisMessagePostfix">
                  <span class="mr3">
                    {{ $t('dashboard.photoAnalysis.label.scanningFor') }}
                  </span>
                  <span class="message-animation">
                    {{ $t(`dashboard.photoAnalysis.label.${photoAnalysisMessagePostfix}`) }}
                  </span>
                </h3>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </main>
    <photo-validation-modal class="onlyXS" @try-again="onTryAgain" @close="onClose" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import ModalHeader from '@/modules/dashboard/components/dashboard/common/ModalHeader';
import PhotoValidationModal from '@/modules/dashboard/components/dashboard/common/PhotoValidationModal';

import RoutingInfo from '@/modules/dashboard/components/common/RoutingInfo';

import {
  detectFace,
  fetchPatientPhotoByPhotoId,
  setPatientPhotoAsAvatar
} from '@/modules/dashboard/api';

import {
  buildBackgroundPhotoUrlStyles,
  buildImageUrl,
  getFacePhotoValidationError,
  handleRoutingInfo
} from '@/modules/dashboard/utils';
import { hasResponseErrors } from '@/utils/storeActionsUtils';

import rootTypes from '@/store/types';
import { types as dashTypes } from '@/modules/dashboard/store/types';

const ANALYZING_PHOTO_ROUTING_INFO = new RoutingInfo(
  'analyzing-photo-modal',
  'ClarificationAnalyzingPhoto'
);

const ANALYSIS_RESULTS_OVERVIEW = new RoutingInfo(
  'analysis-results-overview-modal',
  'AnalysisResultsOverview'
);

const FACE_DETECTOR_ANIMATION_MESSAGES_POSTFIXES = [
  'detectingFace',
  'detectingAge',
  'detectingGender'
];

const PHOTO_ANALYSIS_ANIMATION_MESSAGES_POSTFIXES = [
  'oxidativeStress',
  'skinAppearance',
  'skinRedness',
  'skinDryness',
  'skinOiliness',
  'skinTexture',
  'skinPimples',
  'skinPigmentation'
];

const ANGLE_STEP = (Math.PI * 2) / 360;
const FREQUENCY_X = 7;
const FREQUENCY_Y = 7;

export default {
  name: 'AnalyzingPhoto',
  components: { ModalHeader, PhotoValidationModal },
  props: {
    hasUploadAnotherOption: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      questions: null,
      isPhotoAnalysis: false,
      faceDetectorMessagePostfix: FACE_DETECTOR_ANIMATION_MESSAGES_POSTFIXES[0],
      photoAnalysisMessagePostfix: PHOTO_ANALYSIS_ANIMATION_MESSAGES_POSTFIXES[0],
      faceDetectorMessageInterval: null,
      photoAnalysisMessageInterval: null,
      photoAnalysisPhotoUrl: null
    };
  },
  computed: {
    ...mapGetters({
      hasErrors: dashTypes.getters.HAS_ERRORS,
      doctorLocale: rootTypes.getters.GET_USER_LANGUAGE,
      currentPatientId: dashTypes.getters.CURRENT_PATIENT_ID,
      photoAnalysisPhotoId: dashTypes.getters.PHOTO_ANALYSIS_PHOTO_ID,
      photoAnalysisPermissionToDelete: dashTypes.getters.PHOTO_ANALYSIS_PERMISSION_TO_DELETE,
      hasPhotoValidationErrors: dashTypes.getters.HAS_PHOTO_VALIDATION_ERRORS,
      isCurrentPatientHasAvatar: dashTypes.getters.IS_CURRENT_PATIENT_HAS_AVATAR
    }),
    photoAnalysisPhotoStyles() {
      return buildBackgroundPhotoUrlStyles(this.photoAnalysisPhotoUrl);
    }
  },
  async mounted() {
    this.setLoading(true);

    await this.loadPatientPhoto();

    this.setLoading(false);

    this.runCanvasPhotoAnimation();

    this.startChangingMessageInterval(
      'faceDetectorMessagePostfix',
      'faceDetectorMessageInterval',
      FACE_DETECTOR_ANIMATION_MESSAGES_POSTFIXES
    );

    this.runPhotoAnalysis();
  },
  methods: {
    ...mapActions({
      setLoading: rootTypes.actions.SET_LOADING,
      runFaceDetector: dashTypes.actions.RUN_FACE_DETECTION,
      runPhotoAnalysisAlgorithm: dashTypes.actions.RUN_PHOTO_ANALYSIS_ALGORITHM
    }),
    runCanvasPhotoAnimation() {
      const canvas = this.$refs.photoCanvas;

      const context = canvas.getContext('2d');
      const radius = canvas.width / 3;

      let theta = 0;

      const draw = () => {
        context.setTransform(1, 0, 0, 1, 0, 0);
        context.clearRect(0, 0, canvas.width, canvas.height);
        context.setTransform(1, 0, 0, 1, canvas.width / 2, canvas.height / 2);
        context.beginPath();

        for (let angle = 0; angle < Math.PI * 2; angle += ANGLE_STEP) {
          const x = Math.sin(angle * FREQUENCY_X + theta) * Math.cos(angle + theta) * radius;
          const y = Math.cos(angle * FREQUENCY_Y) * Math.sin(angle + theta) * radius;

          if (angle === 0) {
            context.moveTo(x, y);
          } else {
            context.lineTo(x, y);
          }
        }

        context.lineWidth = 1;
        context.strokeStyle = '#CBEAFF';
        context.stroke();
        context.miterLimit = 0.1;
        context.closePath();

        theta += 0.04;
        window.requestAnimationFrame(draw);
      };

      window.requestAnimationFrame(draw);
    },
    startChangingMessageInterval(messageProperty, messageIntervalProperty, messageArray) {
      let messageIndex = 1;

      this[messageIntervalProperty] = setInterval(() => {
        if (messageArray.length === messageIndex) {
          messageIndex = 0;
        }

        this[messageProperty] = messageArray[messageIndex];

        messageIndex += 1;
      }, 3000);
    },
    async goToTreatmentCreation() {
      if (await this.$validator.validateAll()) {
        this.$router.push({
          name: 'PatientCard',
          params: {
            id: this.currentPatientId
          }
        });

        this.closeCurrentModal();

        handleRoutingInfo(ANALYZING_PHOTO_ROUTING_INFO, this.$router, this.$modal);
      }
    },
    async runPhotoAnalysis() {
      const {
        data: { noFace, severalFaces }
      } = await detectFace(
        this.currentPatientId,
        this.photoAnalysisPhotoId,
        this.photoAnalysisPermissionToDelete
      );

      if (noFace || severalFaces) {
        const validationError = getFacePhotoValidationError(noFace, severalFaces);

        this.$modal.show('photo-validation-modal', {
          validationError,
          hasUploadAnotherButton: this.hasUploadAnotherOption
        });

        this.closeCurrentModal();

        return;
      }

      await this.loadPatientPhoto();

      if (!this.isCurrentPatientHasAvatar) {
        await setPatientPhotoAsAvatar(this.currentPatientId, this.photoAnalysisPhotoId);
      }

      clearInterval(this.faceDetectorMessageInterval);

      this.isPhotoAnalysis = true;

      this.startChangingMessageInterval(
        'photoAnalysisMessagePostfix',
        'photoAnalysisMessageInterval',
        PHOTO_ANALYSIS_ANIMATION_MESSAGES_POSTFIXES
      );

      const photoAnalysisResponse = await this.runPhotoAnalysisAlgorithm(this.doctorLocale);

      clearInterval(this.photoAnalysisMessageInterval);

      if (!hasResponseErrors(photoAnalysisResponse)) {
        handleRoutingInfo(ANALYSIS_RESULTS_OVERVIEW, this.$router, this.$modal);
      }

      this.closeCurrentModal();
    },
    async loadPatientPhoto() {
      const { data } = await fetchPatientPhotoByPhotoId(
        this.currentPatientId,
        this.photoAnalysisPhotoId
      );
      this.photoAnalysisPhoto = data;

      this.photoAnalysisPhotoUrl = buildImageUrl(this.photoAnalysisPhoto);
    },
    closeCurrentModal() {
      this.$modal.hide('analyzing-photo-modal');
    },
    onTryAgain() {
      this.$router.push({ name: 'PhotoAnalysisAddPhoto' });
    },
    onClose() {
      this.$router.go(-1);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../../assets/css/common/icons.css';
@import '../../../../../../assets/scss/common/text';
@import '../../../../../../assets/scss/common/buttons';
@import '../../../../../../assets/scss/helpers/colors';

.clarification {
  height: 650px;

  position: relative;

  &__container {
    height: 100%;
    position: relative;
  }
}

.fingerprint {
  position: relative;
  width: 400px;
  height: 400px;
  border-radius: 50%;
  overflow: hidden;
  background-image: url('~@/assets/images/dashboard/grid-bg.png');
  background-position: center;
  background-size: cover;
}

.analyzing-photo-user {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;

  background-size: cover;
  background-position: center top;
}

.fingerprint.scanning::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 21px;
  background-image: linear-gradient(0deg, rgba(219, 241, 255, 0.5) 0%, rgba(214, 238, 255, 0) 100%);
  animation: scanning 2.8s linear infinite;
  opacity: 0.5;
}

@keyframes scanning {
  100% {
    transform: translate(0, calc(400px - 12px));
  }
}

@keyframes scanning-right {
  100% {
    transform: translate(calc(400px - 12px), 0);
  }
}

canvas {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: transparent;
  border-radius: 100%;
  z-index: 1;
}

#skill {
  list-style: none;
  width: 296px;
  margin: 50px auto 0;
  position: relative;
  padding: 14px 0;
  font-size: 15px;
  line-height: 17px;
}

#skill li {
  height: 4px;
}

#skill li h3 {
  position: relative;
  top: -25px;
  font-size: 15px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: $grey-color;
}

.bar {
  display: block;
  margin-top: 10px;
  margin-left: 60px;
  justify-self: center;
  height: 4px;
  position: absolute;
  border-radius: 10px;
}

.graphic-design {
  width: 100%;
  max-width: 180px;
  animation: graphic-design 3s ease-out infinite, bgcolorchange 24s ease-out infinite;
  background-image: linear-gradient(90deg, #998857 0%, #ffedba 100%);
}

.face-loader {
  display: flex;
  transform: translateY(-50%);
  font-size: 10px;
}

.dot {
  width: 5px;
  height: 5px;
  margin-right: 6px;
  border-radius: 50%;
  background-color: #ff6a00;
}
.dot {
  animation: ani 1s ease-in-out infinite alternate forwards;
}

.first {
  opacity: 0.5;
  animation-delay: 0.2s;
}
.second {
  opacity: 0.7;
  animation-delay: 0.4s;
}
.third {
  animation-delay: 0.6s;
}
.fourth {
  animation-delay: 0.8s;
}
.fifth {
  animation-delay: 1s;
}

.loaderContainer {
  padding-top: 20px;
}

.progress-bar {
  &__face-container {
    padding-top: 50px;
  }

  &__message {
    font-size: 15px;
    line-height: 17px;
    text-align: center;
    color: $grey-color;
  }
}

.message-animation {
  animation: text-fade 3s;
}

@keyframes ani {
  to {
    transform: translateY(10px);
  }
}

@keyframes graphic-design {
  0% {
    width: 0;
  }
  100% {
    width: 180px;
  }
}

@keyframes bgcolorchange {
  8.5% {
    background-image: linear-gradient(90deg, #998857 0%, #ffedba 100%);
  }
  21% {
    background-image: linear-gradient(90deg, #ad747a 0%, #ffc0a3 100%);
  }
  33.5% {
    background-image: linear-gradient(90deg, #b10049 0%, #ffacce 100%);
  }
  46% {
    background-image: linear-gradient(90deg, #426ca8 0%, #88b9ff 100%);
  }
  58.5% {
    background-image: linear-gradient(90deg, #db5000 0%, #ffaf82 100%);
  }
  71% {
    background-image: linear-gradient(90deg, #8e7b6c 0%, #e6ccb7 100%);
  }
  83.5% {
    background-image: linear-gradient(90deg, #3d8a54 0%, #76ff9f 100%);
  }
  96% {
    background-image: linear-gradient(90deg, #54433b 0%, #cca38f 100%);
  }
  100% {
    background-image: linear-gradient(90deg, #54433b 0%, #cca38f 100%);
  }
}

@keyframes text-fade {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  83% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@media (max-width: 767px) {
  .clarification {
    height: auto;
    padding: 140px 26px 70px;

    &__container {
      min-height: 100vh;
      background-color: white;
    }

    &__modal-header {
      position: fixed;
      background-color: $background;
      z-index: 1;
    }

    &__hint {
      text-align: center;
    }

    &__footer {
      height: 80px;

      &__container {
        background: linear-gradient(0, #ffffff 75%, rgba(255, 255, 255, 0) 100%);
        position: fixed;
      }
    }
  }

  .analyzing-photo {
    &__img {
      display: flex;
      justify-content: center;
    }
  }

  .fingerprint {
    width: 70vw;
    height: 70vw;
    max-width: 400px;
    max-height: 400px;
  }
}
</style>
