<template>
  <modal
    name="clarification-modal"
    :classes="['modal-window']"
    transition="scale"
    scrollable
    adaptive
    :click-to-close="false"
    width="800px"
    height="700px"
  >
    <div class="modal-container">
      <clarification />
    </div>
  </modal>
</template>

<script>
import Clarification from '@/modules/dashboard/components/dashboard/create-treatment/photo-analysis/Clarification';

export default {
  name: 'ClarificationModal',
  components: { Clarification }
};
</script>

<style scoped>
@media (min-width: 768px) and (max-height: 700px) {
  /deep/ .modal-window {
    display: flex;
    align-items: center;
  }

  .modal-container {
    width: 100%;
    height: 520px;
  }
}
</style>
