<template>
  <div class="send-patient-email">
    <patient-phone-warnings-template class="send-patient-email_empty" :patient="currentPatient">
      <main class="send-patient-email__message-template">
        <div v-if="shouldShowSmsWarning" class="send-phone__sms-warning flex">
          <span class="sms-warning__icon fas fa-exclamation-triangle mr10" />
          <span class="sms-warning__text label-text">
            <i18n path="dashboard.label.reachLimitWarning" tag="span">
              <span>{{ customSmsCounter }}</span>
              <span>{{ customSmsLimit }}</span>
              <span class="sms-warning__email mail-text">info@universkin.com</span>
            </i18n>
          </span>
        </div>
        <dashboard-constraint
          v-if="hasPatientConsent"
          id="patientSmsConstraint"
          name="patientSmsConstraint"
          :value="patientSmsConsent"
          class="patient-accept-receiving-constraint"
          required
          @input="updatePatientSmsConsent"
        >
          <template slot="constraint">
            <span
              class="patient-accept-receiving-constraint__constraint-label"
              :class="{ error: hasSmsConstraintError }"
            >
              {{ $t('dashboard.consent.sendInvite.agreeToMarketingOffersBySms') }}
            </span>
            <span
              v-tooltip="{
                placement: 'right-start',
                content: $t('dashboard.consent.sendInviteHint.agreeToMarketingOffersBySms')
              }"
              class="custom-icon icon-info mt2"
            ></span>
          </template>
        </dashboard-constraint>
        <message-template
          type="SMS"
          :initial-language="currentPatient.locale"
          :is-footer-visible="isFooterVisible"
          :max-number-of-characters="140"
          :has-scroll="false"
          should-use-external-language
          :external-language="language"
          @update-template-text="updateTemplateText"
          @select-language="updateLanguage"
        >
          <div slot="send-button" slot-scope="{ sendTemplate }">
            <button class="rounded-button-orange" @click="sendTemplate">
              {{ $t('dashboard.action.sendSms') }}
            </button>
          </div>
        </message-template>
      </main>
    </patient-phone-warnings-template>

    <invite-template-sent-modal
      :should-redirect-to-dashboard="shouldRedirectToDashboard"
      @close-modal="onInviteTemplateSentModalClose"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import MessageTemplate from '@/modules/dashboard/components/dashboard/common/MessageTemplate';
import InviteTemplateSentModal from '@/modules/dashboard/components/dashboard/invite-patient/InviteTemplateSentModal';
import DashboardConstraint from '@/modules/dashboard/components/dashboard/common/DashboardConstraint';
import PatientPhoneWarningsTemplate from '@/modules/dashboard/components/patient-card/common/PatientPhoneWarningsTemplate';

import { types as dashTypes } from '@/modules/dashboard/store/types';
import { DEFAULT_LOCALE } from '@/constants';

const SMS_COUNT_LIMIT = 5;

export default {
  name: 'SendPatientInviteWithTextMessage',
  inject: ['$validator'],
  components: {
    PatientPhoneWarningsTemplate,
    DashboardConstraint,
    InviteTemplateSentModal,
    MessageTemplate
  },
  props: {
    patientSmsConsent: {
      type: Boolean,
      default: false
    },
    isFooterVisible: {
      type: Boolean,
      default: false
    },
    hasPatientConsent: {
      type: Boolean,
      default: false
    },
    shouldRedirectToDashboard: {
      type: Boolean,
      default: true
    },
    language: {
      type: String,
      default: DEFAULT_LOCALE
    },
    canShowSmsWarning: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      customSmsCounter: 0,
      customSmsLimit: 125
    };
  },
  computed: {
    ...mapGetters({
      currentPatient: dashTypes.getters.CURRENT_PATIENT
    }),
    hasSmsConstraintError() {
      return this.errors.has('patientSmsConstraint');
    },
    smsCounterDifference() {
      return this.customSmsLimit - this.customSmsCounter;
    },
    shouldShowSmsWarning() {
      return this.canShowSmsWarning && this.smsCounterDifference <= SMS_COUNT_LIMIT;
    }
  },
  methods: {
    updateTemplateText(messageTemplate) {
      this.$emit('update-template-text-sms', messageTemplate);
      this.updateSmsCounter(messageTemplate);
    },
    onInviteTemplateSentModalClose() {
      this.$emit('invite-template-sent-modal-close');
    },
    updateSmsCounter({ customSmsCounter, customSmsLimit }) {
      this.customSmsCounter = customSmsCounter;
      this.customSmsLimit = customSmsLimit;
    },
    updatePatientSmsConsent(smsConsent) {
      this.$emit('update-sms-consent', smsConsent);
    },
    updateLanguage(language) {
      this.$emit('update-language', language);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../assets/scss/helpers/colors';
@import '../../../../../assets/scss/common/buttons';
@import '../../../../../assets/scss/common/text';
@import '../../../../../assets/scss/invite-patients/patient-card-invite-shared';
@import '../../../../../assets/css/common/icons.css';

.send-patient-email {
  height: 100%;
  font-size: 15px;
  line-height: 17px;
  color: $text-color;

  &_empty {
    min-height: 100%;
  }

  &__header {
    height: 50px;
    padding: 0 20px 0 25px;
  }

  &__email {
    margin: 30px 0 20px 0;

    &__title {
      margin-bottom: 15px;
      font-size: 13px;
      line-height: 15px;
    }
  }
}

.send-phone__sms-warning {
  width: auto;
  padding: 15px;
  margin: 0 15px 15px;
  background: $beige;
  border-radius: 8px;
}

.sms-warning {
  &__icon {
    color: $pale-orange-color;
  }

  &__email {
    font-weight: bold;
    color: $blue-steel-color;
  }
}

.rounded-input {
  width: auto;
  min-height: 36px;
}

@media (max-width: 767px) {
  .send-patient-email {
    font-size: 13px;
    line-height: 15px;
    background-color: $white;
  }
}
</style>
