<template>
  <modal
    name="send-patient-invite-modal"
    class="send-patient-invite-modal"
    :classes="['modal-window']"
    transition="scale"
    scrollable
    adaptive
    width="800px"
    height="auto"
  >
    <div class="modal-container">
      <send-patient-invite-tabs />
    </div>
  </modal>
</template>

<script>
import SendPatientInviteTabs from '@/modules/dashboard/components/patient-card/invite/SendPatientInviteTabs';

export default {
  name: 'SendPatientInviteModal',
  components: {
    SendPatientInviteTabs
  }
};
</script>
