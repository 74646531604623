<template>
  <div v-if="currentDiagnosticId && diagnostic">
    <diagnostic-issues
      v-if="!isValidated"
      :diagnostic="diagnostic"
      :photo="photo"
    ></diagnostic-issues>
    <validated-diagnostic-issues
      v-else-if="showValidatedDiagnostics"
      :diagnostic="diagnostic"
      :photo="photo"
      @hide-validated-diagnostics="toggleShowValidatedDiagnostic"
    ></validated-diagnostic-issues>
    <div v-else class="diagnostic-details">
      <diagnostic-header
        :diagnostic="diagnostic"
        :photo="photo"
        @show-validated-diagnostics="toggleShowValidatedDiagnostic"
      ></diagnostic-header>
      <skin-dysfunctions-list
        :diagnostic="diagnostic"
        :is-questionnaire-diagnostic="isQuestionnaireDiagnostic"
      />
      <div
        class="diagnostic-details__footer"
        :class="{
          'diagnostic-details__footer--photo-analysis': !isQuestionnaireDiagnostic
        }"
      >
        <div class="diagnostic-details__footer-controls">
          <button
            class="diagnostic-details__footer-button rounded-button-white button_orange"
            @click="createTreatment"
          >
            {{ $t('dashboard.action.createTreatment') }}
          </button>
          <button
            v-if="isQuestionnaireDiagnostic"
            class="diagnostic-details__footer-button rounded-button-blue button_pdf"
            @click="downloadPdfSummary"
          >
            <span class="icon-button icon-pdf-button"></span>
            {{ $t('dashboard.action.pdfSummary') }}
          </button>
          <div
            class="diagnostic-details__footer-button diagnostic-details__footer-button_delete no-search-field"
            :class="{
              'diagnostic-details__footer-button_delete--photo-analysis': !isQuestionnaireDiagnostic
            }"
          >
            <span
              v-tooltip="{
                content: $t('dashboard.label.diagnosticCannotBeDeleted'),
                classes: 'delete-diagnostic-tooltip custom-tooltip',
                placement: tooltipConfig.placement,
                trigger: tooltipConfig.trigger,
                autoHide: false
              }"
              :class="{ none: !hasTreatment }"
            >
              <span class="far fa-trash-alt"></span>
              <span class="onlyXS diagnostic-details__footer-button_delete__label">
                {{ $t('dashboard.action.deleteDiagnostic') }}
              </span>
            </span>
            <span :class="{ none: hasTreatment }" @click="showDeleteDiagnosticModal">
              <span class="far fa-trash-alt"></span>
              <span class="onlyXS diagnostic-details__footer-button_delete__label">
                {{ $t('dashboard.action.deleteDiagnostic') }}
              </span>
            </span>
          </div>
        </div>
      </div>
      <delete-diagnostic-modal
        :patient-id="currentPatientId"
        :diagnostic-id="currentDiagnosticId"
      ></delete-diagnostic-modal>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

import DeleteDiagnosticModal from '@/modules/dashboard/components/diagnostic-tab/DeleteDiagnosticModal';
import DiagnosticIssues from '@/modules/dashboard/components/diagnostic-tab/DiagnosticIssues';
import DiagnosticHeader from '@/modules/dashboard/components/diagnostic-tab/DiagnosticHeader';
import ValidatedDiagnosticIssues from '@/modules/dashboard/components/diagnostic-tab/ValidatedDiagnosticIssues';
import SkinDysfunctionsList from '@/modules/dashboard/components/diagnostic-tab/SkinDysfunctionsList';

import { fetchPatientPhotoByPhotoId, getPdfSummaryAsBlob } from '@/modules/dashboard/api';
import { isMobileDevice, openFileInNewTab } from '@/modules/dashboard/utils';

import rootTypes from '@/store/types';
import { types as dashTypes } from '@/modules/dashboard/store/types';
import { DIAGNOSTIC_TYPES } from '@/modules/dashboard/api/constants';

export default {
  name: 'DiagnosticDetails',
  components: {
    SkinDysfunctionsList,
    ValidatedDiagnosticIssues,
    DiagnosticHeader,
    DiagnosticIssues,
    DeleteDiagnosticModal
  },
  props: {
    diagnosticId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      currentDiagnosticId: null,
      photo: null,
      showValidatedDiagnostics: false
    };
  },
  computed: {
    ...mapGetters({
      currentPatientId: dashTypes.getters.CURRENT_PATIENT_ID,
      activeDiagnosticId: dashTypes.getters.ACTIVE_DIAGNOSTIC_ID,
      getDiagnosticById: dashTypes.getters.GET_CURRENT_PATIENT_DIAGNOSTIC_BY_ID
    }),
    diagnostic() {
      return this.getDiagnosticById(this.currentDiagnosticId);
    },
    hasTreatment() {
      return this.diagnostic.hasTreatment;
    },
    isValidated() {
      return this.diagnostic.validated;
    },
    tooltipConfig() {
      return isMobileDevice()
        ? { placement: 'left', trigger: 'click' }
        : { placement: 'left-start', trigger: 'hover' };
    },
    isQuestionnaireDiagnostic() {
      return this.diagnostic.type === DIAGNOSTIC_TYPES.QUESTIONNAIRE_BASED;
    },
    tooltipTrigger() {
      return isMobileDevice() ? 'click' : 'hover';
    }
  },
  watch: {
    activeDiagnosticId() {
      this.updatePhoto();
      this.showValidatedDiagnostics = false;
    }
  },
  mounted() {
    this.currentDiagnosticId = this.activeDiagnosticId || this.diagnosticId;
    this.updatePhoto();
  },
  methods: {
    ...mapActions({
      setLoading: rootTypes.actions.SET_LOADING,
      selectRecommendationDiagnostic: dashTypes.actions.SELECT_RECOMMENDATION_DIAGNOSTIC
    }),
    ...mapMutations({
      setNotValidatedDiagnosticId: dashTypes.mutations.SET_NOT_VALIDATED_DIAGNOSTIC_ID
    }),
    async updatePhoto() {
      const { photoId } = this.diagnostic;

      if (!photoId) {
        return;
      }

      const { data } = await fetchPatientPhotoByPhotoId(this.currentPatientId, photoId);
      this.photo = data;
    },
    toggleShowValidatedDiagnostic() {
      this.showValidatedDiagnostics = !this.showValidatedDiagnostics;
    },
    showDeleteDiagnosticModal() {
      this.$modal.show('delete-diagnostic-modal');
    },
    async createTreatment() {
      await this.setNotValidatedDiagnosticId(null);
      await this.selectRecommendationDiagnostic(this.diagnostic);

      if (isMobileDevice()) {
        this.$router.push({
          name: 'CreateTreatment',
          params: {
            id: this.currentPatientId
          }
        });

        return;
      }

      this.$emit('create-treatment');
    },
    async downloadPdfSummary() {
      this.setLoading(true);

      const blob = await getPdfSummaryAsBlob({
        patientId: this.currentPatientId,
        diagnosticId: this.diagnostic.id,
        questionnaireId: this.diagnostic.questionnaireId
      });

      openFileInNewTab(blob);

      this.setLoading(false);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/helpers/colors';
@import '../../../../assets/css/common/icons.css';
@import '../../../../assets/scss/common/buttons';
@import '../../../../assets/sass/mixins';
@import '../../../../assets/css/common/tooltips.css';

.diagnostic-details {
  width: 100%;
  min-width: 320px;
  padding: 15px 30px 30px;
  background: $white;
  border-radius: 8px;
  &__footer {
    display: flex;
  }
  &__footer-controls {
    display: flex;
    align-items: center;
    width: 100%;
  }
  &__footer-button {
    margin-right: 20px;
    &_delete {
      margin-left: auto;
      margin-right: 0;
      outline: none;

      cursor: pointer;

      span {
        color: $grey-color;
      }
    }
  }
}

@media (max-width: 767px) {
  .diagnostic-details {
    padding: 0 0 40px 0;
    margin-bottom: 40px;
    background: transparent;

    &__footer-controls {
      flex-wrap: wrap;
      padding: 0 15px;
      margin: 15px;
      justify-content: space-between;
    }

    &__footer-button {
      padding: 7px 11px;
      font-size: 13px;
      line-height: 15px;
      margin: 0;

      &_delete {
        margin-top: 20px;
        flex-basis: 100%;
        display: flex;
        justify-content: center;

        &--photo-analysis {
          flex-basis: 50%;
          margin-top: 0;
          padding-right: 0;
        }

        &__label {
          margin-left: 7px;
          display: inline;
        }
      }
    }
  }
}
</style>
