<template>
  <timeline-section class="treatment-started-section" :is-active="isActive">
    <timeline-section-header
      slot="header"
      slot-scope="{ isShown }"
      :timeline-date="treatment.startDate"
      :timeline-header="$t('dashboard.label.treatmentStarted')"
      :is-shown="isShown"
      :can-open-section="canOpenSection"
    />
    <div
      slot="content"
      class="treatment-started-section__content started-section-content flex--align-center"
    >
      <div class="started-section-content__progress-chart">
        <percentage-circle-chart :percentage="treatment.treatmentProgressPercentage" />
      </div>
      <span class="started-section-content__days-counter">
        {{
          $t('dashboard.label.dayOf', [
            treatment.numberOfDaysForTreatmentFromBeginning,
            treatment.totalNumberOfDaysForTreatment
          ])
        }}
      </span>
    </div>
  </timeline-section>
</template>

<script>
import TimelineSection from '@/modules/dashboard/components/treatment-history-tab/treatment-details/treatment-timeline-sections/TimelineSection';
import TimelineSectionHeader from '@/modules/dashboard/components/treatment-history-tab/treatment-details/treatment-timeline-sections/TimelineSectionHeader';
import PercentageCircleChart from '@/modules/dashboard/components/common/PercentageCircleChart';

import { TIMELINE_SECTION_DEFAULT_PROPS } from '@/modules/dashboard/api/constants';

export default {
  name: 'TreatmentStartedTimelineSection',
  components: { PercentageCircleChart, TimelineSectionHeader, TimelineSection },
  props: {
    ...TIMELINE_SECTION_DEFAULT_PROPS
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../../assets/scss/helpers/colors';

.treatment-started-section {
  &__content {
    padding: 20px 20px 15px 9px;
  }
}

.started-section-content {
  &__progress-chart {
    width: 57px;
    height: 57px;

    margin-right: 30px;
  }

  &__days-counter {
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;

    color: $orange-color;
  }
}
</style>
