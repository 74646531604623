<template>
  <div class="face-icons flex">
    <div class="face-icons__group flex">
      <face-icon-with-label
        icon="first"
        :gender="gender"
        :label="$t('dashboard.label.removeHair')"
      />
      <face-icon-with-label
        icon="second"
        :gender="gender"
        :label="$t('dashboard.label.removeGlasses')"
      />
      <face-icon-with-label
        icon="third"
        :gender="gender"
        :label="$t('dashboard.label.keepNeutralExpression')"
      />
    </div>
    <div class="face-icons__group flex">
      <face-icon-with-label
        icon="fourth"
        :gender="gender"
        :label="$t('dashboard.label.avoidShadow')"
      />
      <face-icon-with-label
        icon="fifth"
        :gender="gender"
        :label="$t('dashboard.label.faceCentered')"
      />
      <face-icon-with-label icon="sixth" :gender="gender" correct />
    </div>
  </div>
</template>

<script>
import FaceIconWithLabel from '@/modules/dashboard/components/dashboard/create-treatment/photo-analysis/FaceIconWithLabel';

import { GENDER } from '@/modules/questionnaire/api/constants';

export default {
  name: 'FaceIcons',
  components: { FaceIconWithLabel },
  props: {
    gender: {
      type: String,
      default: GENDER.FEMALE
    }
  }
};
</script>

<style lang="scss" scoped>
@media (max-width: 767px) {
  .face-icons {
    flex-direction: column;

    &__group {
      margin-bottom: 20px;
    }
  }
}
</style>
