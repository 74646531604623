<template>
  <div class="timeline-sections">
    <component
      :is="timeline.componentName"
      v-for="timeline in timelines"
      :key="timeline.key"
      class="timeline-sections__section"
      :treatment="treatment"
      :is-active="timeline.isActive"
      :can-open-section="timeline.canOpen"
    />
  </div>
</template>

<script>
import TimelineSectionConfig from '@/modules/dashboard/components/treatment-history-tab/treatment-details/treatment-timeline-sections/TimelineSectionConfig';

import TreatmentCreatedTimelineSection from '@/modules/dashboard/components/treatment-history-tab/treatment-details/treatment-timeline-sections/TreatmentCreatedTimelineSection';
import TreatmentStartedTimelineSection from '@/modules/dashboard/components/treatment-history-tab/treatment-details/treatment-timeline-sections/TreatmentStartedTimelineSection';
import TreatmentCompletedTimelineSection from '@/modules/dashboard/components/treatment-history-tab/treatment-details/treatment-timeline-sections/TreatmentCompletedTimelineSection';
import TreatmentOfferCreatedTimelineSection from '@/modules/dashboard/components/treatment-history-tab/treatment-details/treatment-timeline-sections/TreatmentOfferCreatedTimelineSection';
import TreatmentPaidOnlineTimelineSection from '@/modules/dashboard/components/treatment-history-tab/treatment-details/treatment-timeline-sections/TreatmentPaidOnlineTimelineSection';

import { TREATMENT_ORDERS_STATUSES, TREATMENT_STATUSES } from '@/modules/dashboard/api/constants';

const TIMELINE_SECTION_COMPONENT_NAMES = {
  TREATMENT_CREATED: 'TreatmentCreatedTimelineSection',
  TREATMENT_OFFER_CREATED: 'TreatmentOfferCreatedTimelineSection',
  TREATMENT_PAID_ONLINE: 'TreatmentPaidOnlineTimelineSection',
  TREATMENT_STARTED: 'TreatmentStartedTimelineSection',
  TREATMENT_COMPLETED: 'TreatmentCompletedTimelineSection'
};

const OFFLINE_TREATMENT_FLOW_TIMELINES_CONFIG = {
  [TREATMENT_STATUSES.TREATMENT_STARTS_IN]: [
    new TimelineSectionConfig({
      componentName: TIMELINE_SECTION_COMPONENT_NAMES.TREATMENT_CREATED,
      isActive: true
    })
  ],
  [TREATMENT_STATUSES.ONGOING_TREATMENT]: [
    new TimelineSectionConfig({
      componentName: TIMELINE_SECTION_COMPONENT_NAMES.TREATMENT_STARTED,
      isActive: true
    }),
    new TimelineSectionConfig({
      componentName: TIMELINE_SECTION_COMPONENT_NAMES.TREATMENT_CREATED
    })
  ],
  [TREATMENT_STATUSES.TREATMENT_COMPLETED]: [
    new TimelineSectionConfig({
      componentName: TIMELINE_SECTION_COMPONENT_NAMES.TREATMENT_COMPLETED,
      isActive: true
    }),
    new TimelineSectionConfig({
      componentName: TIMELINE_SECTION_COMPONENT_NAMES.TREATMENT_STARTED,
      canOpen: false
    }),
    new TimelineSectionConfig({
      componentName: TIMELINE_SECTION_COMPONENT_NAMES.TREATMENT_CREATED
    })
  ]
};

const getUnderPreparationTimelinesConfig = () => [
  new TimelineSectionConfig({
    componentName: TIMELINE_SECTION_COMPONENT_NAMES.TREATMENT_PAID_ONLINE,
    isActive: true
  }),
  new TimelineSectionConfig({
    componentName: TIMELINE_SECTION_COMPONENT_NAMES.TREATMENT_OFFER_CREATED
  })
];

const ONLINE_TREATMENT_FLOW_TIMELINES_CONFIG = {
  [TREATMENT_STATUSES.TREATMENT_STARTS_IN]: {
    [TREATMENT_ORDERS_STATUSES.TREATMENT_OFFER_CREATED]: [
      new TimelineSectionConfig({
        componentName: TIMELINE_SECTION_COMPONENT_NAMES.TREATMENT_OFFER_CREATED,
        isActive: true
      })
    ],
    [TREATMENT_ORDERS_STATUSES.TREATMENT_PAID_ONLINE]: [
      new TimelineSectionConfig({
        componentName: TIMELINE_SECTION_COMPONENT_NAMES.TREATMENT_PAID_ONLINE,
        isActive: true
      }),
      new TimelineSectionConfig({
        componentName: TIMELINE_SECTION_COMPONENT_NAMES.TREATMENT_OFFER_CREATED
      })
    ],
    [TREATMENT_ORDERS_STATUSES.UNDER_PREPARATION]: getUnderPreparationTimelinesConfig(),
    [TREATMENT_ORDERS_STATUSES.RESEND_OPERATION]: getUnderPreparationTimelinesConfig(),
    [TREATMENT_ORDERS_STATUSES.CANCELLED]: [
      new TimelineSectionConfig({
        componentName: TIMELINE_SECTION_COMPONENT_NAMES.TREATMENT_PAID_ONLINE,
        isActive: true
      }),
      new TimelineSectionConfig({
        componentName: TIMELINE_SECTION_COMPONENT_NAMES.TREATMENT_OFFER_CREATED
      })
    ],
    [TREATMENT_ORDERS_STATUSES.DELIVERED]: [
      new TimelineSectionConfig({
        componentName: TIMELINE_SECTION_COMPONENT_NAMES.TREATMENT_PAID_ONLINE,
        isActive: true
      }),
      new TimelineSectionConfig({
        componentName: TIMELINE_SECTION_COMPONENT_NAMES.TREATMENT_OFFER_CREATED
      })
    ]
  },
  [TREATMENT_STATUSES.ONGOING_TREATMENT]: {
    [TREATMENT_ORDERS_STATUSES.DELIVERED]: [
      new TimelineSectionConfig({
        componentName: TIMELINE_SECTION_COMPONENT_NAMES.TREATMENT_STARTED,
        isActive: true
      }),
      new TimelineSectionConfig({
        componentName: TIMELINE_SECTION_COMPONENT_NAMES.TREATMENT_PAID_ONLINE
      }),
      new TimelineSectionConfig({
        componentName: TIMELINE_SECTION_COMPONENT_NAMES.TREATMENT_OFFER_CREATED
      })
    ]
  },
  [TREATMENT_STATUSES.TREATMENT_COMPLETED]: {
    [TREATMENT_ORDERS_STATUSES.DELIVERED]: [
      new TimelineSectionConfig({
        componentName: TIMELINE_SECTION_COMPONENT_NAMES.TREATMENT_COMPLETED,
        isActive: true
      }),
      new TimelineSectionConfig({
        componentName: TIMELINE_SECTION_COMPONENT_NAMES.TREATMENT_STARTED,
        canOpen: false
      }),
      new TimelineSectionConfig({
        componentName: TIMELINE_SECTION_COMPONENT_NAMES.TREATMENT_PAID_ONLINE
      }),
      new TimelineSectionConfig({
        componentName: TIMELINE_SECTION_COMPONENT_NAMES.TREATMENT_OFFER_CREATED
      })
    ]
  }
};

export default {
  name: 'TreatmentDetailsTimelineSections',
  components: {
    TreatmentPaidOnlineTimelineSection,
    TreatmentOfferCreatedTimelineSection,
    TreatmentCompletedTimelineSection,
    TreatmentStartedTimelineSection,
    TreatmentCreatedTimelineSection
  },
  props: {
    treatment: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    timelines() {
      const { statusEvent, orderStatus } = this.treatment;
      const isTimelinesOfflineTreatmentFlow =
        orderStatus === TREATMENT_ORDERS_STATUSES.TREATMENT_FOR_FREE ||
        orderStatus === TREATMENT_ORDERS_STATUSES.TREATMENT_PAID_OFFLINE;

      if (isTimelinesOfflineTreatmentFlow) {
        return OFFLINE_TREATMENT_FLOW_TIMELINES_CONFIG[statusEvent] || [];
      }

      return ONLINE_TREATMENT_FLOW_TIMELINES_CONFIG[statusEvent][orderStatus] || [];
    }
  }
};
</script>

<style lang="scss" scoped>
.timeline-sections {
  &__section {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 20px;
    }
  }
}
</style>
