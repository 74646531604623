<template>
  <modal
    name="email-associated-error-modal"
    :classes="['modal-window']"
    transition="scale"
    adaptive
    scrollable
    :width="300"
    height="auto"
  >
    <div class="modal-container">
      <div class="modal-window-content">
        <div class="modal__content-container">
          <div v-if="isEmailUsedByAnotherDoctorPatient">
            <div class="text-align-center modal__text-container">
              <i18n
                path="dashboard.formulation.patientFromAnotherDoctor"
                tag="span"
                class="pre-wrap"
              >
                <span class="bold">{{ anotherDoctorsName }}</span>
                <span class="mail-text">info@universkin.com</span>
              </i18n>
            </div>
          </div>
          <div v-else-if="isEmailUsedByThisDoctorPatient">
            <div class="text-align-center modal__text-container">
              <i18n
                v-if="isQuickTreatmentCreation"
                path="dashboard.warning.patientExist"
                tag="span"
              >
                <span class="mail-text bold pointer" @click="onPatientNameClick">
                  {{ errorPatientName }}
                </span>
              </i18n>
              <span v-else>
                {{ $t('dashboard.warning.patientExist', [errorPatientName]) }}
              </span>
            </div>
          </div>
          <div v-else-if="isEmailUsedByDoctor">
            <div class="text-align-center modal__text-container">
              {{ $t('dashboard.warning.doctorExist') }}
            </div>
          </div>
        </div>
        <div class="flex-column flex--center--center">
          <div
            v-if="isQuickTreatmentCreation && isEmailUsedByThisDoctorPatient"
            class=" rounded-button-white mb20"
            @click="continueClickHandler"
          >
            {{ $t('dashboard.action.continue') }}
          </div>
          <a class="cancel-button" @click="cancel">
            {{ $t('dashboard.action.back') }}
          </a>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapGetters } from 'vuex';
import { get } from 'lodash';

import { getFullName } from '@/modules/dashboard/utils';

import { types } from '@/modules/dashboard/store/types';
import { EMAIL_ERRORS } from '@/constants';

export default {
  name: 'EmailAssociatedErrorModal',
  props: {
    isQuickTreatmentCreation: {
      type: Boolean,
      default: false
    },
    isRecommendedTreatmentCreation: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      emailError: types.getters.PATIENT_INFO_EMAIL_ERROR
    }),
    emailErrorType() {
      return get(this.emailError, 'type', '');
    },
    emailErrorData() {
      return get(this.emailError, 'data', {});
    },
    anotherDoctorsName() {
      return get(this.emailError, ['data', 'doctorName'], '');
    },
    isEmailUsedByAnotherDoctorPatient() {
      return this.emailErrorType === EMAIL_ERRORS.EMAIL_USED_BY_ANOTHER_DOCTOR_PATIENT;
    },
    isEmailUsedByThisDoctorPatient() {
      return this.emailErrorType === EMAIL_ERRORS.EMAIL_USED_BY_THIS_DOCTOR_PATIENT;
    },
    isEmailUsedByDoctor() {
      return this.emailErrorType === EMAIL_ERRORS.EMAIL_USED_BY_DOCTOR;
    },
    errorPatientName() {
      return getFullName(this.emailErrorData);
    },
    continueClickHandler() {
      return this.isRecommendedTreatmentCreation ? this.onContinueClick : this.onPatientNameClick;
    }
  },
  methods: {
    onPatientNameClick() {
      this.$emit('patient-name-click');
    },
    onContinueClick() {
      this.$emit('continue-click', this.emailErrorData);
    },
    cancel() {
      this.$emit('close');
      this.$modal.hide('email-associated-error-modal');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/css/common/text';
@import '../../../../assets/scss/common/buttons';
@import '../../../../assets/scss/helpers/colors';

.modal {
  &-window-content {
    padding: 50px 30px 30px;
  }

  &__content-container {
    margin-bottom: 25px;
  }

  &__text-container {
    margin-bottom: 20px;

    font-size: 13px;
    line-height: 150%;
  }

  &__text-link {
    color: $links-color;
    font-weight: 600;
  }
}

.mail-text {
  font-weight: bold;
}

.space-margin-left-3 {
  margin-left: -3px;
}

.space-margin-right-3 {
  margin-right: -3px;
}
</style>
