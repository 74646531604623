<template>
  <div class="diagnostic-tab__container">
    <mobile-header>
      <span slot="header-title" class="header-title">
        {{ $t('dashboard.statistics.diagnostics') }}
      </span>
    </mobile-header>
    <div v-if="hasDiagnostics" class="diagnostic-tab">
      <div class="diagnostic-tab__list">
        <diagnostic
          v-for="diagnostic in diagnostics"
          :key="diagnostic.id"
          :diagnostic="diagnostic"
          :is-active="isActive(diagnostic)"
          has-open-icon
          class="diagnostic-tab__diagnostic"
          @click.native="activate(diagnostic)"
        />
      </div>
      <div class="diagnostic-tab__detail otherXS">
        <diagnostic-details
          v-if="activeDiagnosticId"
          :key="activeDiagnosticId"
          @create-treatment="onCreateTreatment"
        />
      </div>
    </div>
    <div v-else class="default-view flex-column--align-center">
      <div class="default-view__icon icon-diagnostics-tab-empty custom-icon"></div>
      <span class="default-view__label no-search-field">
        {{ $t('dashboard.label.noNotifications') }}
      </span>
      <span class="default-view__description no-search-field">
        {{ $t('dashboard.label.startOrSendQuestionnaire') }}
      </span>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { isEmpty } from 'lodash';

import { types as dashTypes } from '@/modules/dashboard/store/types';

import Diagnostic from '@/modules/dashboard/components/diagnostic-tab/Diagnostic';
import DiagnosticDetails from '@/modules/dashboard/components/diagnostic-tab/DiagnosticDetails';
import MobileHeader from '@/modules/dashboard/components/common/MobileHeader';

import { isMobileDevice } from '@/modules/dashboard/utils';

import { PATIENT_TAB_NAMES } from '@/modules/dashboard/api/constants';

export default {
  name: 'DiagnosticTab',
  components: { MobileHeader, DiagnosticDetails, Diagnostic },
  computed: {
    ...mapGetters({
      diagnostics: dashTypes.getters.CURRENT_PATIENT_DIAGNOSTICS,
      activeDiagnosticId: dashTypes.getters.ACTIVE_DIAGNOSTIC_ID
    }),
    hasDiagnostics() {
      return !isEmpty(this.diagnostics);
    }
  },
  created() {
    if (isMobileDevice()) {
      return;
    }

    const isActiveDiagnosticSet =
      this.activeDiagnosticId && this.diagnostics.some(({ id }) => id === this.activeDiagnosticId);

    if (isActiveDiagnosticSet) {
      return;
    }

    this.setFirstDiagnosticAsActive();
  },
  destroyed() {
    this.resetActiveDiagnosticId();
  },
  methods: {
    ...mapActions({
      setActiveDiagnosticId: dashTypes.actions.SET_ACTIVE_DIAGNOSTIC_ID,
      setFirstDiagnosticAsActive: dashTypes.actions.SET_FIRST_DIAGNOSTIC_AS_ACTIVE,
      resetActiveDiagnosticId: dashTypes.actions.RESET_ACTIVE_DIAGNOSTIC_ID,
      setActivePatientTab: dashTypes.actions.SET_ACTIVE_PATIENT_TAB
    }),
    isActive({ id }) {
      return this.activeDiagnosticId === id;
    },
    activate({ id }) {
      this.setActiveDiagnosticId(id);

      if (isMobileDevice()) {
        this.$router.push({
          name: 'DiagnosticDetails',
          params: {
            diagnosticId: id
          }
        });
      }
    },
    onCreateTreatment() {
      this.setActivePatientTab(PATIENT_TAB_NAMES.CREATE_TREATMENT);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/helpers/colors';
@import '../../../../assets/scss/common/buttons';
@import '../../../../assets/css/common/icons.css';

.header-title {
  color: $main-title-color;
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
}

.default-view {
  background-color: $background-darker;

  font-size: 15px;
  line-height: 17px;
  color: $grey-color;

  padding: 60px 0 140px 0;

  &__label {
    margin: 40px 0 20px 0;
  }

  &__description {
    font-size: 11px;
    max-width: 200px;
    text-align: center;
  }
}

.diagnostic-tab {
  display: flex;

  &__list {
    width: 50%;
  }

  &__diagnostic {
    height: 60px;
    padding: 0 25px;
  }

  &__detail {
    width: 50%;
    min-width: 467px;
  }
}

@media (max-width: 767px) {
  .default-view {
    margin-top: 46px;
    height: calc(100vh - 46px);
  }

  .diagnostic-tab {
    margin-top: 46px;

    &__container {
      height: 100vh;
      display: flex;
      flex-direction: column;
    }

    &__list {
      width: 100%;
    }

    &__diagnostic {
      padding: 0 30px 0 15px;
    }
  }
}
</style>
