<template>
  <div>
    <photo-analysis-add-photo-modal />
    <analyzing-photo-modal />
    <photo-validation-modal @try-again="handlePhotoAnalysisRoutingInfo" />
    <analysis-results-overview-modal />
    <photo-analysis-result-update-modal />
    <clarification-modal />
    <quit-photo-analysis-warning-modal />
  </div>
</template>

<script>
import PhotoAnalysisAddPhotoModal from '@/modules/dashboard/components/dashboard/create-treatment/photo-analysis/PhotoAnalysisAddPhotoModal';
import AnalyzingPhotoModal from '@/modules/dashboard/components/dashboard/create-treatment/photo-analysis/AnalyzingPhotoModal';
import PhotoValidationModal from '@/modules/dashboard/components/dashboard/common/PhotoValidationModal';
import AnalysisResultsOverviewModal from '@/modules/dashboard/components/dashboard/create-treatment/photo-analysis/AnalysisResultsOverviewModal';
import PhotoAnalysisResultUpdateModal from '@/modules/dashboard/components/dashboard/create-treatment/photo-analysis/PhotoAnalysisResultUpdateModal';
import ClarificationModal from '@/modules/dashboard/components/dashboard/create-treatment/photo-analysis/ClarificationModal';
import QuitPhotoAnalysisWarningModal from '@/modules/dashboard/components/dashboard/create-treatment/photo-analysis/QuitPhotoAnalysisWarningModal';

import { handleRoutingInfo } from '@/modules/dashboard/utils';

import { PHOTO_ANALYSIS_ROUTING_INFO } from '@/modules/dashboard/api/constants';

export default {
  name: 'PhotoAnalysisModals',
  components: {
    QuitPhotoAnalysisWarningModal,
    ClarificationModal,
    PhotoAnalysisResultUpdateModal,
    AnalysisResultsOverviewModal,
    PhotoValidationModal,
    AnalyzingPhotoModal,
    PhotoAnalysisAddPhotoModal
  },
  methods: {
    handlePhotoAnalysisRoutingInfo() {
      handleRoutingInfo(PHOTO_ANALYSIS_ROUTING_INFO, this.$router, this.$modal);
    }
  }
};
</script>
