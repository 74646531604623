<template>
  <TemplateWithHeader>
    <div class="patient-card__container">
      <div class="patient-card">
        <div class="patient-card__title">
          <div
            class="icon-arrow-long-left custom-icon patient-card__title__back-button"
            @click="returnToDashboard"
          />
          <div class="patient-card__label">
            {{ $t('dashboard.label.patientCard') }}
          </div>
        </div>
        <PatientInfo @send-invite-template="loadPatientData" />
        <PatientTabs v-if="isPatientLoaded" id="patient-tabs-container" class="patient-tabs" />
      </div>
    </div>

    <photo-zoom-modal />
    <photo-analysis-modals />
  </TemplateWithHeader>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';

import TemplateWithHeader from '@/modules/dashboard/components/templates/TemplateWithHeader';
import PatientInfo from '@/modules/dashboard/components/patient-card/PatientInfo';
import PatientTabs from '@/modules/dashboard/components/patient-card/PatientTabs';
import PhotoAnalysisModals from '@/modules/dashboard/components/common/PhotoAnalysisModals';
import PhotoZoomModal from '@/modules/dashboard/components/common/PhotoZoomModal';

import { downloadFile } from '@/modules/dashboard/utils';
import { getPdfSummaryAsBlob } from '@/modules/dashboard/api';

import rootTypes from '@/store/types';
import { types as dashTypes } from '@/modules/dashboard/store/types';
import { PATIENT_TAB_NAMES } from '@/modules/dashboard/api/constants';
import { PDF_SUMMARY_NAME } from '@/constants';

export default {
  name: 'PatientCard',
  components: {
    TemplateWithHeader,
    PatientInfo,
    PatientTabs,
    PhotoAnalysisModals,
    PhotoZoomModal
  },
  props: {
    id: {
      type: Number,
      required: true
    },
    showTreatmentRecommendations: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isPatientLoaded: false
    };
  },
  computed: {
    ...mapGetters({
      currentPatientId: dashTypes.getters.CURRENT_PATIENT_ID,
      pdfSummary: dashTypes.getters.PDF_SUMMARY,
      getDiagnosticById: dashTypes.getters.GET_CURRENT_PATIENT_DIAGNOSTIC_BY_ID
    })
  },
  created() {
    if (this.currentPatientId && this.currentPatientId !== this.id) {
      this.resetCurrentPatient();
    }
  },
  async mounted() {
    await this.loadPatientData();

    if (this.pdfSummary && this.pdfSummary.patientId === this.id) {
      await this.getPdfSummaryInfo();
    }

    if (this.showTreatmentRecommendations) {
      this.$scrollTo('#patient-tabs-container', 500, { offset: 70 });
    }
  },
  methods: {
    ...mapMutations({
      resetCurrentPatient: dashTypes.mutations.RESET_CURRENT_PATIENT
    }),
    ...mapActions({
      setLoading: rootTypes.actions.SET_LOADING,
      loadPatient: dashTypes.actions.LOAD_PATIENT,
      setActivePatientTab: dashTypes.actions.SET_ACTIVE_PATIENT_TAB,
      setActiveDiagnosticId: dashTypes.actions.SET_ACTIVE_DIAGNOSTIC_ID,
      resetPdfSummary: dashTypes.actions.RESET_PDF_SUMMARY
    }),
    async loadPatientData() {
      this.setLoading(true);
      await this.loadPatient(this.id);
      this.setLoading(false);

      this.isPatientLoaded = true;
    },
    async loadDiagnosticPdfSummary(patientId, diagnosticId) {
      const { questionnaireId } = this.getDiagnosticById(diagnosticId);

      this.setLoading(true);

      const { data: blob } = await getPdfSummaryAsBlob({
        patientId,
        diagnosticId,
        questionnaireId
      });

      await downloadFile(blob, { fileName: PDF_SUMMARY_NAME });

      this.setLoading(false);
    },
    async getPdfSummaryInfo() {
      const { patientId, diagnosticId, isDirect } = this.pdfSummary;

      this.setActivePatientTab(PATIENT_TAB_NAMES.DIAGNOSTICS);
      this.setActiveDiagnosticId(diagnosticId);

      if (isDirect) {
        await this.loadDiagnosticPdfSummary(patientId, diagnosticId);
      }

      this.resetPdfSummary();
    },
    returnToDashboard() {
      this.$router.push({ name: 'Dashboard' }, () => {
        this.resetCurrentPatient();
      });
    }
  },
  metaInfo: {
    title: 'Patient Card',
    titleTemplate: 'skinXs - %s'
  }
};
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/helpers/colors';

.patient-card {
  width: 100%;

  font-size: 15px;
  line-height: 17px;

  display: flex;
  flex-direction: column;

  &__container {
    display: flex;
    justify-content: center;

    cursor: default;
  }

  &__label {
    display: inline-block;

    font-size: 18px;
    font-weight: 600;
    color: $main-title-color;
  }

  &__title {
    height: 75px;

    display: flex;
    align-items: center;

    &__back-button {
      display: inline-block;
      margin: 0 12px 2px 0;
    }
  }
}

@media (max-width: 767px) {
  .patient-card {
    &__title {
      height: 45px;

      padding-left: 15px;

      background-color: $background;
    }

    &__label {
      font-size: 14px;
    }
  }

  .patient-tabs {
    background: $background;
    padding-bottom: 20px;
  }

  .patient-tabs /deep/ .tabs {
    width: 100%;

    flex-direction: column;

    &__container {
      height: 250px;

      margin: 10px 0 20px;

      border-bottom: none;
    }

    .tab {
      height: 40px;
      justify-content: flex-start;

      padding: 10px 15px;

      font-size: 13px;
      font-weight: 600;
      line-height: 16px;

      letter-spacing: 0.05em;

      color: $text-color;

      cursor: pointer;

      &--active {
        border-bottom: none;

        cursor: pointer;
      }
    }
  }
}
</style>
