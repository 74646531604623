<template>
  <modal
    name="delete-photo-modal"
    :classes="['modal-window']"
    transition="scale"
    adaptive
    scrollable
    :width="300"
    height="auto"
  >
    <div class="modal-container compare-photo-window">
      <div class="flex--center--center">
        <div class="modal-body">
          <div class="modal-title">{{ $t('dashboard.label.deletePhoto') }}</div>
          <div class="link action-buttons flex--align-center">
            <button class="rounded-button-gray" type="button" @click="cancel">
              {{ $t('dashboard.action.cancel') }}
            </button>
            <button class="rounded-button-white" type="button" @click="confirmRemove">
              {{ $t('dashboard.action.yesDelete') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
export default {
  name: 'DeletePhotoModal',
  methods: {
    confirmRemove() {
      this.$emit('photo-delete');
    },
    cancel() {
      this.$modal.hide('delete-photo-modal');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/helpers/colors';
@import '../../../../assets/scss/common/buttons';

.modal-body {
  padding: 60px 30px 55px;
}

.modal-title {
  margin-bottom: 40px;
  font-size: 15px;
  line-height: 17px;
  font-weight: 400;
  color: $text-color;
  text-align: center;
}

.rounded-button-gray {
  border: none;
}

@media (max-width: 471px) {
  .action-buttons button:last-child {
    margin-top: 5px;
  }

  .modal-title {
    font-size: 13px;
    line-height: 15px;
  }
}
</style>
