<template>
  <div
    class="patient-info__container"
    :class="{ 'patient-info__show-more-container': !isMoreShowed }"
  >
    <div class="patient-info__patient-photo-container otherXS" />
    <div class="patient-info">
      <div class="patient-info__header-info-container">
        <div
          v-if="hasPhoto"
          class="photo__container patient-info__patient-photo"
          :style="patientPhotoStyles"
          @click="showPhoto"
        ></div>
        <p
          v-else
          class="photo__container patient-info__patient-photo first-letter patient-avatar-letter"
        >
          {{ firstLetter }}
        </p>
        <div class="patient-info__header-info">
          <div class="patient-info__name">
            {{ patientDisplayedFullName }}
          </div>
          <div class="patient-info__status">
            <notification-subject
              v-if="currentPatientValue.status"
              :subject="currentPatientValue.status"
              display-text
              :patient-chrono="currentPatientValue.chronoDto"
            />
          </div>
          <div class="patient-info__edit-button-container otherXS">
            <button class="rounded-button-gray" @click.stop="showEditPatientModal">
              <div class="inline-block icon-pencil custom-icon"></div>
              {{ $t('dashboard.button.edit') }}
            </button>
          </div>
          <div class="patient-info__edit-button-container onlyXS">
            <button class="rounded-button-gray flex--align-center" @click="openEditPatientPage">
              <div class="inline-block icon-pencil custom-icon mr10"></div>
              {{ $t('dashboard.button.edit') }}
            </button>
          </div>
        </div>
      </div>
      <div
        class="patient-info__general"
        :class="{ 'patient-info__general__show-more-container': !isMoreShowed }"
      >
        <div
          class="patient-info__general__container"
          :class="{ 'patient-info__general__with-show-more': !isMoreShowed }"
        >
          <div class="patient-info__general__field">
            <div class="patient-info__general__field__title">
              {{ $t('dashboard.patientCard.age') }}
            </div>
            <div
              class="patient-info__general__field__value"
              :class="{ 'not-answered': !currentPatientValue.age }"
            >
              {{ getValueOrNA(currentPatientValue.age) }}
            </div>
          </div>
          <div class="patient-info__general__field">
            <div class="patient-info__general__field__title">
              {{ $t('dashboard.patientCard.gender') }}
            </div>
            <div
              class="patient-info__general__field__value"
              :class="{ 'not-answered': !currentPatientValue.gender }"
            >
              {{ patientGender }}
            </div>
          </div>

          <div
            class="patient-info__general__field"
            :class="{ 'patient-info__general__with-show-more': !isMoreShowed }"
          >
            <div class="patient-info__general__field__title">
              {{ $t('dashboard.patientCard.registered') }}
            </div>
            <div class="patient-info__general__field__value">
              {{ currentPatientValue.registrationDate | formatDate }}
            </div>
          </div>

          <div
            class="patient-info__general__field"
            :class="{ 'patient-info__general__with-show-more': !isMoreShowed }"
          >
            <div class="patient-info__general__field__title">
              {{ $t('dashboard.patientCard.source') }}
            </div>
            <div class="patient-info__general__field__value">
              {{ $t(currentPatientSource) }}
            </div>
          </div>

          <div v-if="isDoctorHasClinics" class="patient-info__general__field">
            <div class="patient-info__general__field__title">
              {{ $t('dashboard.patientCard.clinic') }}
            </div>
            <span
              v-if="!!patientClinic"
              class="patient-info__general__clinic__color mr5"
              :style="{ backgroundColor: patientClinic.color }"
            />
            <div
              class="patient-info__general__field__value"
              :class="{ 'not-answered': !patientClinic }"
            >
              <div class="patient-info__general__clinic__name ellipsized">
                {{ getValueOrNA(patientClinicName) }}
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="!isMoreShowed"
          class="patient-info__general__show-more pointer onlyXS"
          @click="showMore"
        >
          More information
        </div>
        <div class="patient-info__general__container">
          <div class="patient-info__general__field">
            <div class="flex--align-center">
              <div class="patient-info__general__field__title">
                {{ $t('dashboard.patientCard.mobile') }}
              </div>
              <a
                v-if="isPatientHasPhone"
                :href="`tel:${patientPhone}`"
                class="flex--align-center patient-info__general__action-button"
              >
                <span class="icon-phone-right-orange custom-icon pointer" />
              </a>
              <div
                class="patient-info__general__field__value"
                :class="{ 'not-answered': !isPatientHasPhone }"
              >
                {{ transformedPatientPhone }}
              </div>
            </div>
          </div>
          <div class="patient-info__general__field">
            <div class="flex--align-center">
              <div class="patient-info__general__field__title">
                {{ $t('dashboard.patientCard.email') }}
              </div>
              <a
                v-if="currentPatientValue.email"
                :href="`mailto:${currentPatientValue.email}`"
                class="flex--align-center patient-info__general__action-button"
              >
                <span class="fas fa-envelope dropdown-icon pointer" />
              </a>
              <div
                v-tooltip.top="{ content: currentPatientValue.email, classes: 'link-tooltip' }"
                class="patient-info__general__field__value ellipsized"
              >
                {{ currentPatientValue.email }}
              </div>
            </div>
          </div>
          <div class="patient-info__general__field">
            <div class="patient-info__general__field__title">
              {{ $t('dashboard.patientCard.address') }}
            </div>
            <div
              class="patient-info__general__field__value--address line-clamp"
              :class="{ 'not-answered': !currentPatientValue.patientAddress }"
            >
              {{ patientFullAddress }}
            </div>
          </div>
        </div>
      </div>
      <div class="patient-info__buttons-container flex--space-between--center">
        <div class="patient-info__left-button-container">
          <button class="rounded-button-white mr20 mb10" @click="startPhotoAnalysis">
            {{ $t('dashboard.patientCard.startPhotoAnalysis') }}
          </button>
          <button class="rounded-button-white" @click="sendQuestionnaire">
            {{ $t('dashboard.action.sendQuestionnaire') }}
          </button>
        </div>
      </div>
    </div>

    <send-patient-invite-modal />
    <invite-template-sent-modal @close-modal="onInviteTemplateSent" />

    <edit-patient-info-modal :patient-photo="patientPhoto" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { get } from 'lodash';

import NotificationSubject from '@/modules/dashboard/components/notifications/NotificationSubject';
import EditPatientInfoModal from '@/modules/dashboard/components/patient-card/EditPatientInfoModal';
import SendPatientInviteModal from '@/modules/dashboard/components/patient-card/invite/SendPatientInviteModal';
import InviteTemplateSentModal from '@/modules/dashboard/components/dashboard/invite-patient/InviteTemplateSentModal';

import {
  buildImageUrl,
  getFullName,
  getPatientDisplayedName,
  getPatientFirstLetter,
  hasPatientPhoto,
  isMobileDevice,
  handleRoutingInfo
} from '@/modules/dashboard/utils';
import { displayNameMixin } from '@/mixins/displayNameMixin';

import { getPatientPhone, getPatientTransformedPhone } from '@/utils';
import { getCountryState } from '@/api/places';

import rootTypes from '@/store/types';
import { types } from '@/modules/dashboard/store/types';
import { NOT_ANSWERED_VALUE_KEY } from '@/constants';
import {
  PHOTO_ANALYSIS_ROUTING_INFO,
  PATIENT_SOURCE,
  PATIENT_SOURCE_VALUE
} from '@/modules/dashboard/api/constants';
import { fetchPatientPhotoByPhotoId } from '@/modules/dashboard/api';

export default {
  name: 'PatientInfo',
  components: {
    InviteTemplateSentModal,
    SendPatientInviteModal,
    NotificationSubject,
    EditPatientInfoModal
  },
  mixins: [displayNameMixin],
  data() {
    return {
      isMoreShowed: false,
      patientPhoto: null
    };
  },
  computed: {
    ...mapGetters({
      doctor: rootTypes.getters.GET_USER,
      doctorClinics: rootTypes.getters.GET_USER_CLINICS,
      currentPatient: types.getters.CURRENT_PATIENT,
      currentPatientPhotoId: types.getters.CURRENT_PATIENT_PHOTO_ID
    }),
    currentPatientValue() {
      return this.currentPatient || {};
    },
    currentPatientSource() {
      const { patientAppearanceSource, influencerName } = this.currentPatientValue;

      return patientAppearanceSource === PATIENT_SOURCE.INVITED_BY_INFLUENCER
        ? influencerName
        : PATIENT_SOURCE_VALUE[patientAppearanceSource];
    },
    firstLetter() {
      return getPatientFirstLetter(this.currentPatientValue);
    },
    hasPhoto() {
      return hasPatientPhoto(this.patientPhoto);
    },
    patientPhotoStyles() {
      return { 'background-image': `url(${buildImageUrl(this.patientPhoto)})` };
    },
    patientPhotoUrl() {
      return buildImageUrl(this.patientPhoto);
    },
    patientFullName() {
      return getFullName(this.currentPatientValue);
    },
    patientDisplayedFullName() {
      return getPatientDisplayedName(this.currentPatientValue);
    },
    patientGender() {
      const patientGender = get(this.currentPatientValue, 'gender');

      const genderValueKey = patientGender
        ? `dashboard.gender.${patientGender}`
        : NOT_ANSWERED_VALUE_KEY;

      return this.$t(genderValueKey);
    },
    patientAddress() {
      const patientAddress = get(this.currentPatientValue, 'patientAddress');

      return patientAddress;
    },
    patientCountry() {
      if (!this.patientAddress) {
        return;
      }

      const { countryCode } = this.patientAddress;

      return this.getCountryDisplayName(countryCode);
    },
    patientCountryState() {
      if (!this.patientAddress) {
        return;
      }

      const { countryCode, state } = this.patientAddress;

      if (!countryCode || !state) {
        return state;
      }

      const countryState = getCountryState(countryCode, state);

      return countryState ? countryState.label : state;
    },
    patientFullAddress() {
      if (!this.patientAddress) {
        return this.getValueOrNA(null);
      }

      const { zipCode, city, address } = this.patientAddress;

      return [this.patientCountry, this.patientCountryState, zipCode, city, address]
        .filter(part => !!part)
        .join(', ');
    },
    isDoctorHasClinics() {
      return this.doctorClinics.length > 1;
    },
    patientClinic() {
      const { clinicDto } = this.currentPatientValue;

      if (!clinicDto || !clinicDto.id) {
        return null;
      }

      return {
        color: clinicDto.clinicColor,
        name: clinicDto.clinicName
      };
    },
    patientClinicName() {
      return this.patientClinic && this.patientClinic.name;
    },
    patientPhone() {
      return getPatientPhone(this.currentPatientValue) || this.$t(NOT_ANSWERED_VALUE_KEY);
    },
    isPatientHasPhone() {
      return this.patientPhone !== this.$t(NOT_ANSWERED_VALUE_KEY);
    },
    transformedPatientPhone() {
      const transformedPhone = getPatientTransformedPhone(this.currentPatientValue);

      return transformedPhone || this.patientPhone;
    }
  },
  watch: {
    currentPatientPhotoId: {
      handler: 'loadPatientPhoto',
      immediate: true
    }
  },
  methods: {
    ...mapActions({
      setLoading: rootTypes.actions.SET_LOADING
    }),
    getValueOrNA(value) {
      return value || this.$t(NOT_ANSWERED_VALUE_KEY);
    },
    showMore() {
      this.isMoreShowed = true;
    },
    startPhotoAnalysis() {
      handleRoutingInfo(PHOTO_ANALYSIS_ROUTING_INFO, this.$router, this.$modal);
    },
    sendQuestionnaire() {
      if (isMobileDevice()) {
        this.$router.push({ name: 'SendPatientInviteTabs' });

        return;
      }

      this.$modal.show('send-patient-invite-modal');
    },
    showEditPatientModal() {
      this.$modal.show('edit-patient-info-modal');
    },
    openEditPatientPage() {
      this.$router.push({
        name: 'EditPatientInfo'
      });
    },
    showPhoto() {
      this.$modal.show('photo-zoom-modal', { photoUrl: this.patientPhotoUrl });
    },
    onInviteTemplateSent() {
      this.$emit('send-invite-template');
    },
    async loadPatientPhoto(photoId) {
      if (!photoId) {
        this.patientPhoto = null;

        return;
      }

      const { data } = await fetchPatientPhotoByPhotoId(this.currentPatientValue.id, photoId);
      this.patientPhoto = data;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/css/common/photos';
@import '../../../../assets/css/common/tooltips';
@import '../../../../assets/scss/helpers/colors';
@import '../../../../assets/scss/common/buttons';
@import '../../../../assets/scss/common/first-letter.scss';

.first-letter {
  width: 130px;
  height: 130px;
  line-height: 130px;
}

.patient-info {
  flex-grow: 1;

  padding: 30px 40px 30px 0;

  &__patient-photo {
    position: absolute;

    top: 10px;
    left: -170px;

    cursor: pointer;

    &-container {
      width: 210px;
      height: 100%;

      padding: 40px;
    }
  }

  &__container {
    width: 100%;
    min-height: 345px;

    display: flex;

    background-color: $white;

    border-radius: 8px;
  }

  &__header-info-container {
    display: flex;
    flex-direction: column;

    position: relative;
  }

  &__name {
    font-size: 18px;
    font-weight: 600;
    color: $main-title-color;
  }

  &__status {
    margin-top: 15px;
  }

  &__edit-button-container {
    position: absolute;

    top: 251px;
    right: 0;
  }

  &__general {
    height: 180px;

    display: flex;
    justify-content: flex-start;
    flex-flow: column wrap;

    padding: 20px 0;

    &__container {
      display: flex;
      flex-direction: column;
      width: 50%;
    }

    &__field {
      display: flex;
      align-items: flex-start;

      margin-top: 10px;

      &__title {
        min-width: 80px;

        display: flex;
        align-items: center;

        font-size: 13px;
        line-height: 18px;
        color: $text-color;
      }

      &__value {
        display: inline-block;
        width: 100%;
        max-width: 220px;

        font-size: 15px;
        line-height: 18px;

        color: $main-title-color;
      }
    }

    &__action-button {
      margin-right: 6px;
      margin-left: -4px;
      padding: 4px;

      color: $orange-color;
    }

    &__clinic {
      &__color {
        display: inline-block;
        width: 11px;
        height: 11px;

        padding: 1px;

        border-radius: 10px;

        box-sizing: content-box;
      }
    }
  }

  &__buttons-container {
    padding: 18px 150px 0 0;
    border-top: 1px solid $dim-white;
  }
}

.not-answered {
  color: $icon-grey-color;
}

.questionnaire-buttons-middle-label {
  padding: 0 7px 0 4px;
}

.line-clamp {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
}

@media (max-width: 1100px) {
  .patient-info {
    &__general {
      &__field {
        &__value {
          max-width: 80px;
          white-space: nowrap;

          &--address {
            white-space: initial;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .first-letter {
    line-height: 82px;
    font-size: 25px;
  }

  .patient-info {
    padding: 15px;

    &__container {
      border-radius: 0;
    }

    &__show-more-container {
      height: auto;
      min-height: auto;
    }

    &__header-info {
      display: flex;
      flex-direction: column;

      margin-left: 30px;

      &-container {
        height: 82px;

        display: flex;
        flex-direction: row;
      }
    }

    &__name {
      font-size: 13px;
      line-height: 16px;
      margin-bottom: 9px;
    }

    &__patient-photo {
      width: 82px;
      height: 82px;

      position: static;
    }

    &__status {
      margin: 0 0 10px;
    }

    &__edit-button-container {
      position: static;
    }

    &__general {
      width: 290px;
      height: 240px;
      padding: 20px 0 16px;

      flex-flow: column-reverse nowrap;
      justify-content: flex-end;

      &__show-more-container {
        height: auto;
      }

      &__show-more {
        font-size: 13px;
        margin-top: 10px;
        color: $orange-color;
      }

      &__field {
        min-height: 25px;

        flex-shrink: 0;

        margin: 0;

        &__title {
          font-size: 12px;
        }

        &__value,
        &__value--address {
          font-size: 13px;
          line-height: 25px;
          max-width: 180px;
        }
      }

      &__with-show-more {
        display: none;
      }

      &__container {
        width: auto;
      }
    }

    &__buttons-container {
      height: auto;
      padding: 15px 0 0 0;
      font-size: 13px;
    }
  }

  /deep/ .notification {
    .notification__icon {
      margin: 0;
      width: 14px;
      height: 16px;
    }

    .notification__text,
    .notification__treatment-count {
      font-size: 10px;
    }
  }
}
</style>
