<template>
  <tabs class="treatment-timeline-tabs" v-bind="$attrs" v-on="$listeners">
    <slot slot="tab-content" name="tab-content"></slot>
    <span slot="custom-tabs" slot-scope="{ label }" class="treatment-timeline-tabs__tab-label">
      {{ getTabLabel(label) }}
    </span>
  </tabs>
</template>

<script>
import Tabs from '@/modules/dashboard/components/common/Tabs';

export default {
  name: 'TreatmentTimelineTabs',
  components: { Tabs },
  props: {
    isLabelKey: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    getTabLabel(value) {
      return this.isLabelKey ? this.$t(value) : value;
    }
  }
};
</script>

<style lang="scss" scoped>
.treatment-timeline-tabs {
  &__tab-label {
    font-size: 14px;
    line-height: 16px;
  }
}
</style>
