<template>
  <section class="timeline-section" :class="{ 'timeline-section--active': isActive }">
    <slide-down :show="isActive">
      <slot slot="toggle" slot-scope="{ isShown }" name="header" :is-shown="isShown" />
      <slot slot="slide-body" slot-scope="{ isShown }" name="content" :is-shown="isShown" />
    </slide-down>
  </section>
</template>

<script>
import SlideDown from '@/modules/dashboard/components/common/SlideDown';

export default {
  name: 'TimelineSection',
  components: { SlideDown },
  props: {
    isActive: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../../assets/scss/helpers/colors';

.timeline-section {
  padding: 10px 10px 10px 20px;

  border-radius: 8px;
  border: 1px solid $border-light-grey-color;

  &--active {
    background-color: $background;
    border-color: $background;
  }
}

@media (max-width: 767px) {
  .timeline-section {
    border-radius: 0;
  }
}
</style>
