<template>
  <div v-if="photo" class="centered-background-photo" :style="centeredPhotoStyles"></div>
</template>

<script>
import { buildPatientPhotoStyles } from '@/modules/dashboard/utils';

import { PHOTO_VUE_TYPES } from '@/modules/dashboard/api/constants';

export default {
  name: 'Photo',
  props: {
    photo: PHOTO_VUE_TYPES
  },
  computed: {
    centeredPhotoStyles() {
      return buildPatientPhotoStyles(this.photo);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/common/photos';
</style>
