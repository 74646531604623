<template>
  <modal
    name="photo-analysis-result-update-modal"
    :classes="['modal-window']"
    transition="scale"
    scrollable
    adaptive
    :click-to-close="false"
    :width="800"
    height="auto"
  >
    <div class="modal-container">
      <photo-analysis-result-update />
    </div>
  </modal>
</template>

<script>
import PhotoAnalysisResultUpdate from '@/modules/dashboard/components/dashboard/create-treatment/photo-analysis/PhotoAnalysisResultUpdate';

export default {
  name: 'PhotoAnalysisResultUpdateModal',
  components: { PhotoAnalysisResultUpdate }
};
</script>

<style scoped></style>
