import { render, staticRenderFns } from "./MedicalDataTab.vue?vue&type=template&id=35f8316a&scoped=true&"
import script from "./MedicalDataTab.vue?vue&type=script&lang=js&"
export * from "./MedicalDataTab.vue?vue&type=script&lang=js&"
import style0 from "./MedicalDataTab.vue?vue&type=style&index=0&id=35f8316a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35f8316a",
  null
  
)

export default component.exports