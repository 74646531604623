<template>
  <modal
    name="delete-diagnostic-modal"
    :classes="['modal-window']"
    transition="scale"
    adaptive
    scrollable
    :width="300"
    height="auto"
  >
    <div class="modal-container">
      <div class="modal-window-content flex-column">
        <p class="title">
          {{ $t('dashboard.label.deleteDiagnostic') }}
        </p>
        <div class="buttons-group">
          <a class="cancel-button" @click="cancel">
            {{ $t('dashboard.action.cancel') }}
          </a>
          <button class="rounded-button-white" @click="onDeleteDiagnostic">
            {{ $t('dashboard.action.yesDelete') }}
          </button>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapActions } from 'vuex';

import { deleteDiagnostic } from '@/modules/dashboard/api';
import { isMobileDevice } from '@/modules/dashboard/utils';

import { types as dashTypes } from '@/modules/dashboard/store/types';

export default {
  name: 'DeleteDiagnosticModal',
  props: {
    patientId: {
      type: Number,
      required: true
    },
    diagnosticId: {
      type: Number,
      required: true
    }
  },
  methods: {
    ...mapActions({
      loadPatient: dashTypes.actions.LOAD_PATIENT,
      setFirstDiagnosticAsActive: dashTypes.actions.SET_FIRST_DIAGNOSTIC_AS_ACTIVE,
      resetRecommendationDiagnostic: dashTypes.actions.RESET_RECOMMENDATION_DIAGNOSTIC
    }),
    cancel() {
      this.hideModal();
    },
    async onDeleteDiagnostic() {
      if (this.patientId) {
        await deleteDiagnostic(this.patientId, this.diagnosticId);
        await this.loadPatient(this.patientId);

        this.setFirstDiagnosticAsActive();
        this.resetRecommendationDiagnostic();
      }

      this.hideModal();

      if (isMobileDevice()) {
        this.$emit('return-back');

        this.$router.go(-1);
      }
    },
    hideModal() {
      this.$modal.hide('delete-diagnostic-modal');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/common/buttons';

.modal-window-content {
  align-items: center;
  width: 100%;
  padding: 20px;

  background-color: #ffffff;
  font-family: var(--u-font-family-Gilroy);
  border-radius: 8px;
}

.title {
  margin-top: 40px;
  margin-bottom: 40px;

  font-size: 13px;
  line-height: 19px;

  color: #324752;
}

.cancel-button {
  font-size: 15px;
  line-height: 17px;

  text-decoration: none;

  color: #8da4b0;
}

.rounded-button-white {
  font-size: 15px;
  line-height: 17px;
}

.buttons-group {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 30px;
}
</style>
