<template>
  <modal
    name="analysis-results-overview-modal"
    :classes="['modal-window']"
    transition="scale"
    scrollable
    adaptive
    :click-to-close="false"
    width="800px"
    height="auto"
  >
    <analysis-results-overview class="modal-container" />
  </modal>
</template>

<script>
import AnalysisResultsOverview from '@/modules/dashboard/components/dashboard/create-treatment/photo-analysis/AnalysisResultsOverview';

export default {
  name: 'AnalysisResultsOverviewModal',
  components: { AnalysisResultsOverview }
};
</script>
