<template>
  <modal
    name="analyzing-photo-modal"
    :classes="['modal-window']"
    transition="scale"
    scrollable
    adaptive
    :click-to-close="false"
    width="800px"
    height="700px"
    @before-open="beforeOpen"
  >
    <div class="modal-container">
      <analyzing-photo :has-upload-another-option="hasUploadAnotherOption" />
    </div>
  </modal>
</template>

<script>
import AnalyzingPhoto from '@/modules/dashboard/components/dashboard/create-treatment/photo-analysis/AnalyzingPhoto';

export default {
  name: 'AnalyzingPhotoModal',
  components: { AnalyzingPhoto },
  data() {
    return {
      canUploadAnother: null
    };
  },
  computed: {
    hasUploadAnotherOption() {
      return this.canUploadAnother !== false;
    }
  },
  methods: {
    beforeOpen({ params: { canUploadAnother } }) {
      this.canUploadAnother = canUploadAnother;
    }
  }
};
</script>
