<template>
  <div class="zoomable-photo flex--align-center pointer">
    <span class="zoomable-photo__title" :class="{ none: isBigZoomablePhotoTypes }">Photo</span>
    <div
      class="zoomable-photo__photo"
      :class="{ 'zoomable-photo__photo--big': isBigZoomablePhotoTypes }"
      :style="photoStyles"
      @click="zoomPhoto"
    ></div>
    <span
      class="zoomable-photo__hint cursor-default"
      :class="{ 'zoomable-photo__hint--big': isBigZoomablePhotoTypes }"
    >
      {{ $t('dashboard.label.clickPhotoToZoom') }}
    </span>
    <photo-zoom-modal />
  </div>
</template>

<script>
import PhotoZoomModal from '@/modules/dashboard/components/common/PhotoZoomModal';

import { buildBackgroundPhotoUrlStyles } from '@/modules/dashboard/utils';

import { ZOOMABLE_PHOTO_TYPES } from '@/modules/dashboard/api/constants';

export default {
  name: 'ZoomablePhoto',
  components: { PhotoZoomModal },
  props: {
    photo: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: ZOOMABLE_PHOTO_TYPES.SMALL
    }
  },
  computed: {
    photoStyles() {
      return buildBackgroundPhotoUrlStyles(this.photo);
    },
    isBigZoomablePhotoTypes() {
      return this.type === ZOOMABLE_PHOTO_TYPES.BIG;
    }
  },
  methods: {
    zoomPhoto() {
      // TODO: avoid using one modal in multiple places because
      // it opens multiple modals when calling $show method
      // https://jira.andersenlab.com/browse/UNK-6735
      this.$modal.show('photo-zoom-modal', { photoUrl: this.photo });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../../assets/scss/helpers/colors';

.zoomable-photo {
  flex-wrap: wrap;

  &__title {
    font-size: 13px;
    line-height: 15px;
    color: $text-color;

    margin-right: 10px;
  }

  &__photo {
    width: 54px;
    height: 54px;

    background-size: cover;
    background-position: center center;

    &--big {
      width: 280px;
      height: 280px;

      border-radius: 8px;
    }
  }

  &__hint {
    font-size: 10px;
    line-height: 12px;
    font-style: italic;
    color: $grey-color;

    margin-left: 10px;

    &--big {
      width: 100%;
      display: inline-block;
      margin-top: 10px;

      font-weight: 300;
      font-size: 12px;
      line-height: 14px;
      color: $grey-color;

      text-align: center;
    }
  }
}
</style>
