<template>
  <modal
    name="web-camera-modal"
    :classes="['modal-window']"
    transition="scale"
    adaptive
    width="680px"
    height="610px"
    @opened="handleModalOpen"
    @before-close="handleModalBeforeClose"
  >
    <div class="web-camera modal-container">
      <header class="web-camera__header flex">
        <div class="icon-close custom-icon" @click="closeModal"></div>
      </header>
      <main class="web-camera__video__container">
        <video v-show="showWebCamera" ref="video" class="web-camera__video" autoplay></video>
        <img v-if="!showWebCamera" class="web-camera__captured-image" :src="capturedImage" />
        <canvas ref="canvas" class="none" width="640" height="480"></canvas>
      </main>
      <footer class="web-camera__footer flex--center--center">
        <template v-if="showWebCamera">
          <button class="rounded-button-white" @click="capture">{{ $t('info.takePhoto') }}</button>
        </template>
        <template v-else>
          <button class="rounded-button-white mr5" @click="uploadPicture">
            {{ $t('info.selectPhoto') }}
          </button>
          <button class="rounded-button-white ml5" @click="takeAnotherPicture">
            {{ $t('info.takeNewPhoto') }}
          </button>
        </template>
      </footer>
    </div>
  </modal>
</template>

<script>
import webCameraMixin from '@/modules/dashboard/components/mixins/webCameraMixin';

import { dataURItoBlob } from '@/api/helpers';

export default {
  name: 'WebCameraModal',
  mixins: [webCameraMixin],
  methods: {
    async handleModalOpen() {
      await this.initializeWebCamera();
    },
    handleModalBeforeClose() {
      this.stopVideoStream();
    },
    uploadPicture() {
      this.$emit('picture-selected', [dataURItoBlob(this.capturedImage)]);
    },
    closeModal() {
      this.$modal.hide('web-camera-modal');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../assets/css/common/icons.css';
@import '../../../../../assets/scss/common/buttons';

.web-camera {
  padding: 20px;

  &__header {
    justify-content: flex-end;
    margin-bottom: 20px;
  }

  &__video {
    width: 100%;
    height: 100%;

    max-width: 640px;
    max-height: 480px;
    border-radius: 8px;
    margin-bottom: -7px;

    background-color: white;

    &__container {
      width: 640px;
      height: 480px;
    }
  }

  &__captured-image {
    border-radius: 8px;
  }

  &__footer {
    margin-top: 20px;
  }
}
</style>
