<template>
  <timeline-section class="treatment-completed-section" :is-active="isActive">
    <timeline-section-header
      slot="header"
      slot-scope="{ isShown }"
      :timeline-date="treatment.expiredDate"
      :timeline-header="$t('dashboard.label.treatmentCompletedOnHistory')"
      :is-shown="isShown"
      :can-open-section="canOpenSection"
    />
    <div slot="content" class="treatment-completed-section__content completed-section-content">
      <span class="completed-section-content__description">
        {{ description }}
      </span>
    </div>
  </timeline-section>
</template>

<script>
import { mapGetters } from 'vuex';

import TimelineSection from '@/modules/dashboard/components/treatment-history-tab/treatment-details/treatment-timeline-sections/TimelineSection';
import TimelineSectionHeader from '@/modules/dashboard/components/treatment-history-tab/treatment-details/treatment-timeline-sections/TimelineSectionHeader';

import rootTypes from '@/store/types';
import { types } from '@/modules/dashboard/store/types';
import { TIMELINE_SECTION_DEFAULT_PROPS } from '@/modules/dashboard/api/constants';

export default {
  name: 'TreatmentCompletedTimelineSection',
  components: { TimelineSectionHeader, TimelineSection },
  props: {
    ...TIMELINE_SECTION_DEFAULT_PROPS
  },
  computed: {
    ...mapGetters({
      hasWebshopAccess: rootTypes.getters.HAS_WEBSHOP_ACCESS,
      patientFirstName: types.getters.CURRENT_PATIENT_FIRST_NAME
    }),
    description() {
      const renewPlaceKeyPostfix = this.hasWebshopAccess ? 'online' : 'atClinic';

      return this.$t('dashboard.confirmation.anEmailWasSent', [
        this.patientFirstName,
        this.$t(`dashboard.confirmation.${renewPlaceKeyPostfix}`)
      ]);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../../assets/scss/helpers/colors';

.treatment-completed-section {
  &__content {
    padding: 20px 40px 20px 0;
  }
}

.completed-section-content {
  &__description {
    font-size: 13px;
    line-height: 18px;
    font-style: italic;
    color: $grey-color;
  }
}
</style>
