<template>
  <div v-if="hasPhotos" class="photo-tab">
    <mobile-header>
      <span slot="header-title" class="photo-tab__header-title">
        {{ $t('dashboard.label.photos') }}
      </span>
    </mobile-header>
    <div class="photo-tab__controls flex--space-between--center">
      <button class="photo-tab__button rounded-button-white" @click="showAddPhotoModal">
        {{ $t('dashboard.label.addPhoto') }}
      </button>
      <button class="photo-tab__button rounded-button-white" @click="showComparePhotoModal">
        {{ $t('dashboard.button.compare') }}
      </button>
    </div>
    <div class="photo-tab__content flex">
      <div class="photo-tab__photos-preview photos-preview flex-column--align-center">
        <ul ref="photosPreviewList" class="photos-preview__list">
          <li
            v-for="previewPhoto in photos"
            :key="previewPhoto.photoId"
            class="photos-preview__list-item photo-preview-item flex-column--align-center"
            :class="{
              'photos-preview__list-item_active': activePhotoId === previewPhoto.photoId
            }"
          >
            <div class="photo-preview-item__title flex--space-between--center">
              <span class="photo-preview-item__title-text">
                {{ previewPhoto.creationDate | formatDate }}
              </span>
              <div
                v-if="withDiagnostic(previewPhoto)"
                class="photo-preview-item__diagnostic-link flex--center--center"
                :class="{
                  'diagnostic-link__photo-based': checkIsPhotoBasedDiagnostic(previewPhoto)
                }"
                @click="followDiagnosticLink(previewPhoto)"
              >
                {{ `D${previewPhoto.diagnosticNumber}` }}
              </div>
            </div>
            <div class="photo-preview-item__image" @click="showZoomedPhoto(previewPhoto)">
              <photo :key="previewPhoto.photoId" :photo="previewPhoto"></photo>
              <div
                class="photo-preview-item__dots custom-icon icon-vertical-dots"
                @click.stop="togglePhotoDetailDropdown"
              >
                <div
                  class="photo-preview-item__overlay"
                  @click.stop="togglePhotoDetailOverlay"
                ></div>
                <div
                  ref="photoOptionsDropdown"
                  class="photo-preview-item__controls photo-controls main-text"
                >
                  <span
                    v-if="withDiagnostic(previewPhoto)"
                    class="photo-controls__option"
                    @click="followDiagnosticLink(previewPhoto)"
                  >
                    {{ $t('dashboard.button.goToDiagnostic') }} {{ previewPhoto.diagnosticNumber }}
                    <div
                      class="go-to-diagnostic-button__portrait-icon custom-icon inline-block"
                      :class="[
                        checkIsPhotoBasedDiagnostic(previewPhoto)
                          ? 'icon-portrait'
                          : 'icon-clipboard'
                      ]"
                    ></div>
                    <span class="go-to-diagnostic-button__date">
                      {{ previewPhoto.creationDate | formatDate }}
                    </span>
                  </span>
                  <span
                    v-else
                    class="photo-controls__option"
                    @click="runPhotoAnalysisOnPhoto(previewPhoto)"
                  >
                    {{ $t('dashboard.button.treatmentFromPhotoAnalysis') }}
                  </span>

                  <span class="photo-controls__option" @click="setPhotoAsAvatar(previewPhoto)">
                    {{ $t('dashboard.action.setAsProfilePhoto') }}
                  </span>
                  <span
                    v-if="!withDiagnostic(previewPhoto)"
                    class="photo-controls__option"
                    @click="onPhotoDelete(previewPhoto)"
                  >
                    <span class="far fa-trash-alt delete-photo-icon"></span>
                    {{ $t('dashboard.button.deletePhoto') }}
                  </span>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="photo-tab__zoomed">
        <div class="photo-tab__zoomed-item">
          <div class="photo-tab__zoomed-photo zoomed-photo flex-column">
            <div class="zoomed-photo__img-container flex--center--center">
              <zoomed-photo :photo="activePhoto"></zoomed-photo>
            </div>
            <div class="zoomed-photo__controls flex">
              <button
                v-if="canUsePhotoAnalysis(activePhoto)"
                class="photo-tab__button rounded-button-white"
                @click="runPhotoAnalysisOnPhoto(activePhoto)"
              >
                {{ $t('dashboard.button.treatmentFromPhotoAnalysis') }}
              </button>
              <button
                v-if="withDiagnostic(activePhoto)"
                class="photo-tab__button photo-tab__go-to-diagnostic-button rounded-button-gray flex--align-center"
                @click="followDiagnosticLink(activePhoto)"
              >
                {{ $t('dashboard.button.goToDiagnostic') }} {{ activePhoto.diagnosticNumber }}
                <div
                  class="go-to-diagnostic-button__portrait-icon custom-icon inline-block"
                  :class="[
                    checkIsPhotoBasedDiagnostic(activePhoto) ? 'icon-portrait' : 'icon-clipboard'
                  ]"
                ></div>
                <span class="go-to-diagnostic-button__date">
                  {{ activePhoto.creationDate | formatDate }}
                </span>
              </button>
              <dropdown class="photo-dropdown">
                <div
                  slot="button"
                  class="custom-icon icon-vertical-dots mr5"
                  data-toggle="dropdown"
                ></div>
                <template slot="list-elements">
                  <li
                    class="photo-dropdown__option main-text"
                    @click="setPhotoAsAvatar(activePhoto)"
                  >
                    {{ $t('dashboard.action.setAsProfilePhoto') }}
                  </li>
                  <li
                    v-if="!withDiagnostic(activePhoto)"
                    class="photo-dropdown__option main-text"
                    @click="onPhotoDelete(activePhoto)"
                  >
                    <span class="far fa-trash-alt delete-photo-icon"></span>
                    {{ $t('dashboard.button.deletePhoto') }}
                  </li>
                </template>
              </dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>

    <photo-zoom-modal class="onlyXS" />

    <add-photo-modal
      :photo="currentPhoto"
      :patient-id="currentPatient.id"
      @photo-upload="updatePhotos"
    />

    <compare-photo-modal :patient-id="currentPatient.id" :photos="photos" />
    <delete-photo-modal :photo="currentPhoto" @photo-delete="onPhotoDeleteConfirm" />
  </div>
  <div v-else-if="isPhotosLoaded" class="photo-tab-empty flex-column">
    <mobile-header>
      <span slot="header-title" class="medical-data-tab__header-title">
        {{ $t('dashboard.label.photos') }}
      </span>
    </mobile-header>
    <div class="photo-tab-empty__content flex--center--center">
      <div class="photo-tab-empty__img-container flex--center--center">
        <div class="icon-photo-tab-empty"></div>
      </div>
      <div class="photo-tab-empty__text">{{ $t('dashboard.label.noPhotosYet') }}</div>
      <button class="photo-tab__button rounded-button-white" @click="showAddPhotoModal">
        {{ $t('dashboard.button.addPhoto') }}
      </button>
    </div>

    <add-photo-modal
      :photo="currentPhoto"
      :patient-id="currentPatient.id"
      @photo-upload="updatePhotos"
    />
  </div>
</template>

<script>
import PerfectScrollbar from 'perfect-scrollbar';

import { mapActions, mapGetters } from 'vuex';
import { first, get, isEmpty } from 'lodash';

import AddPhotoModal from '@/modules/dashboard/components/photo-tab/AddPhotoModal';
import ComparePhotoModal from '@/modules/dashboard/components/photo-tab/ComparePhotoModal';
import DeletePhotoModal from '@/modules/dashboard/components/photo-tab/DeletePhotoModal';
import Photo from '@/modules/dashboard/components/photo-tab/Photo';
import ZoomedPhoto from '@/modules/dashboard/components/photo-tab/ZoomedPhoto';
import MobileHeader from '@/modules/dashboard/components/common/MobileHeader';
import Dropdown from '@/modules/dashboard/components/common/Dropdown';
import PhotoZoomModal from '@/modules/dashboard/components/common/PhotoZoomModal';

import {
  buildImageUrl,
  isMobileDevice,
  updateDropdownVisibility,
  handleRoutingInfo
} from '@/modules/dashboard/utils';

import {
  fetchPatientPhotos,
  deletePatientPhoto,
  setPatientPhotoAsAvatar
} from '@/modules/dashboard/api';

import rootTypes from '@/store/types';
import { types as dashTypes } from '@/modules/dashboard/store/types';

import {
  PATIENT_TAB_NAMES,
  ANALYZING_ROUTING_INFO,
  DIAGNOSTIC_TYPES,
  DEFAULT_PERFECT_SCROLLBAR_OPTIONS
} from '@/modules/dashboard/api/constants';

const DROPDOWN_GRADIENT_ELEMENT_HEIGHT = 10;
const DROPDOWN_ABSOLUTE_TOP = '-125px';
const DROPDOWN_INITIAL_ABSOLUTE_TOP = '30px';

export default {
  name: 'PhotoTab',
  components: {
    PhotoZoomModal,
    DeletePhotoModal,
    Dropdown,
    Photo,
    ZoomedPhoto,
    AddPhotoModal,
    ComparePhotoModal,
    MobileHeader
  },
  data() {
    return {
      photos: [],
      activePhoto: null,
      currentPhoto: null,
      needPhotoUpdate: false,
      noFace: false,
      severalFaces: false,
      photosPreviewListScrollbar: null,
      isPhotosLoaded: false
    };
  },
  computed: {
    ...mapGetters({
      currentPatient: dashTypes.getters.CURRENT_PATIENT
    }),
    hasPhotos() {
      return !isEmpty(this.photos);
    },
    imageUrl() {
      return buildImageUrl(this.photo);
    },
    firstPhoto() {
      return first(this.photos);
    },
    activePhotoId() {
      return get(this.activePhoto, 'photoId');
    }
  },
  async mounted() {
    await this.updatePhotos();

    this.isPhotosLoaded = true;

    const { photosPreviewList } = this.$refs;

    if (photosPreviewList && !isMobileDevice()) {
      this.photosPreviewListScrollbar = new PerfectScrollbar(
        photosPreviewList,
        DEFAULT_PERFECT_SCROLLBAR_OPTIONS
      );
    }
  },
  methods: {
    ...mapActions({
      loadPatient: dashTypes.actions.LOAD_PATIENT,
      setLoading: rootTypes.actions.SET_LOADING,
      setActiveDiagnosticId: dashTypes.actions.SET_ACTIVE_DIAGNOSTIC_ID,
      setActivePatientTab: dashTypes.actions.SET_ACTIVE_PATIENT_TAB,
      setPhotoAnalysisData: dashTypes.actions.SET_PHOTO_ANALYSIS
    }),
    withDiagnostic(photo) {
      return photo.diagnosticId;
    },
    onPhotoDelete(photo) {
      this.currentPhoto = photo;

      this.$modal.show('delete-photo-modal');
    },
    followDiagnosticLink({ diagnosticId }) {
      this.setActiveDiagnosticId(diagnosticId);
      this.setActivePatientTab(PATIENT_TAB_NAMES.DIAGNOSTICS);

      if (isMobileDevice()) {
        this.$router.push({
          name: 'DiagnosticDetails',
          params: {
            diagnosticId
          }
        });
      }
    },
    async setPhotoAsAvatar({ photoId }) {
      this.setLoading(true);

      await setPatientPhotoAsAvatar(this.currentPatient.id, photoId);
      await this.loadPatient(this.currentPatient.id);

      this.setLoading(false);
    },
    async onPhotoDeleteConfirm() {
      if (!this.currentPhoto) {
        return;
      }

      this.$modal.hide('delete-photo-modal');

      this.setLoading(true);

      const { data: response } = await deletePatientPhoto(
        this.currentPatient.id,
        this.currentPhoto.photoId
      );

      if (this.currentPhoto.isAvatar) {
        await this.loadPatient(this.currentPatient.id);
      }

      if (response && response.error) {
        this.setLoading(false);

        return;
      }

      this.photos = this.photos.filter(({ photoId }) => photoId !== this.currentPhoto.photoId);
      this.currentPhoto = null;

      this.activePhoto = this.firstPhoto;

      this.updatePhotos();

      this.setLoading(false);
    },
    showAddPhotoModal() {
      this.$modal.show('add-photo-modal');
    },
    showComparePhotoModal() {
      this.$modal.show('compare-photo-modal');
    },
    showZoomedPhoto(photo) {
      if (isMobileDevice()) {
        this.$modal.show('photo-zoom-modal', { photoUrl: buildImageUrl(photo) });

        return;
      }

      const zoomedMobPhotoWrapper = document.body.querySelectorAll('.photo-tab__zoomed');

      this.activePhoto = photo;
      zoomedMobPhotoWrapper[0].style.display = 'flex';
    },
    async togglePhotoDetailDropdown({ currentTarget }) {
      const mobPhotoDetailOverlay = currentTarget.querySelectorAll('.photo-preview-item__overlay');

      currentTarget.classList.toggle('active');
      mobPhotoDetailOverlay[0].classList.toggle('active');

      await this.$nextTick();

      [...this.$refs.photoOptionsDropdown].forEach(dropdown => {
        updateDropdownVisibility({
          dropdown,
          isVisible: currentTarget.classList.contains('active'),
          elementHeight: DROPDOWN_GRADIENT_ELEMENT_HEIGHT,
          top: DROPDOWN_ABSOLUTE_TOP,
          initialTop: DROPDOWN_INITIAL_ABSOLUTE_TOP
        });
      });
    },
    togglePhotoDetailOverlay(e) {
      const mobPhotoDetailOverlay = e.currentTarget;
      const mobPhotoDetail = e.currentTarget.parentNode;

      mobPhotoDetail.classList.toggle('active');
      mobPhotoDetailOverlay.classList.toggle('active');
    },
    async updatePhotos() {
      this.setLoading(true);

      const { data: photos } = await fetchPatientPhotos({ patientId: this.currentPatient.id });

      this.photos = photos.filter(photo => photo && photo.convertedToBase64Photo);
      this.activePhoto = this.firstPhoto;

      this.setLoading(false);
    },
    checkIsPhotoBasedDiagnostic(photo) {
      return photo.diagnosticType === DIAGNOSTIC_TYPES.PHOTO_BASED;
    },
    canUsePhotoAnalysis({ diagnosticId }) {
      return !diagnosticId;
    },
    async runPhotoAnalysisOnPhoto({ photoId }) {
      await this.setPhotoAnalysisData({ photoId, hasPermissionToDelete: false });

      handleRoutingInfo(ANALYZING_ROUTING_INFO, this.$router, this.$modal);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/css/common/icons';
@import '../../../../assets/scss/helpers/colors';
@import '../../../../assets/scss/common/buttons';
@import '../../../../assets/scss/common/text';

.photo-tab {
  margin-bottom: 200px;

  &__controls {
    padding: 30px 0;
  }

  &__zoomed {
    position: relative;
    width: 100%;
  }

  &__zoomed-item {
    position: absolute;
    width: 100%;
  }

  &__zoomed-photo {
    width: 100%;
    background-color: $white;
    border-radius: 0 8px 8px 0;
  }

  &__go-to-diagnostic-button {
    color: $text-color;
  }
}

.go-to-diagnostic-button {
  &__portrait-icon {
    margin: 0 10px;
  }

  &__date {
    font-size: 13px;
    font-weight: 300;
  }
}

.photo-controls {
  &__option {
    display: flex;
    align-items: center;

    padding: 10px 0;
  }
}

.photo-tab-empty {
  width: 100%;
  justify-content: center;
  height: 500px;
  background: $background-darker;
  border-radius: 8px;

  &__content {
    flex-direction: column;
    width: 100%;
  }

  &__img-container {
    width: 150px;
    height: 168px;
    margin-bottom: 40px;
  }

  &__text {
    font-size: 15px;
    line-height: 17px;
    text-align: center;
    margin-bottom: 20px;
    color: $grey-color;
  }
}

.photos-preview {
  &__list {
    padding: 0;
    margin: 0;
    list-style: none;
    max-height: 830px;
  }

  &__list-item {
    padding: 17px 10px 14px;

    &_active {
      background-color: $white;
      border-radius: 8px 0 0 8px;

      .photo-preview-item__diagnostic-link,
      .photo-preview-item__title-text {
        background-color: $white;
      }
    }

    &:last-child {
      padding-bottom: 0;
    }
  }
}

.delete-photo-icon {
  color: $icon-grey-color;
  margin-right: 10px;
}

.photo-dropdown .photo-dropdown__option {
  padding-bottom: 20px;
  cursor: pointer;
  text-align: left;
}

.photo-preview-item {
  cursor: pointer;

  &__title {
    position: relative;
    width: 100%;
    margin-bottom: 18px;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 1px;
      background-color: $dim-white;
    }
  }

  &__title-text {
    padding-right: 6px;
    margin-right: auto;
    font-size: 13px;
    font-weight: 300;
    line-height: 15px;
    background-color: $background;
    color: $active-title-color;
    z-index: 1;
  }

  &__diagnostic-link {
    width: 41px;
    height: 40px;
    padding: 10px;
    border-radius: 50%;
    font-size: 15px;
    line-height: 19px;
    color: $orange-color;
    background-color: $background;
    border: 1px solid $dim-white;
    z-index: 1;
    cursor: pointer;

    &.diagnostic-link {
      &__photo-based {
        background-color: $cream-color;
      }
    }
  }

  &__image {
    position: relative;
    width: 214px;
    height: 204px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__dots {
    display: block;

    position: absolute;
    top: 0;
    right: -35px;

    cursor: pointer;

    &.active {
      .photo-preview-item__controls {
        display: flex;
      }
    }
  }

  &__controls {
    display: none;
    position: absolute;
    top: 30px;
    right: 0;
    width: 270px;
    flex-direction: column;
    background: $white;
    padding: 10px 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(148, 166, 176, 0.3);
    z-index: 999;
  }

  &__overlay {
    position: fixed;
    display: none;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: transparent;
    z-index: 99;

    &.active {
      display: block;
    }
  }
}

.zoomed-photo {
  padding: 30px 30px 20px;
  width: 100%;

  &__img-container {
    position: relative;
    max-width: 680px;
    width: 100%;
    height: 680px;
    margin-bottom: 20px;
    overflow: hidden;
  }

  &__img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 200%;
    height: 200%;
    object-fit: cover;
    cursor: grab;
  }

  &__controls {
    justify-content: space-between;
    position: relative;
    padding-bottom: 40px;
  }
}

@media (max-width: 767px) {
  .photo-tab {
    &__header-title {
      font-size: 13px;
      line-height: 16px;
      letter-spacing: 0.05em;
      color: $main-title-color;
    }

    &__controls {
      margin-top: 46px;
      padding: 10px 15px 20px;
    }

    &__zoomed {
      display: none;
    }

    &__photos-preview {
      width: 100%;
    }

    &__button {
      &_delete {
        padding-left: 0;
        padding-right: 0;
        text-align: left;
        font-size: 13px;
        line-height: 15px;
      }
    }
  }

  .photo-tab-empty {
    width: 100%;
    height: auto;
    min-height: 100vh;

    &__content {
      height: calc(100vh - 46px);
      margin-top: 46px;
    }
  }

  .zoomed-photo {
    padding: 0;

    &__controls {
      display: none;
    }
  }

  .photos-preview {
    &__list {
      width: 100%;
      padding: 0 50px;
      max-height: unset;
    }

    &__list-item {
      padding-top: 0;

      &_active {
        background-color: transparent;
        border-radius: 0;

        .photo-preview-item__title-text {
          background-color: $background;
        }
      }
    }
  }

  .photo-preview-item {
    &__image {
      width: 100%;
      min-width: 200px;
    }

    &__dots {
      display: block;
    }
  }
}
</style>
