<template>
  <li class="anomaly-list__item flex--align-center mb5">
    <div class="anomaly-item" :class="{ toggled: !toggle, 'error-highlight': hasError }">
      <slide-down :show="isActive">
        <div slot="toggle" class="flex--space-between--center pointer" @click="toggleAnomaly">
          <div class="flex--align-center">
            <div class="anomaly-item__title">{{ anomalyTitle }}</div>
            <div class="anomaly-item__icons flex">
              <div
                v-for="(icon, index) in icons"
                :key="index"
                class="anomaly-item__icon"
                :class="icon"
              ></div>
            </div>
          </div>
          <div class="anomaly-item__moreInfo">
            <span class="fas fa-chevron-down more-info-icon"></span>
          </div>
        </div>
        <div slot="slide-body" class="anomaly__details">
          <span class="anomaly__details__description">
            {{ anomaly.details }}
          </span>
          <template v-if="displayConfirmation">
            <div class="divider"></div>
            <div class="anomaly__details__confirm flex">
              <div class="checkbox">
                <input
                  :id="anomalyName"
                  v-validate="'required'"
                  :name="anomalyName"
                  type="checkbox"
                />
                <label
                  :for="anomalyName"
                  class="checkbox--with-border p0"
                  :class="{ error: hasError }"
                ></label>
              </div>
              <span class="anomaly__details__confirm__label">
                {{ confirmText }}
              </span>
            </div>
          </template>
        </div>
      </slide-down>
    </div>
  </li>
</template>

<script>
import SlideDown from '@/modules/dashboard/components/common/SlideDown';

const ANOMALY_ICONS = [
  ['pictoPhototype', 'pictoEthnic'],
  ['pictoPhototype', 'pictoThickness'],
  ['pictoFlushing', 'pictoThickness'],
  ['pictoThickness', 'pictoDryness', 'pictoOily'],
  ['pictoDryness', 'pictoPimples'],
  ['pictoDryness', 'pictoOily'],
  ['pictoWrinkles', 'pictoPimples']
];

export default {
  name: 'Anomaly',
  components: { SlideDown },
  inject: ['$validator'],
  props: {
    anomaly: {
      type: Object,
      required: true
    },
    active: {
      type: Boolean,
      required: false,
      default: false
    },
    displayConfirmation: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    return {
      toggle: false
    };
  },
  computed: {
    isValidation() {
      return !!this.anomaly.actions;
    },
    anomalyIndex() {
      return this.anomaly.index;
    },
    anomalyTitle() {
      const titleKey = `dashboard.diagnostic.${this.isValidation ? 'validation' : 'anomaly'}`;

      return `${this.$t(titleKey)} ${this.anomalyIndex}`;
    },
    anomalyName() {
      return `${this.anomalyIndex}${this.isValidation}`;
    },
    isActive() {
      return this.active || this.hasError;
    },
    hasError() {
      return this.errors.has(this.anomalyName);
    },
    icons() {
      return this.isValidation ? [] : ANOMALY_ICONS[this.anomalyIndex - 1];
    },
    confirmText() {
      return this.anomaly.actions || this.$t('dashboard.label.anomaly');
    }
  },
  methods: {
    toggleAnomaly() {
      this.toggle = !this.toggle;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/helpers/checkbox';
@import '../../../../assets/scss/helpers/colors';

.anomaly-item {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  padding: 4px 20px;
  border-radius: 10px;
  border: 1px solid $lavender-color;

  &__title {
    margin-right: 17px;
    font-size: 15px;
    font-weight: 400;
    line-height: 30px;
    color: $active-title-color;
  }

  &__moreInfo {
    cursor: pointer;

    .more-info-icon {
      color: $dim-grey-color;
      font-size: 12px;
    }
  }

  &__icon {
    width: 30px;
    height: 30px;
    background-size: contain;
  }

  &.toggled {
    .anomaly-item__moreInfo {
      transform: rotate(180deg);
    }

    .anomaly-item__description {
      display: block;
    }
  }
}

.anomaly {
  &__details {
    margin-top: 14px;
    margin-bottom: 15px;
    font-size: 13px;
    line-height: 19px;
    color: $text-color;
  }
}

@media (max-width: 767px) {
  .anomaly-item {
    background-color: $white;
    border: 1px solid $white;
    border-radius: 0;
    &__title {
      margin-right: 30px;
    }
  }
}

.divider {
  margin: 25px -20px 15px;
}

.checkbox {
  margin-top: 3px;
}

.error-highlight {
  border-color: $error-color;
}
</style>
