<template>
  <div class="chart-container">
    <div class="single-chart">
      <svg viewBox="0 0 57 57" class="circular-chart" :class="`circular-chart--${circleColor}`">
        <path
          class="circle-bg"
          d="M28.5 3.5
          a 25 25 0 0 1 0 50
          a 25 25 0 0 1 0 -50"
        />
        <path
          class="circle"
          :stroke-dasharray="strokeDasharray"
          d="M28.5 3.5
          a 25 25 0 0 1 0 50
          a 25 25 0 0 1 0 -50"
        />
        <text x="30" y="33" class="percentage">{{ percentage }}%</text>
      </svg>
    </div>
  </div>
</template>

<script>
const CIRCLE_CIRCUMFERENCE = 157;

const CIRCLE_COLORS = {
  GREY: 'grey',
  GOLD: 'gold'
};

export default {
  name: 'PercentageCircleChart',
  props: {
    percentage: {
      type: Number,
      default: 0
    }
  },
  computed: {
    isFullPercentage() {
      return this.percentage === 100;
    },
    circleColor() {
      return this.isFullPercentage ? CIRCLE_COLORS.GOLD : CIRCLE_COLORS.GREY;
    },
    strokeDasharray() {
      const currentPosition = (CIRCLE_CIRCUMFERENCE * this.percentage) / 100;

      return `${currentPosition}, ${CIRCLE_CIRCUMFERENCE}`;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/helpers/colors';

.single-chart {
  height: 57px;
}

.circular-chart {
  &--grey {
    stroke: $grey-color;
  }

  &--gold {
    stroke: $bright-orange-color;
  }
}

.circle-bg {
  fill: none;
  stroke: $gainsboro-color;
  stroke-width: 6;
}

.circle {
  fill: none;
  stroke-width: 6;
  stroke-linecap: round;
}

.percentage {
  fill: $dark-dim-grey;
  font-size: 13px;
  text-anchor: middle;
}
</style>
