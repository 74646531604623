<template>
  <modal
    name="edit-patient-info-modal"
    :classes="['modal-window']"
    transition="scale"
    :scrollable="true"
    :adaptive="true"
    :reset="true"
    :click-to-close="false"
    width="835"
    height="auto"
  >
    <div class="modal-container">
      <EditPatientInfo :patient-photo="patientPhoto" />
    </div>
  </modal>
</template>

<script>
import EditPatientInfo from '@/modules/dashboard/components/patient-card/EditPatientInfo';

export default {
  name: 'EditPatientInfoModal',
  components: { EditPatientInfo },
  props: {
    patientPhoto: {
      type: Object,
      default: null
    }
  }
};
</script>
