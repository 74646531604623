<template>
  <div>
    <tabs :tabs="tabs" :active-tab="activePatientTab" is-labels-are-keys @select-tab="selectTab">
      <div v-if="!isMobileDevice" slot="tab-content" class="patient-tab__content otherXS">
        <component :is="activePatientTab"></component>
      </div>
    </tabs>
    <create-treatment-modal :diagnostics="diagnostics" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { types as dashTypes } from '@/modules/dashboard/store/types';

import Tabs from '@/modules/dashboard/components/common/Tabs';
import DiagnosticTab from '@/modules/dashboard/components/diagnostic-tab/DiagnosticTab';
import TreatmentHistoryTab from '@/modules/dashboard/components/treatment-history-tab/TreatmentHistoryTab';
import PhotoTab from '@/modules/dashboard/components/photo-tab/PhotoTab';
import CommentsTab from '@/modules/dashboard/components/patient-card/comments/CommentsTab';
import CreateTreatmentTab from '@/modules/dashboard/components/create-treatment-tab/CreateTreatmentTab';
import MedicalDataTab from '@/modules/dashboard/components/patient-card/MedicalDataTab';
import CreateTreatmentModal from '@/modules/dashboard/components/create-treatment-tab/CreateTreatmentModal';

import TabInfo from '@/modules/dashboard/components/common/TabInfo';

import { isMobileDevice } from '@/modules/dashboard/utils';

import { PATIENT_TAB_NAMES, PATIENT_PAGE_NAMES } from '@/modules/dashboard/api/constants';

const TABS_BINDING = [
  new TabInfo(
    PATIENT_TAB_NAMES.CREATE_TREATMENT,
    'dashboard.action.createTreatment',
    'CreateTreatment'
  ),
  new TabInfo(
    PATIENT_TAB_NAMES.TREATMENT_HISTORY,
    'dashboard.label.treatmentHistory',
    'TreatmentHistory'
  ),
  new TabInfo(PATIENT_TAB_NAMES.DIAGNOSTICS, 'dashboard.label.diagnosticHistory', 'Diagnostics'),
  new TabInfo(PATIENT_TAB_NAMES.MEDICAL_DATA, 'dashboard.label.medicalData', 'MedicalData'),
  new TabInfo(PATIENT_TAB_NAMES.PHOTOS, 'dashboard.label.photosHistory', 'PhotosTab'),
  new TabInfo(PATIENT_TAB_NAMES.COMMENTS, 'dashboard.medicalData.comments', 'Comments')
];

export default {
  name: 'PatientTabs',
  components: {
    CreateTreatmentTab,
    Tabs,
    DiagnosticTab,
    TreatmentHistoryTab,
    CommentsTab,
    MedicalDataTab,
    PhotoTab,
    CreateTreatmentModal
  },
  data() {
    return {
      tabs: TABS_BINDING,
      isMobileDevice: isMobileDevice()
    };
  },
  computed: {
    ...mapGetters({
      activePatientTab: dashTypes.getters.ACTIVE_PATIENT_TAB,
      diagnostics: dashTypes.getters.CURRENT_PATIENT_DIAGNOSTICS
    })
  },
  created() {
    if (isMobileDevice()) {
      this.resetTreatmentRecommendation();
    }

    this.resetCreateTreatment();
  },
  async beforeRouteLeave(to, from, next) {
    if (isMobileDevice() && to === PATIENT_PAGE_NAMES.CREATE_TREATMENT) {
      next();

      return;
    }

    this.resetCreateTreatment();
    this.resetTreatmentRecommendation();

    next();
  },
  destroyed() {
    this.setActivePatientTab(PATIENT_TAB_NAMES.CREATE_TREATMENT);
  },
  methods: {
    ...mapActions({
      setActivePatientTab: dashTypes.actions.SET_ACTIVE_PATIENT_TAB,
      resetCreateTreatmentType: dashTypes.actions.RESET_CREATE_TREATMENT_TYPE,
      resetTreatmentRecommendation: dashTypes.actions.RESET_TREATMENT_RECOMMENDATION,
      resetSummaryTreatment: dashTypes.actions.RESET_SUMMARY_TREATMENT,
      resetCreateTreatmentErrors: dashTypes.actions.RESET_CREATE_TREATMENT_ERRORS,
      resetRecommendationDiagnostic: dashTypes.actions.RESET_RECOMMENDATION_DIAGNOSTIC
    }),
    async selectTab(selectedTabName) {
      if (isMobileDevice()) {
        if (selectedTabName === PATIENT_TAB_NAMES.CREATE_TREATMENT) {
          await this.getCreateTreatmentOption();

          const { routePath: tabPath } = this.tabs.find(({ name }) => name === selectedTabName);
          this.$router.push({ name: tabPath, query: { selectDefaultOption: false } });

          return;
        }

        const { routePath } = this.tabs.find(({ name }) => name === selectedTabName);
        this.$router.push({ name: routePath });
      }

      this.setActivePatientTab(selectedTabName);
    },
    async getCreateTreatmentOption() {
      return new Promise(resolve => {
        this.$modal.show('create-treatment-modal', {
          answerResolver: resolve
        });
      });
    },
    resetCreateTreatment() {
      this.resetSummaryTreatment();
      this.resetCreateTreatmentType();
      this.resetCreateTreatmentErrors();
      this.resetRecommendationDiagnostic();
    }
  }
};
</script>

<style lang="scss" scoped>
.patient-tab {
  &__content {
    min-height: 800px;
  }
}

@media (max-width: 767px) {
  .patient-tab {
    &__content {
      min-height: unset;
      padding-bottom: 0;
    }
  }
}
</style>
