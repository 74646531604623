<template>
  <modal
    name="create-treatment-modal"
    :width="300"
    height="auto"
    transition="scale"
    :classes="['modal-window', 'no-mobile-padding-modal', 'v--modal']"
    @before-open="beforeOpen"
  >
    <div class="modal-wrapper">
      <create-treatment-options
        should-prevent-update-treatment-type
        :should-show-diagnostic-options="false"
        @option-selected="onOptionSelect"
      />
    </div>
  </modal>
</template>

<script>
import CreateTreatmentOptions from '@/modules/dashboard/components/create-treatment-tab/CreateTreatmentOptions';

export default {
  name: 'CreateTreatmentModal',
  components: { CreateTreatmentOptions },
  data() {
    return {
      answerResolver: null
    };
  },
  methods: {
    beforeOpen({ params: { answerResolver } }) {
      this.answerResolver = answerResolver;
    },
    onOptionSelect() {
      this.closeModal();
    },
    closeModal() {
      this.answerResolver();

      this.$modal.hide('create-tratment-modal');
    }
  }
};
</script>

<style lang="scss" scoped>
.modal-wrapper {
  border-radius: 8px;
}
</style>
