<template>
  <div class="disfynction-slider">
    <vue-slider
      :value="value"
      :min="min"
      :max="max"
      tooltip="none"
      :interval="1"
      adsorb
      contained
      marks
      hide-label
      :rail-style="backgroundColorTransparentStyle"
      :process-style="backgroundColorTransparentStyle"
      @change="onChange"
    >
      <template slot="dot" slot-scope="{}">
        <div class="custom-outer-dot flex--center--center" :style="borderColorStyle">
          <div class="custom-inner-dot" :style="backgroundColorStyle"></div>
        </div>
      </template>
      <template slot="step" slot-scope="{ active }">
        <div class="custom-step" :style="[colorStyle, active ? backgroundColorStyle : '']"></div>
      </template>
    </vue-slider>
    <span class="slider-value" :style="colorStyle">{{ value }}/{{ max }}</span>
  </div>
</template>

<script>
import VueSlider from 'vue-slider-component';

import 'vue-slider-component/theme/default.css';

const BACKGROUND_COLOR_TRANSPARENT_STYLE = {
  backgroundColor: 'transparent'
};

export default {
  name: 'DiagnosticDysfunctionSlider',
  components: { VueSlider },
  props: {
    value: {
      type: Number,
      default: 0
    },
    color: {
      type: String,
      required: true
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 10
    }
  },
  data() {
    return {
      backgroundColorTransparentStyle: BACKGROUND_COLOR_TRANSPARENT_STYLE
    };
  },
  computed: {
    borderColorStyle() {
      return {
        'border-color': this.color
      };
    },
    backgroundColorStyle() {
      return {
        'background-color': this.color
      };
    },
    colorStyle() {
      return {
        color: this.color
      };
    }
  },
  methods: {
    onChange(value) {
      this.$emit('change', value);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/helpers/colors';

.disfynction-slider {
  height: 18px;
  width: 195px;
  min-width: 195px;

  display: inline-block;

  position: relative;
}

.custom-outer-dot {
  height: 18px;
  width: 18px;

  border-radius: 50%;
  border: 1px solid;

  background-color: $white;

  transform: translate(-2px, -2px);
}

.custom-inner-dot {
  height: 12px;
  width: 12px;

  border-radius: 50%;
}

.custom-step {
  height: 12px;
  width: 12px;

  border-radius: 50%;
  border: 1px solid;

  transform: translate(-4px, -4px);

  &--active {
    border: 0;
  }
}

.slider-value {
  font-size: 13px;
  line-height: 15px;

  position: absolute;
  right: -59px;
  top: 1px;
}

@media (max-width: 767px) {
  .disfynction-slider {
    height: 15px;
    width: 140px;
    min-width: 140px;
  }

  .custom-outer-dot {
    height: 14px;
    width: 14px;

    transform: none;
  }

  .custom-inner-dot {
    height: 8px;
    width: 8px;
  }

  .custom-step {
    height: 8px;
    width: 8px;

    transform: translate(-2px, -2px);
  }

  .slider-value {
    right: -45px;

    font-weight: bold;
  }
}
</style>
