<template>
  <div class="patient-edit">
    <header class="patient-edit__header flex--space-between--center">
      <div
        class="patient-edit__close-icon icon-close custom-icon onlyXS"
        @click="returnToPatientCard"
      ></div>
      <span class="patient-edit__title">
        {{ $t('dashboard.label.editPatientInfo') }}
      </span>
      <div class="icon-close custom-icon otherXS" @click="checkBeforeModalClose"></div>
    </header>
    <div class="divider otherXS"></div>
    <main class="patient-edit__edit-section-container">
      <div v-if="hasPhoto" class="patient-edit__photo__container">
        <div class="photo__container" :style="patientPhotoStyles"></div>
      </div>
      <div v-else class="patient-edit__photo__container">
        <p class="first-letter patient-avatar-letter photo__container">{{ firstLetter }}</p>
      </div>
      <div ref="edit-section" class="patient-edit__edit-info-section relative">
        <div class="patient-edit__edit-horizontal-section">
          <div class="patient-edit__field-container">
            <div class="patient-edit__field-title">
              {{ $t('dashboard.label.firstName') }}
              <span class="compulsory">*</span>
            </div>
            <input
              v-model="updatedPatient.firstName"
              v-validate.disable="'required'"
              name="firstName"
              class="patient-edit__field rounded-input input-focus-within"
              :class="{ 'error-highlight': isFirstNameHasErrors }"
              @input="clearFirstNameErrors"
            />
            <field-error
              v-if="isFirstNameHasErrors"
              :error-message="$t('dashboard.warning.requiredField')"
            />
          </div>
          <div class="patient-edit__field-container">
            <div class="patient-edit__field-title">
              {{ $t('dashboard.label.lastName') }}
            </div>
            <input
              v-model="updatedPatient.lastName"
              name="lastName"
              class="patient-edit__field rounded-input input-focus-within"
            />
          </div>
        </div>
        <div
          class="patient-edit__edit-horizontal-section patient-edit__edit-horizontal-section__row-mobile"
        >
          <div class="patient-edit__field-container patient-edit__birthday-container">
            <div class="patient-edit__field-title">
              {{ $t('dashboard.label.dateOfBirth') }}
            </div>
            <div class="flex space-between">
              <input
                v-model="dayOfBirth"
                v-allow-only-reg-exp="/^[0-9]$/"
                placeholder="DD"
                class="patient-edit__date-field patient-edit__field--quarter-width rounded-input input-focus-within"
                :class="{
                  'error-highlight': isDayOfBirthInvalid || isDateOfBirthFieldError
                }"
                @input="clearDateOfBirthErrors"
              />
              <input
                v-model="monthOfBirth"
                v-allow-only-reg-exp="/^[0-9]$/"
                placeholder="MM"
                class="patient-edit__date-field patient-edit__field--quarter-width rounded-input input-focus-within"
                :class="{
                  'error-highlight': isMonthOfBirthInvalid || isDateOfBirthFieldError
                }"
                @input="clearDateOfBirthErrors"
              />
              <input
                v-model="yearOfBirth"
                v-allow-only-reg-exp="/^[0-9]$/"
                placeholder="YYYY"
                class="patient-edit__date-field patient-edit__field--third-width rounded-input input-focus-within"
                :class="{
                  'error-highlight': isYearOfBirthInvalid || isDateOfBirthFieldError
                }"
                @input="clearDateOfBirthErrors"
              />
            </div>
            <field-error
              v-if="isDateOfBirthHasError"
              :error-message="$t('dashboard.warning.ageFilled')"
            />
            <field-error
              v-if="isPatientAgeError"
              :error-message="$t('dashboard.warning.ageBetween')"
            />
          </div>
          <div class="patient-edit__field-container patient-edit__gender-container">
            <div class="patient-edit__field-title">
              {{ $t('dashboard.patientCard.gender') }}
            </div>
            <dropdown-select
              class="patient-edit__field--half-width patient-edit__select"
              label="label"
              value="value"
              :current-value="genderValue"
              :select-values="genderBinding"
              @select="updateGender"
            />
          </div>
        </div>
        <div class="patient-edit__edit-horizontal-section">
          <div class="patient-edit__field-container">
            <div class="patient-edit__field-title">
              {{ $t('dashboard.label.emailAddress') }}
              <span class="compulsory">*</span>
            </div>
            <input
              v-model="updatedPatient.email"
              v-validate.disable="{ required: true, regex: validEmailRegExp }"
              name="email"
              class="patient-edit__field rounded-input input-focus-within"
              :class="{ 'error-highlight': isEmailHasErrors || isEmailHasRegexpError }"
              @input="clearEmailErrors"
            />
            <field-error
              v-if="isEmailHasErrors"
              :error-message="$t('dashboard.warning.requiredField')"
            />
            <field-error
              v-if="isEmailHasRegexpError"
              :error-message="$t('dashboard.warning.invalidEmail')"
            />
          </div>
          <div
            class="patient-edit__field-container patient-accept-checkbox__container flex--align-center mt25"
          >
            <div class="patient-edit__checkbox flex--align-center checkbox">
              <input
                id="marketingEmailAccepted"
                name="marketingEmailAccepted"
                class="patient-accept-checkbox__input"
                type="checkbox"
                :checked="currentPatient.marketingEmailAccepted"
                @change="updateMarketingEmailAccepted"
              />
              <label
                for="marketingEmailAccepted"
                class="checkbox--with-border patient-accept-checkbox__label p0"
              ></label>
              <span class="patient-edit__content-text">
                {{ $t('dashboard.label.patientAccepted') }}
              </span>
            </div>
          </div>
        </div>
        <div class="patient-edit__edit-horizontal-section">
          <div class="patient-edit__field-container">
            <div class="patient-edit__field-title">
              {{ $t('dashboard.formulation.phoneNumber') }}
            </div>
            <phone-edit-field
              class="patient-edit__field"
              :phone="updatedPatient.phone"
              :iso-code="updatedPatient.phoneIsoCode"
              @update-phone-number="updatePhoneNumber"
              @update-iso-code="updatePhoneIsoCode"
            />
          </div>
          <div
            class="patient-edit__field-container patient-accept-checkbox__container flex--align-center mt25"
          >
            <div class="patient-edit__checkbox flex--align-center checkbox">
              <input
                id="smsAccepted"
                name="treatmentPaidCheckbox"
                class="patient-accept-checkbox__input"
                type="checkbox"
                :checked="currentPatient.smsAccepted"
                @change="updateSmsAccept"
              />
              <label
                for="smsAccepted"
                class="checkbox--with-border patient-accept-checkbox__label p0"
              ></label>
              <span class="patient-edit__content-text">
                {{ $t('dashboard.label.patientAcceptedSms') }}
              </span>
            </div>
          </div>
        </div>
        <div class="patient-edit__edit-horizontal-section">
          <div class="patient-edit__field-container">
            <div class="patient-edit__field-title flex--align-center">
              {{ $t('dashboard.label.country') }}
              <div
                v-tooltip="{
                  content: $t('dashboard.patientCard.nearestLocation'),
                  placement: 'right-start'
                }"
                class="icon-info custom-icon inline-block"
              />
            </div>
            <dropdown-select
              class="patient-edit__field patient-edit__select"
              label="label"
              value="value"
              :current-value="countryValue"
              :select-values="sortedCountries"
              @select="updateCountry"
            />
          </div>
          <div class="patient-edit__field-container">
            <div class="patient-edit__field-title">
              {{ $t('dashboard.label.state') }}
            </div>
            <dropdown-select
              class="patient-edit__field patient-edit__select"
              label="label"
              :current-value="stateValue"
              :select-values="countryStates"
              @select="updateState"
            />
          </div>
        </div>
        <div class="patient-edit__edit-horizontal-section">
          <div class="patient-edit__field-container">
            <div class="patient-edit__field-title">
              {{ $t('dashboard.patientCard.city') }}
            </div>
            <input
              v-model="patientAddress.city"
              name="city"
              class="patient-edit__field rounded-input input-focus-within"
            />
          </div>
          <div class="patient-edit__field-container">
            <div class="patient-edit__field-title">
              {{ $t('dashboard.label.postalCode') }}
            </div>
            <input
              v-model="patientAddress.zipCode"
              name="postalCode"
              class="patient-edit__field rounded-input input-focus-within"
            />
          </div>
        </div>
        <div class="patient-edit__field-container--full-width">
          <div class="patient-edit__field-title">
            {{ $t('dashboard.label.address') }}
          </div>
          <input
            v-model="patientAddress.address"
            name="address"
            class="patient-edit__field rounded-input input-focus-within"
          />
        </div>
        <div class="patient-edit__field-container patient-edit__language-dropdown mb15">
          <language-dropdown-input
            display-tooltip
            :tooltip-text="$t('dashboard.hint.patientLanguage')"
            :language="updatedPatient.locale"
            @language-select="updateLocale"
          >
            <span slot="label" class="patient-edit__field-title language-dropdown__label">
              {{ $t('dashboard.label.patientLanguage') }}
            </span>
          </language-dropdown-input>
        </div>
        <div v-if="isDoctorHasClinics" class="patient-edit__edit-horizontal-section">
          <div class="patient-edit__field-container--full-width">
            <div class="patient-edit__field-title">
              {{ $t('dashboard.label.clinicAssosiation') }}
            </div>
            <dropdown-select
              class="patient-edit__field patient-edit__select"
              :current-value="clinicDto"
              :select-values="doctorClinics"
              :placeholder="$t('dashboard.hint.notAssociatedWithClinic')"
              @select="updateClinic"
            >
              <template slot="picked-value" slot-scope="clinic">
                <div class="flex--align-center">
                  <div class="clinic-color" :style="{ backgroundColor: clinic.clinicColor }"></div>
                  <span>{{ clinic.clinicName }}</span>
                </div>
              </template>
              <template slot="list-element" slot-scope="clinic">
                <div class="flex--align-center">
                  <div class="clinic-color" :style="{ backgroundColor: clinic.clinicColor }"></div>
                  <span>{{ clinic.clinicName }}</span>
                </div>
              </template>
            </dropdown-select>
          </div>
        </div>
      </div>
    </main>
    <div class="divider footer-divider otherXS"></div>
    <footer class="patient-edit__footer" :class="{ 'patient-edit__no-delete': !isAllowedToDelete }">
      <div
        v-if="isAllowedToDelete"
        class="patient-edit__delete-button-container flex--align-center pointer"
        @click="showDeletePatientModal"
      >
        <span class="far fa-trash-alt" />
        <a class="patient-edit__footer__button cancel-button otherXS flex--align-center">
          {{ $t('dashboard.action.deletePatient') }}
        </a>
      </div>
      <div class="flex--align-center">
        <a class="patient-edit__footer__button cancel-button otherXS" @click="closeModal">
          {{ $t('dashboard.action.cancel') }}
        </a>
        <a
          class="patient-edit__footer__button cancel-button onlyXS"
          @click="cancelChangesAndReturnToPatientCard"
        >
          {{ $t('dashboard.action.cancel') }}
        </a>
        <button
          class="patient-edit__footer__button save-changes-button rounded-button-white"
          @click="saveChanges"
        >
          {{ $t('dashboard.button.saveChanges') }}
        </button>
      </div>
    </footer>

    <delete-patient-modal @patient-delete="onPatientDelete" />
    <email-associated-error-modal @close="returnPreviousEmail" />
    <unsaved-changes-modal />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import PerfectScrollbar from 'perfect-scrollbar';
import { get, pick, upperFirst, sortBy } from 'lodash';
import differenceInYears from 'date-fns/difference_in_years';

import pageUnsavedChangesMixin from '@/modules/dashboard/components/mixins/pageUnsavedChangesMixin';

import DropdownSelect from '@/modules/dashboard/components/common/DropdownSelect';
import FieldError from '@/modules/dashboard/components/common/FieldError';
import PhoneEditField from '@/modules/dashboard/components/profile/PhoneEditField';
import DeletePatientModal from '@/modules/dashboard/components/patients/DeletePatientModal';
import EmailAssociatedErrorModal from '@/modules/dashboard/components/common/EmailAssociatedErrorModal';
import UnsavedChangesModal from '@/modules/dashboard/components/message-templates/UnsavedChangesModal';
import SelectOptionObject from '@/components/common/SelectOptionObject';
import LanguageDropdownInput from '@/modules/dashboard/components/profile/LanguageDropdownInput';

import {
  buildImageUrl,
  getDateObjectFromString,
  hasPatientPhoto,
  getPatientFirstLetter,
  isMobileDevice
} from '@/modules/dashboard/utils';
import { hasResponseErrors } from '@/utils/storeActionsUtils';
import { displayNameMixin } from '@/mixins/displayNameMixin';

import { formatDate } from '@/modules/dashboard/utils/date-utils';

import rootTypes from '@/store/types';
import { types } from '@/modules/dashboard/store/types';
import { GENDER_VALUES } from '@/modules/questionnaire/api/constants';
import { VALID_EMAIL_REG_EXP } from '@/constants';

import {
  DATE_FORMAT,
  UNSAVED_CHANGES_INPUTS,
  DEFAULT_PERFECT_SCROLLBAR_OPTIONS,
  DISABLE_DELETE_OPTION_STATUSES
} from '@/modules/dashboard/api/constants';
import { COUNTRY_CODES, COUNTRY_STATES } from '@/api/places/COUNTRIES';

import * as Places from '@/api/places';
import { fetchPatientPhotoByPhotoId } from '@/modules/dashboard/api';

const MIN_AGE = 12;
const MAX_AGE = 120;

const DEFAULT_ADDRESS_FIELD = {
  address: null,
  city: null,
  countryCode: null,
  state: null,
  zipCode: null
};

export default {
  name: 'EditPatientInfo',
  components: {
    UnsavedChangesModal,
    DropdownSelect,
    FieldError,
    PhoneEditField,
    DeletePatientModal,
    EmailAssociatedErrorModal,
    LanguageDropdownInput
  },
  mixins: [pageUnsavedChangesMixin, displayNameMixin],
  props: {
    patientPhoto: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      updatedPatient: {
        firstName: '',
        lastName: '',
        gender: '',
        email: '',
        smsAccepted: '',
        marketingEmailAccepted: false,
        phone: '',
        phoneIsoCode: '',
        countryOfResidence: '',
        cityOfResidence: '',
        locale: ''
      },
      savedPatientData: null,
      clinicDto: null,
      patientAddress: null,
      dayOfBirth: '',
      monthOfBirth: '',
      yearOfBirth: '',
      validEmailRegExp: VALID_EMAIL_REG_EXP,
      isDateOfBirthFieldError: false,
      isDayOfBirthInvalid: false,
      isMonthOfBirthInvalid: false,
      isYearOfBirthInvalid: false,
      isDateOfBirthHasError: false,
      isPatientAgeError: false,
      scrollbar: null,
      displayedPatientPhoto: null
    };
  },
  computed: {
    ...mapGetters({
      doctor: rootTypes.getters.GET_USER,
      doctorClinics: rootTypes.getters.GET_USER_CLINICS,
      doctorIsoCode: rootTypes.getters.USER_ISO_CODE,
      currentPatient: types.getters.CURRENT_PATIENT,
      currentPatientId: types.getters.CURRENT_PATIENT_ID,
      currentPatientPhotoId: types.getters.CURRENT_PATIENT_PHOTO_ID,
      hasPatientInfoEmailError: types.getters.HAS_PATIENT_INFO_EMAIL_ERROR
    }),
    isMobile() {
      return get(this.$route, 'meta.mobile') || false;
    },
    firstLetter() {
      return getPatientFirstLetter(this.currentPatient);
    },
    hasPhoto() {
      return hasPatientPhoto(this.displayedPatientPhoto);
    },
    patientPhotoStyles() {
      return {
        'background-image': `url(${buildImageUrl(this.displayedPatientPhoto)})`
      };
    },
    genderValue() {
      const { gender } = this.updatedPatient;

      if (!gender) {
        return null;
      }

      const genderLabel = this.$t(`dashboard.gender.${gender}`);

      return this.getSelectOption(upperFirst(genderLabel), gender);
    },
    genderBinding() {
      return GENDER_VALUES.map(gender => {
        const genderLabel = this.$t(`dashboard.gender.${gender}`);

        return this.getSelectOption(upperFirst(genderLabel), gender);
      });
    },
    countryValue() {
      const { countryCode } = this.patientAddress;

      const countryLabel = this.getCountryDisplayName(countryCode);

      return this.getSelectOption(countryLabel, countryCode);
    },
    stateValue() {
      const { state } = this.patientAddress;

      if (!state) {
        return;
      }

      return this.countryStates.find(({ value }) => state === value);
    },
    sortedCountries() {
      const countries = COUNTRY_CODES.map(code => ({
        label: this.getCountryDisplayName(code),
        value: code
      }));

      return sortBy(countries, country => country.label);
    },
    cityValue() {
      const { countryOfResidence, cityOfResidence } = this.updatedPatient;

      const cityLabel =
        countryOfResidence && cityOfResidence
          ? `city.${countryOfResidence}.${cityOfResidence}`
          : '';

      return this.getSelectOption(cityLabel, cityOfResidence);
    },
    sortedCities() {
      const { countryOfResidence } = this.updatedPatient;
      const cities = countryOfResidence ? Places.getSortedCities(countryOfResidence, true) : [];

      return cities.map(city => ({ ...city, label: this.$t(city.label) }));
    },
    countryStates() {
      const { countryCode } = this.patientAddress;

      if (!countryCode) {
        return [];
      }

      return COUNTRY_STATES[countryCode.toUpperCase()] || [];
    },
    isDoctorHasClinics() {
      return this.doctorClinics.length > 1;
    },
    isFirstNameHasErrors() {
      return this.errors.has('firstName');
    },
    isEmailHasErrors() {
      return this.errors.firstByRule('email', 'required');
    },
    isEmailHasRegexpError() {
      return this.errors.firstByRule('email', 'regex');
    },
    isDayHasInvalidLength() {
      return this.dayOfBirth.length > 2;
    },
    isMonthHasInvalidLength() {
      return this.monthOfBirth.length > 2;
    },
    isYearHasInvalidLength() {
      const { length } = this.yearOfBirth;

      return length !== 4 && length !== 0;
    },
    isDateOfBirthInvalid() {
      if (!this.yearOfBirth && !this.monthOfBirth && !this.dayOfBirth) {
        return false;
      }

      const filledDate = new Date(this.yearOfBirth, this.monthOfBirth - 1, this.dayOfBirth);
      const difference = differenceInYears(new Date(), filledDate);

      return difference < MIN_AGE || difference > MAX_AGE;
    },
    isDayNumberInvalid() {
      return (this.dayOfBirth < 1 || this.dayOfBirth > 31) && this.dayOfBirth !== '';
    },
    isMonthNumberInvalid() {
      return (this.monthOfBirth < 1 || this.monthOfBirth > 12) && this.monthOfBirth !== '';
    },
    isDateOfBirthInputsInvalid() {
      return this.isDayOfBirthInvalid || this.isMonthOfBirthInvalid || this.isYearOfBirthInvalid;
    },
    isAllowedToDelete() {
      return !DISABLE_DELETE_OPTION_STATUSES.includes(this.currentPatient.status);
    }
  },
  created() {
    this.updatedPatient = pick(this.currentPatient, [
      'id',
      'firstName',
      'lastName',
      'gender',
      'email',
      'phone',
      'phoneIsoCode',
      'smsAccepted',
      'marketingEmailAccepted',
      'locale'
    ]);

    if (this.currentPatient.dateOfBirth) {
      const { day, month, year } = getDateObjectFromString(this.currentPatient.dateOfBirth);

      this.yearOfBirth = year;
      this.monthOfBirth = month;
      this.dayOfBirth = day;
    }

    if (!this.updatedPatient.phoneIsoCode) {
      this.updatedPatient.phoneIsoCode = this.doctorIsoCode;
    }

    this.clinicDto = this.currentPatient.clinicDto;
    this.patientAddress = this.currentPatient.patientAddress
      ? { ...this.currentPatient.patientAddress }
      : { ...DEFAULT_ADDRESS_FIELD };

    this.savePatientState();
  },
  mounted() {
    if (!isMobileDevice()) {
      this.scrollbar = new PerfectScrollbar(
        this.$refs['edit-section'],
        DEFAULT_PERFECT_SCROLLBAR_OPTIONS
      );
    }

    if (!this.patientPhoto) {
      this.loadPatientPhoto();

      return;
    }

    this.displayedPatientPhoto = { ...this.patientPhoto };
  },
  async beforeRouteLeave(to, from, next) {
    const userAnswer = await this.getCanContinue();

    if (!userAnswer) {
      next(false);

      return;
    }

    if (userAnswer !== UNSAVED_CHANGES_INPUTS.YES) {
      next();

      return;
    }

    const isValidated = await this.$validator.validateAll();

    if (isValidated && !this.isDateOfBirthFieldError && !this.isDateOfBirthInputsInvalid) {
      next();
    }
  },
  methods: {
    ...mapActions({
      setLoading: rootTypes.actions.SET_LOADING,
      updatePatientInfo: types.actions.UPDATE_PATIENT_INFO,
      resetOpenedPatient: types.actions.RESET_OPENED_PATIENT
    }),
    savePatientState() {
      this.savedPatientData = this.getUpdatedPatientState();
    },
    getUpdatedPatientState() {
      return {
        ...this.updatedPatient,
        clinicDto: { ...this.clinicDto },
        patientAddress: { ...this.patientAddress },
        yearOfBirth: this.yearOfBirth,
        monthOfBirth: this.monthOfBirth,
        dayOfBirth: this.dayOfBirth
      };
    },
    updateSmsAccept() {
      this.updatedPatient.smsAccepted = !this.updatedPatient.smsAccepted;
    },
    updateMarketingEmailAccepted({ target: { checked } }) {
      this.updatedPatient.marketingEmailAccepted = checked;
    },
    getSelectOption(label, value) {
      return new SelectOptionObject(label, value);
    },
    updateGender({ value }) {
      this.updatedPatient.gender = value;
    },
    updateCountry({ value }) {
      this.patientAddress.countryCode = value.toUpperCase();
      this.patientAddress.city = null;
    },
    updateCity({ value }) {
      this.patientAddress.city = value;
    },
    updateState({ value }) {
      this.patientAddress.state = value;
    },
    updatePostalCode({ value }) {
      this.patientAddress.zipCode = value;
    },
    updateAddress({ value }) {
      this.patientAddress.address = value;
    },
    updatePhoneIsoCode(isoCode) {
      this.updatedPatient.phoneIsoCode = isoCode;
    },
    updatePhoneNumber(phoneNumber) {
      this.updatedPatient.phone = phoneNumber;
    },
    updateClinic(clinic) {
      this.clinicDto = clinic;
    },
    updateLocale(locale) {
      this.updatedPatient.locale = locale;
    },
    showDeletePatientModal() {
      if (!this.isAllowedToDelete) {
        return;
      }

      this.$modal.show('delete-patient-modal', { patientId: this.currentPatient.id });
    },
    onPatientDelete() {
      this.resetOpenedPatient();
      this.savePatientState();
      this.closeModal();
      this.returnToDashboard();
    },
    async getCanContinue() {
      const updatedPatientState = this.getUpdatedPatientState();

      return this.onPageClose(updatedPatientState, this.savedPatientData);
    },
    async checkBeforeModalClose() {
      const userAnswer = await this.getCanContinue();

      if (!userAnswer) {
        return;
      }

      if (userAnswer !== UNSAVED_CHANGES_INPUTS.YES) {
        this.closeModal();

        return;
      }

      const isValidated = await this.$validator.validateAll();

      if (isValidated && !this.isDateOfBirthFieldError && !this.isDateOfBirthInputsInvalid) {
        this.closeModal();
      }
    },
    closeModal() {
      this.$modal.hide('edit-patient-info-modal');
    },
    cancelChangesAndReturnToPatientCard() {
      this.savePatientState();

      this.returnToPatientCard();
    },
    returnToPatientCard() {
      this.$router.push({ name: 'PatientCard' });
    },
    returnToDashboard() {
      this.$router.push({ name: 'Dashboard' });
    },
    returnPreviousEmail() {
      this.updatedPatient.email = this.currentPatient.email;
    },
    getDateOfBirth() {
      if (this.yearOfBirth === '' || this.monthOfBirth === '' || this.dayOfBirth === '') {
        return null;
      }

      return formatDate(
        new Date(this.yearOfBirth, this.monthOfBirth - 1, this.dayOfBirth),
        DATE_FORMAT
      );
    },
    async saveChanges() {
      const isValidated = await this.$validator.validateAll();

      this.validateDateOfBirth();

      if (!isValidated || this.isDateOfBirthFieldError || this.isDateOfBirthInputsInvalid) {
        return;
      }

      const hasPatientAddressFieldsFilled = Object.values(this.patientAddress).some(value => value);

      const patientAddress = hasPatientAddressFieldsFilled ? this.patientAddress : null;

      const patientData = {
        ...this.updatedPatient,
        patientAddress,
        clinicDto: this.clinicDto,
        dateOfBirth: this.getDateOfBirth()
      };

      this.setLoading(true);

      const response = await this.updatePatientInfo({ patientId: patientData.id, patientData });

      this.setLoading(false);

      if (this.hasPatientInfoEmailError) {
        this.$modal.show('email-associated-error-modal');

        return;
      }

      if (hasResponseErrors(response)) {
        return;
      }

      this.savePatientState();

      if (this.isMobile) {
        this.$router.push({ name: 'PatientCard' });

        return;
      }

      this.closeModal();
    },
    clearFirstNameErrors() {
      if (this.isFirstNameHasErrors) {
        this.errors.remove('firstName');
      }
    },
    clearEmailErrors() {
      if (this.isEmailHasErrors || this.isEmailHasRegexpError) {
        this.errors.remove('email');
      }
    },
    checkIsDateOfBirthPartiallyFilled() {
      const isDateOfBirthFilled = this.dayOfBirth && this.monthOfBirth && this.yearOfBirth;
      const isDateOfBirthEmpty = !this.dayOfBirth && !this.monthOfBirth && !this.yearOfBirth;

      return !isDateOfBirthFilled && !isDateOfBirthEmpty;
    },
    validateDateOfBirth() {
      this.isDateOfBirthHasError = this.checkIsDateOfBirthPartiallyFilled();

      this.isDayOfBirthInvalid = this.isDayHasInvalidLength || this.isDayNumberInvalid;
      this.isMonthOfBirthInvalid = this.isMonthHasInvalidLength || this.isMonthNumberInvalid;
      this.isYearOfBirthInvalid = this.isYearHasInvalidLength;

      this.isDateOfBirthFieldError = this.isDateOfBirthHasError || this.isDateOfBirthInvalid;

      this.isPatientAgeError =
        this.isDateOfBirthInvalid &&
        !this.isDayOfBirthInvalid &&
        !this.isMonthOfBirthInvalid &&
        !this.isYearOfBirthInvalid;
    },
    clearDateOfBirthErrors() {
      const hasDateFieldErrors =
        this.isDayOfBirthInvalid || this.isMonthOfBirthInvalid || this.isYearOfBirthInvalid;

      const hasDateValidationErrors =
        this.isDateOfBirthHasError || this.isDateOfBirthFieldError || this.isPatientAgeError;

      if (hasDateFieldErrors || hasDateValidationErrors) {
        this.isDateOfBirthHasError = false;

        this.isDayOfBirthInvalid = false;
        this.isMonthOfBirthInvalid = false;
        this.isYearOfBirthInvalid = false;

        this.isDateOfBirthFieldError = false;
        this.isPatientAgeError = false;
      }
    },
    async loadPatientPhoto() {
      if (!this.currentPatientPhotoId) {
        return;
      }

      const { data } = await fetchPatientPhotoByPhotoId(
        this.currentPatientId,
        this.currentPatientPhotoId
      );
      this.displayedPatientPhoto = data;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/helpers/colors';
@import '../../../../assets/css/common/icons.css';
@import '../../../../assets/scss/common/buttons';
@import '../../../../assets/css/common/photos.css';
@import '../../../../assets/css/common/inputs.css';
@import '../../../../assets/scss/helpers/checkbox';
@import '../../../../assets/css/common/tooltips.css';
@import '../../../../assets/scss/common/first-letter';
@import '../../../../assets/css/common/errors';

.treatment-header {
  &__title {
    font-size: 15px;
    line-height: 19px;
    font-weight: 500;
    color: $grey-color;
  }

  &__label {
    margin-right: 10px;
  }

  &__icon {
    margin-right: 5px;
  }
}

.icon-close {
  padding: 10px;
  background-size: 12px;
}

.patient-accept-checkbox {
  &__input:not(:checked) + &__label,
  &__input:checked + &__label {
    padding-left: 29px;
  }
}

@media (max-width: 767px) {
  .treatment-header {
    &__title {
      font-weight: 600;
      font-size: 12px;
      line-height: 14px;
    }

    &__label {
      margin: 0;
    }
  }

  .patient-accept-checkbox {
    &__container {
      margin: 0 0 10px;
    }
  }
}

.rounded-input {
  width: auto;
  padding: 9px 10px;
  font-size: 15px;
  line-height: 17px;
  color: $main-title-color;
}

.save-changes-button {
  margin-left: 20px;
  padding: 9px 15px;
  font-size: 15px;
  line-height: 17px;
}

.patient-edit {
  width: 100%;

  background-color: $white;
  border-radius: 8px;

  &__header {
    height: 50px;
    padding: 0 20px;
  }

  &__content-text {
    font-size: 13px;
    line-height: 15px;
  }

  &__checkbox {
    position: relative;
    margin-top: 0;
  }

  &__title {
    font-size: 15px;
    color: $text-color;
  }

  &__photo__container {
    margin-right: 40px;
  }

  &__edit-section-container {
    height: 578px;
    display: flex;

    padding: 40px 5px 4px 40px;
  }

  &__edit-info-section {
    display: flex;
    flex-direction: column;

    padding-left: 2px;
    padding-right: 38px;

    flex-grow: 1;
  }

  &__edit-horizontal-section {
    display: flex;
    justify-content: space-between;
    flex-shrink: 0;
  }

  &__field {
    width: 100%;

    &-container {
      width: 240px;

      &--full-width {
        width: 100%;
      }
    }

    &-title {
      margin-bottom: 10px;

      font-size: 13px;
      color: $text-color;
    }

    &--quarter-width {
      width: 60px;
    }

    &--third-width {
      width: 100px;
    }

    &--half-width {
      width: 120px;
    }

    &--full-width {
      width: 100%;
    }
  }

  &__select {
    height: 37px;

    margin-bottom: 15px;

    /deep/ .scrollable-dropdown {
      min-width: 120px;
      width: 100%;
    }
  }

  .footer-divider {
    margin-top: 20px;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 19px 45px;
  }

  &__no-delete {
    justify-content: flex-end;
  }
}

.language-dropdown {
  &__label {
    margin-bottom: 0;
  }
}

.fa-trash-alt {
  margin: 0 5px 2px 0;
  color: $icon-grey-color;
}

.photo__container {
  margin-bottom: 20px;
  width: 200px;
  height: 200px;
}

.first-letter {
  line-height: 200px;
  font-size: 56px;
}

.clinic-color {
  display: inline-block;
  box-sizing: content-box;
  width: 11px;
  height: 11px;
  margin-right: 5px;
  padding: 1px;

  border-radius: 10px;
}

@media (max-width: 900px) {
  .photo__container {
    width: 150px;
    height: 150px;
  }

  .first-letter {
    line-height: 150px;
    font-size: 42px;
  }

  .patient-edit__photo__container {
    margin-right: 20px;
  }
}

@media (max-width: 767px) {
  .patient-edit {
    &__title {
      font-size: 16px;
      line-height: 19px;
    }

    &__header {
      position: fixed;
      z-index: 999;
      justify-content: flex-start;
      background-color: $background;

      &__title {
        font-size: 13px;
      }
    }

    &__close-icon {
      margin-right: 20px;
      margin-left: -4px;

      font-size: 16px;
    }

    &__edit-section-container {
      height: auto;
      flex-direction: column;
      padding: 66px 15px 0;
    }

    &__edit-info-section {
      padding-right: 0;
    }

    &__photo__container {
      display: flex;
      justify-content: center;

      margin: 0;
    }

    &__edit-horizontal-section {
      flex-direction: column;

      &__row-mobile {
        flex-direction: row;
        justify-content: space-between;
      }
    }

    &__date-field {
      padding: 7px 6px 9px;
    }

    &__date-field,
    &__field {
      font-size: 16px;
      line-height: 19px;
    }

    &__field {
      &-container {
        width: 100%;
      }

      &-title {
        font-size: 12px;
      }

      &--half-width {
        width: 115px;
      }

      &--quarter-width {
        width: 40px;
      }

      &--third-width {
        width: 70px;
      }
    }

    &__birthday-container {
      max-width: 170px;
      margin-right: 15px;
    }

    &__gender-container {
      width: 115px;
    }

    &__language-dropdown {
      /deep/ & .select-label-container {
        margin-bottom: 10px;
      }
    }

    &__footer {
      flex-direction: column-reverse;

      padding: 20px 30px;

      &__button {
        font-size: 16px;
        line-height: 19px;
      }
    }

    &__delete-button-container {
      margin-top: 20px;
    }
  }

  .photo__container {
    width: 100px;
    height: 100px;
  }

  .first-letter {
    line-height: 100px;
    font-size: 27px;
  }
}
</style>
