<template>
  <div class="validated-issues">
    <div class="validated-issues__header flex--align-center mb10">
      <div class="icon-arrow-long-left custom-icon otherXS" @click="hideValidatedDiagnostics"></div>
      <div class="vertical-divider otherXS"></div>
      <div class="icon-close custom-icon onlyXS" @click="hideValidatedDiagnostics"></div>
      <span
        class="fas fa-exclamation-triangle validated-issues__header__triangle-icon onlyXS"
      ></span>
      <div class="validated-issues__header__title no-search-field">
        {{ $t('dashboard.label.anomaliesValidated') }}
      </div>
    </div>
    <div class="divider otherXS"></div>
    <div class="validated-issues__photo flex--center--center">
      <span class="validated-issues__photo-label">{{ $t('dashboard.label.photo') }}</span>
      <img
        v-if="!hasNoPhoto"
        class="validated-issues__photo__image"
        :src="photoUrl"
        alt="avatar"
        @click="showZoomedPhoto"
      />
      <span v-if="isPhotoNotSaved" class="validated-issues__photo-not-saved">
        {{ $t('dashboard.label.photoWasntSaved') }}
      </span>
      <span v-else-if="hasNoPhoto" class="validated-issues__photo__tooltip ml10">
        {{ $t('dashboard.label.noPhoto') }}
      </span>
      <span v-else class="validated-issues__photo__tooltip">Click to zoom</span>
    </div>
    <div class="validated-issues__anomalies flex-column">
      <ul class="anomaly-list">
        <anomaly
          v-for="anomaly in anomalies"
          :key="`anomaly${anomaly.index}`"
          :anomaly="anomaly"
          :display-confirmation="false"
        ></anomaly>
        <anomaly
          v-for="validation in validations"
          :key="`validation${validation.index}`"
          :anomaly="validation"
          :display-confirmation="false"
        ></anomaly>
      </ul>
    </div>
    <photo-zoom-modal :photo-url="photoUrl" class="onlyXS" />
  </div>
</template>

<script>
import Anomaly from '@/modules/dashboard/components/diagnostic-tab/Anomaly';
import PhotoZoomModal from '@/modules/dashboard/components/common/PhotoZoomModal';

import { PHOTO_VUE_TYPES } from '@/modules/dashboard/api/constants';
import { buildImageUrl } from '@/modules/dashboard/utils';

export default {
  name: 'ValidatedDiagnosticIssues',
  components: { PhotoZoomModal, Anomaly },
  props: {
    diagnostic: {
      type: Object,
      required: true
    },
    photo: PHOTO_VUE_TYPES
  },
  computed: {
    anomalies() {
      return this.diagnostic.anomalies.filter(({ present }) => present);
    },
    validations() {
      return this.diagnostic.validations.filter(({ present }) => present);
    },
    isPhotoNotSaved() {
      return this.photo && !this.photo.convertedToBase64Photo;
    },
    hasNoPhoto() {
      return !this.photo || this.isPhotoNotSaved;
    },
    photoUrl() {
      return buildImageUrl(this.photo);
    }
  },
  methods: {
    showZoomedPhoto() {
      this.$modal.show('photo-zoom-modal', { photoUrl: this.photoUrl });
    },
    hideValidatedDiagnostics() {
      this.$emit('hide-validated-diagnostics');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/helpers/colors';

.validated-issues {
  width: 100%;
  min-width: 320px;
  padding: 15px 30px 30px;
  background: $white;
  border-radius: 8px;
  font-size: 15px;
  line-height: 17px;
  color: $text-color;

  &__photo {
    margin-top: 30px;
    margin-bottom: 20px;

    &__image {
      height: 54px;
      cursor: pointer;
      border-radius: 7px;
      margin-left: 15px;
      margin-right: 10px;
    }

    &-not-saved {
      margin-left: 22px;
    }

    &-not-saved,
    &__tooltip {
      font-size: 12px;
      line-height: 14px;
      font-style: italic;
      color: $grey-color;
    }
  }
}

.vertical-divider {
  margin-left: 20px;
  margin-right: 15px;
}

.anomaly-list {
  padding: 0;
  list-style: none;
}

/deep/ .anomaly__details {
  margin-bottom: 25px;
}

@media (max-width: 767px) {
  .validated-issues {
    min-width: auto;
    padding: 0;
    background: transparent;

    &__header {
      padding-left: 18px;
      height: 46px;

      &__triangle-icon {
        color: $icon-dim-grey-color;
        margin-left: 24px;
        margin-right: 10px;
      }
    }

    &__photo {
      margin-top: 20px;

      &-label {
        font-size: 13px;
      }

      &-not-saved {
        font-size: 10px;
      }
    }
  }
}
</style>
