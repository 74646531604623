<template>
  <div
    class="timeline-section-header flex--align-center"
    :class="{ pointer: canOpenSection }"
    @click="checkCanOpenSection"
  >
    <span class="timeline-section-header__date">{{ formattedTimelineDate }}</span>
    <slot name="header-title">
      <span class="timeline-section-header__title">{{ $t(timelineHeader) }}</span>
    </slot>
    <span
      v-if="canOpenSection"
      class="timeline-section-header__open-icon fas fa-chevron-down"
      :class="{ 'fa-chevron-up': isShown }"
    ></span>
  </div>
</template>

<script>
import { formatDate } from '@/modules/dashboard/utils/date-utils';

export default {
  name: 'TimelineSectionHeader',
  props: {
    timelineDate: {
      type: String,
      required: true
    },
    timelineHeader: {
      type: String,
      default: ''
    },
    isShown: {
      type: Boolean,
      default: false
    },
    canOpenSection: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    formattedTimelineDate() {
      return formatDate(this.timelineDate, 'DD-MMM-YYYY');
    }
  },
  methods: {
    checkCanOpenSection(event) {
      if (!this.canOpenSection) {
        event.stopPropagation();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../../assets/scss/helpers/colors';

.timeline-section-header {
  min-height: 30px;

  &__date {
    font-weight: 300;
    font-size: 13px;
    line-height: 19px;

    color: $gumbo;

    margin-right: 15px;
    white-space: nowrap;
  }

  &__title {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;

    color: $text-color;
  }

  &__open-icon {
    width: 30px;
    height: 30px;

    font-size: 10px;
    line-height: 30px;
    text-align: center;
    color: $dim-grey-color;

    margin-left: auto;
  }
}
</style>
