<template>
  <div class="treatment-paid-checkbox flex--align-center">
    <div class="checkbox">
      <input
        id="treatmentHistoryPaidCheckbox"
        :key="value"
        :checked="value"
        name="treatmentHistoryPaidCheckbox"
        type="checkbox"
        :disabled="disabled"
        @click.prevent="checkConfirmation"
      />
      <label for="treatmentHistoryPaidCheckbox" class="checkbox--with-border p0"></label>
    </div>
    <span class="treatment-paid-checkbox__label">
      {{ $t('dashboard.label.treatmentPaid') }}
    </span>
    <span
      v-tooltip="{
        content: $t('dashboard.hint.treatmentPaymentInformation'),
        placement: 'right-start',
        classes: 'treatment-detail-tooltip'
      }"
      class="custom-icon icon-info"
    ></span>

    <paid-checkbox-confirmation-modal />
  </div>
</template>

<script>
import PaidCheckboxConfirmationModal from '@/modules/dashboard/components/treatment-history-tab/treatment-details/PaidCheckboxConfirmationModal.vue';
import { UNSAVED_CHANGES_INPUTS, TREATMENT_STATUSES } from '@/modules/dashboard/api/constants';

export default {
  name: 'TreatmentPaidCheckbox',
  components: { PaidCheckboxConfirmationModal },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    treatmentStatus: {
      type: String,
      default: ''
    }
  },
  methods: {
    async checkConfirmation() {
      if (this.treatmentStatus === TREATMENT_STATUSES.TREATMENT_COMPLETED) {
        this.$emit('input', true);

        return;
      }

      const isPaidConfirmed = await this.checkPaidCheckboxConfirmation();

      if (isPaidConfirmed) {
        this.$emit('input', isPaidConfirmed);
      }
    },
    async checkPaidCheckboxConfirmation() {
      const answer = await this.getPaidCheckboxConfirmationAnswer();

      return answer === UNSAVED_CHANGES_INPUTS.YES;
    },
    getPaidCheckboxConfirmationAnswer() {
      return new Promise(resolve => {
        this.$modal.show('paid-checkbox-confirmation-modal', { answerResolver: resolve });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../assets/scss/helpers/colors';
@import '../../../../../assets/scss/helpers/checkbox';
@import '../../../../../assets/css/common/icons';

.treatment-paid-checkbox {
  &__label {
    flex-shrink: 0;

    font-size: 13px;
    line-height: 19px;
    color: $text-color;
  }
}
</style>
