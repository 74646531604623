<template>
  <div class="diagnostic-details__header-content">
    <mobile-header>
      <div slot="header-title" class="diagnostic-mobile-header flex--align-center">
        <span class="first-letter">{{ diagnosticFirstLetter }}</span>
        <span class="diagnostic-mobile-header__title">{{ diagnosticLabel }}</span>
      </div>
    </mobile-header>
    <div class="diagnostic-details__header">
      <div class="flex--space-between--center full-width">
        <div class="diagnostic-details__header-date__container flex--align-center">
          <div class="diagnostic-details__header-icon">
            <div class="custom-icon cursor-default" :class="iconClasses"></div>
          </div>
          <div class="diagnostic-details__header-info flex-column">
            <div class="diagnostic-details__header-date">
              {{ diagnostic.date | formatDate('DD-MMM-YYYY') }}
            </div>
            <div class="diagnostic-details__header-status">
              {{ $t('dashboard.label.createdTreatment') }}
            </div>
          </div>
        </div>
        <template v-if="diagnostic.hasValidationsOrAnomalies">
          <div v-if="!validated" class="anomaly-validation flex--align-center">
            <span class="fas fa-exclamation-triangle error-message"></span>
            <span class="anomaly-validation__title">
              {{ $t('dashboard.diagnostic.validationsRequired') }}
            </span>
          </div>
          <div
            v-else
            class="anomaly-validation rounded-button-gray"
            @click="showValidatedDiagnostics"
          >
            <span class="fas fa-exclamation-triangle"></span>
            <span class="anomaly-validation__title">
              {{ $t('dashboard.label.validated') }}
            </span>
          </div>
        </template>
      </div>
    </div>
    <diagnostic-details-header :photo="photo" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import MobileHeader from '@/modules/dashboard/components/common/MobileHeader';
import DiagnosticDetailsHeader from '@/modules/dashboard/components/diagnostic-tab/DiagnosticDetailsHeader';

import { types as dashTypes } from '@/modules/dashboard/store/types';
import { DIAGNOSTIC_TYPES, PHOTO_VUE_TYPES } from '@/modules/dashboard/api/constants';

export default {
  name: 'DiagnosticHeader',
  components: { DiagnosticDetailsHeader, MobileHeader },
  props: {
    diagnostic: {
      type: Object,
      required: true
    },
    photo: PHOTO_VUE_TYPES,
    validated: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  computed: {
    ...mapGetters({
      diagnostics: dashTypes.getters.CURRENT_PATIENT_DIAGNOSTICS
    }),
    isPhotoAnalysisDiagnostic() {
      return this.diagnostic.type === DIAGNOSTIC_TYPES.PHOTO_BASED;
    },
    diagnosticFirstLetter() {
      return `D${this.diagnostic.number}`;
    },
    diagnosticLabel() {
      return `${this.$t('dashboard.details.diagnostic')} ${this.diagnostic.number}`;
    },
    iconClasses() {
      if (this.isPhotoAnalysisDiagnostic) {
        return 'icon-portrait';
      }

      return this.diagnostic.hasTreatment ? 'icon-clipboard-grey' : 'icon-clipboard';
    }
  },
  methods: {
    showValidatedDiagnostics() {
      this.$emit('show-validated-diagnostics');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/helpers/colors';
@import '../../../../assets/css/common/errors.css';
@import '../../../../assets/scss/common/buttons';
@import '../../../../assets/scss/common/first-letter';

.diagnostic-details {
  &__header {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    margin-bottom: 30px;
    border-bottom: 1px solid $separator-grey-color;
  }

  &__header-icon {
    width: 14px;
    height: 16px;
    margin-right: 12px;
  }

  &__header-date {
    font-size: 13px;
    font-weight: 400;
    color: $active-title-color;
    margin-bottom: 5px;
  }

  &__header-status {
    font-size: 12px;
    font-weight: 200;
    font-style: italic;
    line-height: 14px;
    color: $grey-color;
  }
}

.anomaly-validation {
  &__title {
    font-style: italic;
    color: $text-color;
    font-size: 12px;
    line-height: 14px;
    margin-left: 10px;
    margin-right: 7px;
  }
}

@media (max-width: 767px) {
  .diagnostic-mobile-header {
    &__title {
      margin-left: 10px;
      font-size: 13px;
      line-height: 15px;
    }
  }

  .diagnostic-details {
    &__header {
      padding: 14px 12px 0 16px;
      margin-top: 46px;
      margin-bottom: 20px;
      border-bottom: none;
    }

    &__header-date {
      margin-bottom: 0;

      &__container {
        align-items: flex-start;
        margin-top: 5px;
      }
    }
  }
}
</style>
