<template>
  <div v-if="isOfflineTreatment" class="treatment-details-footer">
    <div class="treatment-details-footer__buttons treatment-footer-buttons">
      <button
        class="treatment-footer-buttons__desktop-button rounded-button-white mr20"
        @click="renewTreatment"
      >
        {{ $t('dashboard.button.renew') }}
      </button>
      <button
        class="treatment-footer-buttons__desktop-button rounded-button-blue"
        @click="printLabel"
      >
        <span class="print-label-icon custom-icon icon-pdf-button"></span>
        {{ $t('dashboard.action.printLabel') }}
      </button>
      <button
        class="treatment-footer-buttons__delete delete-treatment-button flex--center--center transparent-button"
        @click="deleteTreatment"
      >
        <span class="delete-treatment-button__icon far fa-trash-alt"></span>
        <span class="onlyXS ml5">
          {{ $t('dashboard.treatmentsHistory.deleteTreatment') }}
        </span>
      </button>
    </div>
    <div class="treatment-details-footer__mobile-footer flex--space-between--center">
      <button class="treatment-details__footer-button rounded-button-white" @click="renewTreatment">
        {{ $t('dashboard.button.renew') }}
      </button>
      <button
        class="treatment-mobile-button rounded-button-blue flex--align-center"
        @click="printLabel"
      >
        <span class="print-label-icon custom-icon icon-pdf-button"></span>
        {{ $t('dashboard.action.printLabel') }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TreatmentDetailsFooter',
  props: {
    isOfflineTreatment: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    printLabel() {
      this.$emit('print-label');
    },
    renewTreatment() {
      this.$emit('renew-treatment');
    },
    deleteTreatment() {
      this.$emit('delete-treatment');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../assets/scss/common/buttons';
@import '../../../../../assets/css/common/icons';

.treatment-details-footer {
  &__buttons {
    display: flex;
    align-items: center;
    width: 100%;
  }

  &__footer-button {
    margin-right: 20px;
  }

  &__mobile-footer {
    display: none;
  }
}

.treatment-footer-buttons {
  &__desktop-button {
    display: flex;
    align-items: center;
  }

  &__delete {
    margin-left: auto;
    color: $grey-color;
  }
}

.print-label-icon {
  margin-right: 10px;
}

.icon-replicate-treatment {
  margin-right: 8px;
}

.delete-treatment-button {
  &__icon {
    color: $grey-color;
  }
}

@media (max-width: 767px) {
  .treatment-details-footer {
    &__mobile-footer {
      position: fixed;
      width: 100%;

      bottom: 0;
      left: 0;
      z-index: 5;
      padding: 15px;

      background: $white;
      box-shadow: 0 -6px 7px rgba(148, 166, 176, 0.15);
    }

    &__mobile-footer {
      display: flex;
    }
  }

  .treatment-footer-buttons {
    &__desktop-button {
      display: none;
    }

    &__delete {
      flex-basis: 100%;
    }
  }

  .treatment-mobile-button {
    padding: 7px 11px;
    font-size: 13px;
    line-height: 15px;
  }

  .print-label-icon {
    margin-right: 9px;
  }
}
</style>
