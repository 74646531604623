<template>
  <mobile-header @return-back="returnBack">
    <div slot="header-title" class="treatment-mobile-header flex--align-center">
      <div class="treatment-mobile-header__title">{{ treatmentLabel }}</div>
      <div v-if="diagnosticNum" class="treatment__based-container flex--align-center">
        <span class="treatment__based-text">{{ $t('dashboard.label.basedOn') }}</span>
        <p class="first-letter">
          <span>{{ diagnosticNum }}</span>
        </p>
      </div>
    </div>
  </mobile-header>
</template>

<script>
import { get } from 'lodash';
import { mapGetters } from 'vuex';

import MobileHeader from '@/modules/dashboard/components/common/MobileHeader';

import { types as dashTypes } from '@/modules/dashboard/store/types';
import { DATE_FORMAT } from '@/modules/dashboard/api/constants';

export default {
  name: 'TreatmentDetailsHeader',
  components: { MobileHeader },
  props: {
    treatment: {
      type: Object,
      required: true
    },
    treatmentDiagnostic: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dateFormat: DATE_FORMAT
    };
  },
  computed: {
    ...mapGetters({
      treatments: dashTypes.getters.CURRENT_PATIENT_TREATMENTS
    }),
    treatmentNumber() {
      return this.treatment.number;
    },
    diagnosticNum() {
      const diagnosticNumber = get(this.treatmentDiagnostic, 'number');

      return diagnosticNumber ? `D${diagnosticNumber}` : '';
    },
    treatmentLabel() {
      return `${this.$t('dashboard.details.treatment')} ${this.treatmentNumber}`;
    }
  },
  methods: {
    returnBack() {
      this.$emit('return-back');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../assets/scss/helpers/colors';
@import '../../../../../assets/scss/common/first-letter';

@media (max-width: 767px) {
  .treatment {
    &__based-text {
      margin-right: 5px;
      font-weight: 100;
      font-size: 12px;
      line-height: 17px;
      color: $main-title-color;
    }
  }

  .treatment-mobile-header {
    &__title {
      margin-right: 15px;
      font-weight: normal;
      font-size: 15px;
      line-height: 17px;
      color: $main-title-color;
    }
  }
}
</style>
