<template>
  <div class="medical-data-tab">
    <div class="medical-data-tab__content">
      <mobile-header>
        <span slot="header-title" class="medical-data-tab__header-title">
          Medical data
        </span>
      </mobile-header>
      <div class="data-container">
        <div class="medical-data-fields no-search-field">
          <div class="data-container__column">
            <div class="field-container">
              <span class="field-container__title">
                <small class="color1">{{ $t('dashboard.medicalData.country') }}</small>
              </span>
              <q-select
                class="medical-data-fields__country-select"
                label="label"
                track-by="label"
                :allow-empty="false"
                :placeholder="$t('dashboard.medicalData.selectOption')"
                :value="countryValue"
                :options="countrySelectOptions"
                @input="updateCountry($event)"
              />
            </div>
            <div class="field-container">
              <span class="field-container__title">
                <small class="color1">{{ $t('dashboard.medicalData.city') }}</small>
              </span>
              <q-select
                class="medical-data-fields__city-select"
                label="label"
                track-by="label"
                :placeholder="$t('dashboard.medicalData.selectOption')"
                :value="cityValue"
                :options="citiesSelectOptions"
                @input="updateCity($event)"
              />
            </div>
            <div
              v-for="field in medicalDataSelectsBinding"
              :key="field.label"
              class="field-container"
            >
              <p class="field-container__title">
                <small class="color1">{{ field.label }}</small>
              </p>
              <q-select
                label="label"
                :allow-empty="false"
                :placeholder="$t('dashboard.medicalData.selectOption')"
                :value="patientMedicalData[field.propertyName]"
                :options="field.options"
                track-by="label"
                @input="onMedicalDataFieldChange(field.propertyName, $event)"
              ></q-select>
            </div>
          </div>
          <div class="data-container__column">
            <div class="field-container field-container_allergies column">
              <p class="field-container__title">
                <small class="color1">{{ $t('dashboard.label.allergies') }}</small>
              </p>
              <q-select
                ref="scrollable-dropdown"
                class="medical-data-fields__allergies-select"
                label="label"
                group-label="allergyType"
                group-values="allergies"
                :placeholder="$t('dashboard.medicalData.selectOption')"
                :group-select="false"
                multiple
                :close-on-select="false"
                :options="groupedAllergies"
                :value="patientMedicalData.allergiesDr"
                track-by="label"
                @input="onMedicalDataFieldChange('allergiesDr', $event)"
              >
                <div class="erere"></div>
              </q-select>
            </div>
            <div
              v-for="textArea in textAreasData"
              :key="textArea.label"
              class="field-container column"
            >
              <p class="field-container__title">
                <small class="color1">{{ textArea.label }}</small>
              </p>
              <textarea
                ref="textareas"
                class="medical-data-textarea textarea jsautosize"
                :placeholder="$t('dashboard.medicalData.noData')"
                :value="textArea.property"
                @change="onMedicalDataFieldChange(textArea.propertyName, $event)"
              ></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import autosize from 'autosize';
import PerfectScrollbar from 'perfect-scrollbar';
import { get, keys, fromPairs, sortBy } from 'lodash';

import MobileHeader from '@/modules/dashboard/components/common/MobileHeader';
import QSelect from '@/modules/dashboard/components/common/Select';
import SelectDataBinding from '@/components/common/SelectDataBinding';
import MedicalDataTextarea from '@/modules/dashboard/components/patient-card/MedicalDataTextarea';

import InputObject from '@/components/common/SelectOptionObject';

import { getPatientMedicalData, updatePatientMedicalData } from '@/modules/dashboard/api';
import { getSortedCities } from '@/api/places';

import {
  getArrayElementOrNull,
  getMedicalDataSelectOptionsWithLabel
} from '@/modules/dashboard/utils';
import { displayNameMixin } from '@/mixins/displayNameMixin';

import { formatDate } from '@/modules/dashboard/utils/date-utils';

import rootTypes from '@/store/types';
import { types } from '@/modules/dashboard/store/types';

import { GENDER } from '@/modules/questionnaire/api/constants';
import { DEFAULT_PERFECT_SCROLLBAR_OPTIONS } from '@/modules/dashboard/api/constants';

import {
  PHOTOTYPE,
  SKIN_THICKNESS,
  MENOPAUSAL_STATE,
  YES_NO,
  YES_NO_WITH_NOT_APPLICABLE,
  NO_WITH_NOT_APPLICABLE,
  NOT_APPLICABLE,
  ALLERGIES,
  GROUPED_ALLERGIES,
  MENOPAUSAL_VALUES
} from '@/modules/dashboard/api/medicalData';
import { COUNTRY_CODES } from '@/api/places/COUNTRIES';

const pregnantDateFormat = 'YYYY-MM-DD';
const PREGMENT_DR = 'pregnantDr';
const MENOPAUSAM_STATE_DR = 'menopausalStateDr';
const TAKING_HRT_DR = 'takingHRTDr';
const TAKING_BIRT_CONTROL_PILL_DR = 'takingBirthControlPillDr';
const USES_IUD_DR = 'usesIUD_Dr';
const YES = 'yes';

const MEDICAL_DATA_FIELDS_CONFIG = {
  phototypeDr: 'phototypeSelectOptions',
  skinThicknessDr: 'skinThinknessSelectOptions',
  skinReactsToProductsDr: 'yesNoSelectOptions',
  pregnantDr: 'pregnantSelectOptions',
  menopausalStateDr: 'menopausalSelectOptions',
  takingHRTDr: 'yesNoWithNotApplicableSelectOptions',
  takingBirthControlPillDr: 'yesNoWithNotApplicableSelectOptions',
  // eslint-disable-next-line camelcase
  usesIUD_Dr: 'yesNoWithNotApplicableSelectOptions',
  breastCancerDr: 'yesNoSelectOptions',
  treatedForSkinCancerDr: 'yesNoSelectOptions',
  glandularCancerDr: 'yesNoSelectOptions',
  hasHerpesDr: 'yesNoSelectOptions',
  sufferedFromAcneRosaceaDr: 'yesNoSelectOptions'
};

export default {
  name: 'MedicalDataTab',
  components: { QSelect, MobileHeader },
  mixins: [displayNameMixin],
  data() {
    return {
      allergiesDropdownscrollbar: null,
      countriesDropdownScrollbar: null,
      citiesDropdownScrollbar: null,
      currentPatientMedicalData: null,
      patientMedicalData: {
        phototypeDr: null,
        skinThicknessDr: null,
        skinReactsToProductsDr: null,
        pregnantDr: null,
        menopausalStateDr: null,
        takingHRTDr: null,
        takingBirthControlPillDr: null,
        usesIUD_Dr: null,
        breastCancerDr: null,
        treatedForSkinCancerDr: null,
        glandularCancerDr: null,
        hasHerpesDr: null,
        sufferedFromAcneRosaceaDr: null,
        allergiesDr: null,
        otherAllergiesDr: null,
        medicalConditionsDr: null,
        medicationsUsedDr: null,
        pregnantLastUpdated: null
      }
    };
  },
  computed: {
    ...mapGetters({
      currentPatient: types.getters.CURRENT_PATIENT,
      currentPatientId: types.getters.CURRENT_PATIENT_ID
    }),
    countryValue() {
      if (!this.currentPatientMedicalData) {
        return;
      }

      const { countryOfResidence } = this.currentPatientMedicalData;

      const countryLabel = this.getCountryDisplayName(countryOfResidence);

      return countryOfResidence ? this.getSelectOption(countryLabel, countryOfResidence) : null;
    },
    cityValue() {
      if (!this.currentPatientMedicalData) {
        return;
      }

      const { countryOfResidence, cityOfResidence } = this.currentPatientMedicalData;

      const cityLabel =
        countryOfResidence && cityOfResidence
          ? this.$t(`city.${countryOfResidence}.${cityOfResidence}`)
          : '';

      return cityLabel ? this.getSelectOption(cityLabel, cityOfResidence) : null;
    },
    countrySelectOptions() {
      const countries = COUNTRY_CODES.map(code => ({
        label: this.getCountryDisplayName(code),
        value: code
      }));

      return sortBy(countries, country => country.label);
    },
    citiesSelectOptions() {
      if (!this.currentPatientMedicalData) {
        return;
      }

      const { countryOfResidence } = this.currentPatientMedicalData;
      const cities = countryOfResidence ? getSortedCities(countryOfResidence, true) : [];

      return cities.map(city => ({ ...city, label: this.$t(city.label) }));
    },
    medicalDataFields() {
      return get(this.currentPatientMedicalData, 'json', {});
    },
    phototypeSelectOptions() {
      return getMedicalDataSelectOptionsWithLabel(this.$t, PHOTOTYPE);
    },
    skinThinknessSelectOptions() {
      return getMedicalDataSelectOptionsWithLabel(this.$t, SKIN_THICKNESS);
    },
    pregnantSelectOptions() {
      const { pregnantLastUpdated, pregnantDr } = this.medicalDataFields;

      const pregnantDate =
        pregnantDr && pregnantDr !== 'no'
          ? pregnantLastUpdated
          : formatDate(Date.now(), pregnantDateFormat);

      const pregnantYesOptionLabel = pregnantLastUpdated
        ? this.$t('dashboard.medicalData.yesUpdated', [pregnantDate])
        : this.$t('dashboard.medicalData.yes');

      return [
        { label: pregnantYesOptionLabel, value: 'yes' },
        ...getMedicalDataSelectOptionsWithLabel(this.$t, NO_WITH_NOT_APPLICABLE)
      ];
    },
    menopausalSelectOptions() {
      return getMedicalDataSelectOptionsWithLabel(this.$t, MENOPAUSAL_STATE);
    },
    yesNoSelectOptions() {
      return getMedicalDataSelectOptionsWithLabel(this.$t, YES_NO);
    },
    yesNoWithNotApplicableSelectOptions() {
      return getMedicalDataSelectOptionsWithLabel(this.$t, YES_NO_WITH_NOT_APPLICABLE);
    },
    availableAllergies() {
      return this.getAllergiesSelectOptions(ALLERGIES);
    },
    groupedAllergies() {
      return GROUPED_ALLERGIES.map(({ allergyType, allergies }) => {
        const allergiesOptions = this.getAllergiesSelectOptions(allergies);

        return {
          allergyType: this.$t(`dashboard.allergies.${allergyType}`),
          allergies: allergiesOptions
        };
      });
    },
    medicalDataSelectsBinding() {
      switch (this.currentPatient.gender) {
        case GENDER.MALE: {
          return this.getMaleSelectsBinding();
        }

        case GENDER.FEMALE: {
          return this.getFemaleSelectsBinding();
        }

        default: {
          return this.getOtherSelectBinding();
        }
      }
    },
    textAreasData() {
      const { otherAllergiesDr, medicalConditionsDr, medicationsUsedDr } = this.patientMedicalData;

      return [
        new MedicalDataTextarea(
          this.$i18n.t('dashboard.medicalData.otherAllergies'),
          otherAllergiesDr,
          'otherAllergiesDr'
        ),
        new MedicalDataTextarea(
          this.$i18n.t('dashboard.medicalData.medicalConditions'),
          medicalConditionsDr,
          'medicalConditionsDr'
        ),
        new MedicalDataTextarea(
          this.$i18n.t('dashboard.medicalData.medications'),
          medicationsUsedDr,
          'medicationsUsedDr'
        )
      ];
    }
  },
  async created() {
    this.setLoading(true);
    const { data } = await getPatientMedicalData(this.currentPatientId);
    this.currentPatientMedicalData = data;
    this.setLoading(false);

    this.fillMedicalDataFields();
  },
  mounted() {
    autosize([...this.$refs.textareas]);

    const allergiesList = document.body.querySelector(
      '.medical-data-fields__allergies-select .multiselect__content-wrapper '
    );
    const countriesList = document.body.querySelector(
      '.medical-data-fields__country-select .multiselect__content-wrapper '
    );
    const citiesList = document.body.querySelector(
      '.medical-data-fields__city-select .multiselect__content-wrapper '
    );

    this.allergiesDropdownscrollbar = new PerfectScrollbar(allergiesList, {
      DEFAULT_PERFECT_SCROLLBAR_OPTIONS
    });
    this.countriesDropdownScrollbar = new PerfectScrollbar(countriesList, {
      DEFAULT_PERFECT_SCROLLBAR_OPTIONS
    });
    this.citiesDropdownScrollbar = new PerfectScrollbar(citiesList, {
      DEFAULT_PERFECT_SCROLLBAR_OPTIONS
    });
  },
  updated() {
    this.updateTextAreaSize();
  },
  methods: {
    ...mapActions({
      setLoading: rootTypes.actions.SET_LOADING
    }),
    async updateCountry({ value }) {
      this.currentPatientMedicalData.countryOfResidence = value;
      this.currentPatientMedicalData.cityOfResidence = '';

      await this.updateMedicalData();
    },
    async updateCity({ value }) {
      this.currentPatientMedicalData.cityOfResidence = value;

      await this.updateMedicalData();
    },
    getSelectOption(label, value) {
      return new InputObject(label, value);
    },
    getFemaleSelectsBinding() {
      return [
        new SelectDataBinding(
          this.$i18n.t('dashboard.medicalData.phototype'),
          'phototypeDr',
          this.phototypeSelectOptions
        ),
        new SelectDataBinding(
          this.$i18n.t('dashboard.medicalData.skinThickness'),
          'skinThicknessDr',
          this.skinThinknessSelectOptions
        ),
        new SelectDataBinding(
          this.$i18n.t('dashboard.medicalData.verySensitiveSkin'),
          'skinReactsToProductsDr',
          this.yesNoSelectOptions
        ),
        new SelectDataBinding(
          this.$i18n.t('dashboard.medicalData.pregnant'),
          PREGMENT_DR,
          this.pregnantSelectOptions
        ),
        new SelectDataBinding(
          this.$i18n.t('dashboard.medicalData.menopausalState'),
          'menopausalStateDr',
          this.menopausalSelectOptions
        ),
        new SelectDataBinding(
          this.$i18n.t('dashboard.medicalData.takeHRT'),
          'takingHRTDr',
          this.yesNoWithNotApplicableSelectOptions
        ),
        new SelectDataBinding(
          this.$i18n.t('dashboard.medicalData.takeControlPill'),
          'takingBirthControlPillDr',
          this.yesNoWithNotApplicableSelectOptions
        ),
        new SelectDataBinding(
          this.$i18n.t('dashboard.medicalData.useIUD'),
          'usesIUD_Dr',
          this.yesNoWithNotApplicableSelectOptions
        ),
        new SelectDataBinding(
          this.$i18n.t('dashboard.medicalData.treatedBreastCancer'),
          'breastCancerDr',
          this.yesNoSelectOptions
        ),
        new SelectDataBinding(
          this.$i18n.t('dashboard.medicalData.treatedSkinCancer'),
          'treatedForSkinCancerDr',
          this.yesNoSelectOptions
        ),
        new SelectDataBinding(
          this.$i18n.t('dashboard.medicalData.treatedAdenocarcinoma'),
          'glandularCancerDr',
          this.yesNoSelectOptions
        ),
        new SelectDataBinding(
          this.$i18n.t('dashboard.medicalData.coldSores'),
          'hasHerpesDr',
          this.yesNoSelectOptions
        ),
        new SelectDataBinding(
          this.$i18n.t('dashboard.medicalData.treatedAcneRosacea'),
          'sufferedFromAcneRosaceaDr',
          this.yesNoSelectOptions
        )
      ];
    },
    getMaleSelectsBinding() {
      return [
        new SelectDataBinding(
          this.$i18n.t('dashboard.medicalData.phototype'),
          'phototypeDr',
          this.phototypeSelectOptions
        ),
        new SelectDataBinding(
          this.$i18n.t('dashboard.medicalData.skinThickness'),
          'skinThicknessDr',
          this.skinThinknessSelectOptions
        ),
        new SelectDataBinding(
          this.$i18n.t('dashboard.medicalData.verySensitiveSkin'),
          'skinReactsToProductsDr',
          this.yesNoSelectOptions
        ),
        new SelectDataBinding(
          this.$i18n.t('dashboard.medicalData.treatedBreastCancer'),
          'breastCancerDr',
          this.yesNoSelectOptions
        ),
        new SelectDataBinding(
          this.$i18n.t('dashboard.medicalData.treatedSkinCancer'),
          'treatedForSkinCancerDr',
          this.yesNoSelectOptions
        ),
        new SelectDataBinding(
          this.$i18n.t('dashboard.medicalData.treatedAdenocarcinoma'),
          'glandularCancerDr',
          this.yesNoSelectOptions
        ),
        new SelectDataBinding(
          this.$i18n.t('dashboard.medicalData.coldSores'),
          'hasHerpesDr',
          this.yesNoSelectOptions
        ),
        new SelectDataBinding(
          this.$i18n.t('dashboard.medicalData.treatedAcneRosacea'),
          'sufferedFromAcneRosaceaDr',
          this.yesNoSelectOptions
        )
      ];
    },
    getOtherSelectBinding() {
      return [
        new SelectDataBinding(
          this.$i18n.t('dashboard.medicalData.phototype'),
          'phototypeDr',
          this.phototypeSelectOptions
        ),
        new SelectDataBinding(
          this.$i18n.t('dashboard.medicalData.skinThickness'),
          'skinThicknessDr',
          this.skinThinknessSelectOptions
        ),
        new SelectDataBinding(
          this.$i18n.t('dashboard.medicalData.verySensitiveSkin'),
          'skinReactsToProductsDr',
          this.yesNoSelectOptions
        ),
        new SelectDataBinding(
          this.$i18n.t('dashboard.medicalData.takeHRT'),
          'takingHRTDr',
          this.yesNoWithNotApplicableSelectOptions
        ),
        new SelectDataBinding(
          this.$i18n.t('dashboard.medicalData.treatedBreastCancer'),
          'breastCancerDr',
          this.yesNoSelectOptions
        ),
        new SelectDataBinding(
          this.$i18n.t('dashboard.medicalData.treatedSkinCancer'),
          'treatedForSkinCancerDr',
          this.yesNoSelectOptions
        ),
        new SelectDataBinding(
          this.$i18n.t('dashboard.medicalData.treatedAdenocarcinoma'),
          'glandularCancerDr',
          this.yesNoSelectOptions
        ),
        new SelectDataBinding(
          this.$i18n.t('dashboard.medicalData.coldSores'),
          'hasHerpesDr',
          this.yesNoSelectOptions
        ),
        new SelectDataBinding(
          this.$i18n.t('dashboard.medicalData.treatedAcneRosacea'),
          'sufferedFromAcneRosaceaDr',
          this.yesNoSelectOptions
        )
      ];
    },
    getAllergiesSelectOptions(allergies) {
      return allergies.map(
        allergy => new InputObject(this.$t(`dashboard.allergies.${allergy}`), allergy)
      );
    },
    fillMedicalDataFields() {
      Object.keys(this.medicalDataFields).forEach(field => {
        if (MEDICAL_DATA_FIELDS_CONFIG[field]) {
          this.patientMedicalData[field] = getArrayElementOrNull(
            this[MEDICAL_DATA_FIELDS_CONFIG[field]],
            this.medicalDataFields[field]
          );

          return;
        }

        this.patientMedicalData[field] = this.medicalDataFields[field];
      });

      this.patientMedicalData.pregnantLastUpdated = this.medicalDataFields.pregnantLastUpdated;

      const allergies = this.medicalDataFields.allergiesDr;

      this.patientMedicalData.allergiesDr = allergies
        ? this.availableAllergies.filter(({ value }) => allergies.includes(value))
        : null;

      this.patientMedicalData.otherAllergiesDr = this.medicalDataFields.otherAllergiesDr;
      this.patientMedicalData.medicalConditionsDr = this.medicalDataFields.medicalConditionsDr;
      this.patientMedicalData.medicationsUsedDr = this.medicalDataFields.medicationsUsedDr;
    },
    async onMedicalDataFieldChange(propertyName, event) {
      this.patientMedicalData[propertyName] = get(event, 'target.value', event);

      const [NOT_APPLICABLE_DATA] = getMedicalDataSelectOptionsWithLabel(this.$t, NOT_APPLICABLE);

      if (propertyName === PREGMENT_DR && this.patientMedicalData.pregnantDr.value === YES) {
        this.patientMedicalData.menopausalStateDr = NOT_APPLICABLE_DATA;
        this.patientMedicalData.takingHRTDr = NOT_APPLICABLE_DATA;
        this.patientMedicalData.takingBirthControlPillDr = NOT_APPLICABLE_DATA;
        this.patientMedicalData.usesIUD_Dr = NOT_APPLICABLE_DATA;
      }

      if (
        propertyName === MENOPAUSAM_STATE_DR &&
        MENOPAUSAL_VALUES.includes(this.patientMedicalData.menopausalStateDr.value)
      ) {
        this.patientMedicalData.pregnantDr = NOT_APPLICABLE_DATA;
        this.patientMedicalData.takingBirthControlPillDr = NOT_APPLICABLE_DATA;
        this.patientMedicalData.usesIUD_Dr = NOT_APPLICABLE_DATA;
      }

      if (propertyName === TAKING_HRT_DR && this.patientMedicalData.takingHRTDr.value === YES) {
        this.patientMedicalData.pregnantDr = NOT_APPLICABLE_DATA;
        this.patientMedicalData.takingBirthControlPillDr = NOT_APPLICABLE_DATA;
        this.patientMedicalData.usesIUD_Dr = NOT_APPLICABLE_DATA;
      }

      if (
        propertyName === TAKING_BIRT_CONTROL_PILL_DR &&
        this.patientMedicalData.takingBirthControlPillDr.value === YES
      ) {
        this.patientMedicalData.pregnantDr = NOT_APPLICABLE_DATA;
        this.patientMedicalData.menopausalStateDr = NOT_APPLICABLE_DATA;
        this.patientMedicalData.takingHRTDr = NOT_APPLICABLE_DATA;
        this.patientMedicalData.usesIUD_Dr = NOT_APPLICABLE_DATA;
      }

      if (propertyName === USES_IUD_DR && this.patientMedicalData.usesIUD_Dr.value === YES) {
        this.patientMedicalData.pregnantDr = NOT_APPLICABLE_DATA;
        this.patientMedicalData.menopausalStateDr = NOT_APPLICABLE_DATA;
        this.patientMedicalData.takingHRTDr = NOT_APPLICABLE_DATA;
        this.patientMedicalData.takingBirthControlPillDr = NOT_APPLICABLE_DATA;
      }

      if (propertyName === PREGMENT_DR) {
        await this.updatePregnantDate();
      }

      await this.updateMedicalData();
    },
    async updatePregnantDate() {
      const newDate = formatDate(new Date(), pregnantDateFormat);

      this.patientMedicalData.pregnantLastUpdated = newDate;

      this.currentPatientMedicalData = {
        ...this.currentPatientMedicalData,
        json: {
          ...this.medicalDataFields,
          pregnantLastUpdated: newDate
        }
      };
    },
    async updateMedicalData() {
      const medicalDataFields = keys(this.patientMedicalData).map(key => {
        const value = get(this.patientMedicalData, `${key}.value`, this.patientMedicalData[key]);

        return [key, value];
      });

      const { allergiesDr } = this.patientMedicalData;
      const patientAllergies = allergiesDr ? allergiesDr.map(({ value }) => value) : null;
      const medicalData = fromPairs(medicalDataFields);

      const medicalDataFieldsJSON = {
        ...medicalData,
        allergiesDr: patientAllergies
      };

      const updatedMedicalData = {
        ...this.currentPatientMedicalData,
        json: medicalDataFieldsJSON
      };

      try {
        const { data: currentMedicalData } = await updatePatientMedicalData(
          this.currentPatientId,
          updatedMedicalData.id,
          updatedMedicalData
        );

        if (!currentMedicalData.id) {
          return;
        }

        this.currentPatientMedicalData = currentMedicalData;
      } catch (error) {
        return error;
      }
    },
    updateTextAreaSize() {
      autosize.update([...this.$refs.textareas]);
    },
    readyToScroll() {
      this.$emit('ready-to-scroll');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/helpers/colors';
@import '../../../../assets/scss/common/buttons';

.medical-data-tab {
  &__header-title {
    color: $main-title-color;
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
  }
}

.data-container {
  display: flex;
  width: 100%;
  &__column {
    display: flex;
    flex-direction: column;
    width: calc(50% - 20px);
    max-width: 450px;
    margin-right: 20px;
    &:last-child {
      margin-right: 0;
    }
  }
}

.medical-data-textarea {
  font-size: 16px;
  line-height: 19px;
}

.medical-data-fields {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.field-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;

  &__title {
    flex: 1;
    margin-bottom: 5px;
    min-height: 19px;
    small {
      font-size: 13px;
      line-height: 19px;
      color: $text-color;
    }
  }

  .select-container {
    display: flex;
    max-width: 200px;
  }

  &.column {
    align-items: flex-start;
    flex-direction: column;

    &__title {
      flex: 1 1 auto;
      margin-bottom: 5px;
    }

    .select-container {
      flex: 1 1 auto;
      width: 100%;
      max-width: 100%;
    }
  }
}

.textarea {
  width: 100%;
  height: 36px;
  max-height: 100%;
  min-height: 15px;
  box-sizing: border-box;
  overflow-y: visible;
  overflow-x: hidden;
  resize: none;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: none;
  padding: 9px 25px 9px 10px;
  outline: none;
  cursor: pointer;
  color: $main-title-color;

  &:focus,
  &:hover {
    background-color: white;
  }

  &:focus {
    border: 1px solid $light-blue;
  }

  &:hover {
    background-image: url('~@/assets/images/dashboard/icons/pencil-icon.svg');
    background-size: 13px 13px;
    background-repeat: no-repeat;
    background-position: top 12px right 10px;
  }

  &::placeholder {
    color: $silver-color;
  }
}

@media (max-width: 991px) {
  .field-container__title {
    max-width: 55%;
  }
}

@media (max-width: 767px) {
  .data-container {
    margin-top: 46px;
    padding: 5px 15px 15px 15px;

    &__column {
      margin-right: 0;
      max-width: none;
      width: 100%;
    }

    .textarea {
      background-color: white;
      border-radius: 5px;
    }
  }

  .medical-data-fields {
    flex-direction: column;
  }
}
</style>
