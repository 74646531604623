<template>
  <modal
    name="paid-checkbox-confirmation-modal"
    :classes="['modal-window']"
    transition="scale"
    :width="modalWidth"
    height="auto"
    @before-open="beforeOpen"
  >
    <div class="modal-container">
      <div class="content flex-column--align-center">
        <div class="content__question">
          {{ $t('dashboard.treatmentsHistory.loyaltyProgram.markThisTreatmentPaid') }}
        </div>
        <div class="content__description">
          {{ $t('dashboard.treatmentsHistory.loyaltyProgram.pleaseCheck') }}
        </div>
        <div class="content__buttons buttons flex--center--center">
          <button class="cancel-button mr20" @click="chooseCancel">
            {{ $t('dashboard.action.cancel') }}
          </button>
          <button class="buttons__yes rounded-button-white" @click="chooseYes">
            {{ $t('dashboard.treatmentsHistory.loyaltyProgram.markAsPaid') }}
          </button>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import { UNSAVED_CHANGES_INPUTS } from '@/modules/dashboard/api/constants';
import { isMobileDevice } from '@/modules/dashboard/utils';

export default {
  name: 'PaidCheckboxConfirmationModal',
  data() {
    return {
      answerResolver: null
    };
  },
  computed: {
    modalWidth() {
      return isMobileDevice() ? 300 : 500;
    }
  },
  methods: {
    beforeOpen({ params: { answerResolver } }) {
      this.answerResolver = answerResolver;
    },
    chooseCancel() {
      this.answerResolver(UNSAVED_CHANGES_INPUTS.CANCEL);
      this.closeModal();
    },
    chooseYes() {
      this.answerResolver(UNSAVED_CHANGES_INPUTS.YES);
      this.closeModal();
    },
    closeModal() {
      this.$modal.hide('paid-checkbox-confirmation-modal');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../assets/scss/common/buttons';
@import '../../../../../assets/scss/helpers/colors';

.content {
  padding: 30px 21px;

  &__description,
  &__question {
    font-weight: 300;
    color: $text-color;
  }

  &__question {
    margin: 15px 0;

    font-size: 15px;
    line-height: 17px;
  }

  &__description {
    width: 300px;
    margin-bottom: 30px;
    padding: 12px 15px 10px;

    font-size: 13px;
    line-height: 150%;
    text-align: center;

    border-radius: 8px;
    background: $beige;
  }
}

.buttons__yes {
  min-width: 100px;
}

@media (max-width: 767px) {
  .content {
    &__description {
      width: auto;
    }
  }
}
</style>
