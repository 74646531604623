<template>
  <div class="treatment-tab__container">
    <mobile-header>
      <span slot="header-title" class="header-title">
        {{ $t('dashboard.label.treatmentHistory') }}
      </span>
    </mobile-header>
    <div v-if="hasTreatments" class="treatment-tab">
      <div class="treatment-tab__list">
        <treatment
          v-for="treatment in treatments"
          :key="treatment.id"
          :treatment="treatment"
          :treatment-diagnostic="getTreatmentDiagnostic(treatment.id)"
          :number="treatment.number"
          :is-active="isActive(treatment)"
          @click.native="activate(treatment)"
        />
      </div>
      <div class="treatment-tab__detail otherXS">
        <treatment-details
          v-if="activeTreatmentId"
          :key="activeTreatmentId"
          @create-treatment="onCreateTreatment"
        />
      </div>
    </div>
    <div v-else class="default-view flex-column--align-center">
      <div class="default-view__icon icon-treatment-history-tab-empty custom-icon"></div>
      <span class="default-view__label no-search-field">
        {{ $t('dashboard.label.noTreatmentsYet') }}
      </span>
      <span class="default-view__description no-search-field">
        <i18n path="dashboard.label.canCreateTreatment" tag="span">
          <span class="default-view__link" @click="onCreateTreatment">
            '{{ $t('dashboard.action.createTreatment') }}'
          </span>
        </i18n>
      </span>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { isEmpty } from 'lodash';

import Treatment from '@/modules/dashboard/components/treatment-history-tab/Treatment';
import TreatmentDetails from '@/modules/dashboard/components/treatment-history-tab/TreatmentDetails';
import MobileHeader from '@/modules/dashboard/components/common/MobileHeader';

import { isMobileDevice } from '@/modules/dashboard/utils';

import rootTypes from '@/store/types';
import { types as dashTypes } from '@/modules/dashboard/store/types';

import { PATIENT_TAB_NAMES } from '@/modules/dashboard/api/constants';

const FORBIDDEN_PREVIOUS_ROUTE_NAMES = ['ReviewPatientInviteTabs', 'SummaryTreatmentDetails'];

export default {
  name: 'TreatmentHistoryTab',
  components: { MobileHeader, Treatment, TreatmentDetails },
  computed: {
    ...mapGetters({
      treatments: dashTypes.getters.CURRENT_PATIENT_TREATMENTS,
      diagnostics: dashTypes.getters.CURRENT_PATIENT_DIAGNOSTICS,
      activeTreatmentId: dashTypes.getters.ACTIVE_TREATMENT_ID
    }),
    hasTreatments() {
      return !isEmpty(this.treatments);
    }
  },
  async mounted() {
    this.setLoading(true);
    await this.loadPatientTreatments();
    this.setLoading(false);

    if (isMobileDevice()) {
      return;
    }

    const isActiveTreatmentSet =
      this.activeTreatmentId && this.treatments.find(({ id }) => id === this.activeTreatmentId);

    if (isActiveTreatmentSet) {
      return;
    }

    await this.setFirstTreatmentAsActive();
  },
  destroyed() {
    this.resetActiveTreatment();
  },
  async beforeRouteLeave(to, from, next) {
    if (isMobileDevice() && FORBIDDEN_PREVIOUS_ROUTE_NAMES.includes(to.name)) {
      next({ name: 'PatientCard' });

      return;
    }

    next();
  },
  methods: {
    ...mapActions({
      setLoading: rootTypes.actions.SET_LOADING,
      setActiveTreatmentId: dashTypes.actions.SET_ACTIVE_TREATMENT_ID,
      setFirstTreatmentAsActive: dashTypes.actions.SET_FIRST_TREATMENT_AS_ACTIVE,
      resetActiveTreatment: dashTypes.actions.RESET_ACTIVE_TREATMENT,
      setActivePatientTab: dashTypes.actions.SET_ACTIVE_PATIENT_TAB,
      loadPatientTreatments: dashTypes.actions.LOAD_CURRENT_PATIENT_TREATMENTS
    }),
    getTreatmentDiagnostic(treatmentId) {
      const currentTreatment = this.treatments.find(({ id }) => id === treatmentId);
      const treatmentDiagnostic = this.diagnostics.find(
        ({ id }) => id === currentTreatment.diagnosticId
      );

      return treatmentDiagnostic || {};
    },
    isActive({ id }) {
      return this.activeTreatmentId === id;
    },
    async activate({ id }) {
      await this.setActiveTreatmentId(id);

      if (isMobileDevice()) {
        this.$router.push({
          name: 'TreatmentDetails',
          params: {
            treatmentId: id
          }
        });
      }
    },
    onCreateTreatment() {
      if (isMobileDevice()) {
        this.$router.push({ name: 'CreateTreatment' });

        return;
      }

      this.setActivePatientTab(PATIENT_TAB_NAMES.CREATE_TREATMENT);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/helpers/colors';
@import '../../../../assets/scss/common/buttons';
@import '../../../../assets/css/common/icons.css';

.header-title {
  color: $main-title-color;
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
}

.default-view {
  background-color: $background-darker;

  font-size: 15px;
  line-height: 17px;
  color: $grey-color;

  padding: 60px 0 140px 0;

  &__label {
    margin: 40px 0 20px 0;
  }

  &__description {
    font-size: 11px;
    max-width: 200px;
    text-align: center;
  }

  &__link {
    color: $orange-color;
    text-decoration: underline;
    cursor: pointer;
  }
}

.treatment-tab {
  display: flex;

  &__list {
    width: 50%;
  }

  &__detail {
    width: 50%;
    min-width: 467px;
  }
}

@media (max-width: 767px) {
  .default-view {
    margin-top: 46px;
    height: calc(100vh - 46px);
  }

  .treatment-tab {
    margin-top: 46px;
    padding-top: 10px;

    &__container {
      height: 100vh;
      display: flex;
      flex-direction: column;
    }

    &__list {
      width: 100%;
    }
  }
}
</style>
