<template>
  <div v-if="directionsOfUse" class="directions-of-use">
    <summary-direction-of-use-tab
      class="directions-of-use__summary"
      :directions-of-use="directionsOfUse"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import SummaryDirectionOfUseTab from '@/modules/dashboard/components/create-treatment-tab/SummaryDirectionsOfUseTab';

import { getTreatmentDirectionsOfUse } from '@/modules/dashboard/api';

import { getParsedHowToApply } from '@/modules/dashboard/utils/create-treatment-utils';

import rootTypes from '@/store/types';
import { types as dashTypes } from '@/modules/dashboard/store/types';

export default {
  name: 'DirectionsOfUseTab',
  components: { SummaryDirectionOfUseTab },
  props: {
    treatment: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      directionsOfUse: null
    };
  },
  computed: {
    ...mapGetters({
      doctorLocale: rootTypes.getters.GET_USER_LANGUAGE,
      currentPatientId: dashTypes.getters.CURRENT_PATIENT_ID
    })
  },
  async mounted() {
    try {
      this.setLoading(true);

      const { data: directionsOfUse } = await getTreatmentDirectionsOfUse({
        patientId: this.currentPatientId,
        treatmentId: this.treatment.id,
        language: this.doctorLocale
      });

      this.setLoading(false);

      this.directionsOfUse = {
        ...directionsOfUse,
        howToApply: getParsedHowToApply(directionsOfUse)
      };
    } catch (error) {
      this.addRequestError('Cannot load directions of use');
    }
  },
  methods: {
    ...mapActions({
      setLoading: rootTypes.actions.SET_LOADING,
      addRequestError: dashTypes.actions.ADD_ERROR
    })
  }
};
</script>

<style lang="scss" scoped>
.directions-of-use {
  & &__summary {
    padding-top: 0;
  }
}

@media (max-width: 767px) {
  .directions-of-use {
    & &__summary {
      max-height: none;
    }
  }
}
</style>
