<template>
  <modal
    name="delete-treatment-modal"
    :classes="['modal-window']"
    transition="scale"
    adaptive
    scrollable
    :width="300"
    height="auto"
  >
    <div class="modal-container">
      <div class="modal-window-content flex-column">
        <p class="title">
          {{ $t('dashboard.label.deleteTreatment') }}
        </p>
        <div class="buttons-group">
          <a class="cancel-button" @click="cancel">
            {{ $t('dashboard.action.cancel') }}
          </a>
          <button class="rounded-button-white" @click="deleteTreatment">
            {{ $t('dashboard.action.yesDelete') }}
          </button>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapActions } from 'vuex';

import { deleteTreatment } from '@/modules/dashboard/api';

import { isMobileDevice } from '@/modules/dashboard/utils';

import rootTypes from '@/store/types';
import { types as dashTypes } from '@/modules/dashboard/store/types';

export default {
  name: 'DeleteTreatmentModal',
  props: {
    patientId: {
      type: Number,
      required: true
    },
    treatmentId: {
      type: Number,
      required: true
    }
  },
  methods: {
    ...mapActions({
      setLoading: rootTypes.actions.SET_LOADING,
      loadPatient: dashTypes.actions.LOAD_PATIENT,
      loadPatientTreatments: dashTypes.actions.LOAD_CURRENT_PATIENT_TREATMENTS,
      setFirstTreatmentAsActive: dashTypes.actions.SET_FIRST_TREATMENT_AS_ACTIVE
    }),
    cancel() {
      this.hideModal();
    },
    async deleteTreatment() {
      this.setLoading(true);

      await deleteTreatment(this.patientId, this.treatmentId);

      await this.loadPatient(this.patientId);
      await this.loadPatientTreatments();

      this.setLoading(false);

      if (isMobileDevice()) {
        this.$router.replace({
          name: 'TreatmentHistory',
          params: {
            id: this.patientId
          }
        });

        return;
      }

      this.setFirstTreatmentAsActive();
    },
    hideModal() {
      this.$modal.hide('delete-treatment-modal');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/helpers/colors';
@import '../../../../assets/scss/common/buttons';

.modal-window-content {
  align-items: center;
  width: 100%;
  padding: 20px;

  background-color: $white;
  font-family: var(--u-font-family-Gilroy);
  border-radius: 8px;
}

.title {
  margin-top: 40px;
  margin-bottom: 40px;

  font-size: 13px;
  line-height: 19px;

  color: $text-color;
}

.cancel-button {
  font-size: 15px;
  line-height: 17px;

  text-decoration: none;

  color: $cancel-text-color;
}

.rounded-button-white {
  font-size: 15px;
  line-height: 17px;
}

.buttons-group {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 30px;
}
</style>
