<template>
  <modal
    name="image-use-consent-modal"
    class="no-modal-background mobile-fullscreen-modal"
    :classes="['modal-window', 'no-mobile-padding-modal']"
    transition="scale"
    scrollable
    adaptive
    :click-to-close="false"
    width="800px"
    height="auto"
    @before-open="beforeOpen"
  >
    <div class="modal-container">
      <section class="image-use-consent__container flex-column--align-center">
        <header
          class="image-use-consent-header consent-header flex--space-between--center full-width"
        >
          <div class="flex--align-center">
            <div
              class="consent-header__arrow_icon icon-arrow-long-left custom-icon"
              @click="closeModal"
            ></div>
            <div class="vertical-divider otherXS"></div>
            <span class="consent-header__header-text">
              {{ $t('dashboard.consent.imageUseConsent') }}
            </span>
          </div>
          <div class="icon-close custom-icon" @click="closeModal"></div>
        </header>
        <div class="divider full-width"></div>
        <div class="image-use-consent flex-column justify-content--center ie-text-modal">
          <div class="image-use-consent__title">
            {{ consentsTitle }}
          </div>
          <dashboard-constraint
            id="imageUseConstraint"
            name="imageUseConstraint"
            class="image-use-consent__image-use-constraint image-constraint"
            :value="imageUse"
            @input="updateImageUse"
          >
            <span slot="constraint" class="image-constraint__label">
              {{ consentRepresentAndWarrant }}
            </span>
          </dashboard-constraint>
          <div v-if="hasImageStoreOptions" class="image-use-consent__store-image">
            <radio-button-with-label
              name="store-image"
              class="store-image-radio-button"
              :current-value="storeImageOption"
              :value="$options.storeImageOptions.STORE"
              :disabled="!imageUse"
              @input="updateStoreImage"
            >
              <span slot="label" class="store-image-radio-button__label">
                {{ $t('dashboard.consent.imageUse.storeImage') }}
              </span>
            </radio-button-with-label>
            <radio-button-with-label
              name="store-image"
              class="not-store-image-radio-button"
              :current-value="storeImageOption"
              :value="$options.storeImageOptions.DO_NOT_STORE"
              :disabled="!imageUse"
              @input="updateStoreImage"
            >
              <span slot="label" class="store-image-radio-button__label">
                {{ $t('dashboard.consent.imageUse.doNotStoreImage') }}
              </span>
            </radio-button-with-label>
          </div>
          <div
            class="image-use-consent__title"
            :class="{
              'image-use-consent__title--disabled': isClinicResearchConstraintDisabled,
              pt5: !hasImageStoreOptions
            }"
          >
            {{ $t('dashboard.consent.photoUsageByUniverskin') }}
          </div>
          <dashboard-constraint
            id="clinicResearchConstraint"
            v-model="clinicResearch"
            name="clinicResearchConstraint"
            class="image-constraint"
            :disabled="isClinicResearchConstraintDisabled"
          >
            <span slot="constraint" class="image-constraint__label">
              {{ $t('dashboard.consent.photoUsageByUniverskinRepresentAndWarrant') }}
            </span>
          </dashboard-constraint>
        </div>
        <div class="divider full-width otherXS"></div>
        <div class="image-use-consent__footer full-width flex--center--center">
          <button class="rounded-button-white" :disabled="!imageUse" @click="resolveConsents">
            {{ $t('dashboard.action.continue') }}
          </button>
        </div>
      </section>
    </div>
  </modal>
</template>

<script>
import RadioButtonWithLabel from '@/modules/dashboard/components/dashboard/common/RadioButtonWithLabel';
import DashboardConstraint from '@/modules/dashboard/components/dashboard/common/DashboardConstraint';

import { STORE_IMAGE_OPTIONS } from '@/modules/dashboard/api/constants';

export default {
  name: 'ImageUseConsentModal',
  components: { DashboardConstraint, RadioButtonWithLabel },
  storeImageOptions: STORE_IMAGE_OPTIONS,
  props: {
    hasImageStoreOptions: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      imageUse: false,
      storeImageOption: '',
      clinicResearch: false,
      consentsResolver: null
    };
  },
  computed: {
    consentsTitle() {
      return this.$t(
        `dashboard.consent.${
          this.hasImageStoreOptions ? 'imageUseForSkinEvaluation' : 'imageCaptureStorageUse'
        }`
      );
    },
    consentRepresentAndWarrant() {
      return this.$t(
        `dashboard.consent.${
          this.hasImageStoreOptions
            ? 'imageUse.representAndWarrant'
            : 'imageCaptureStorageUseRepresentAndWarrant'
        }`
      );
    },
    isClinicResearchConstraintDisabled() {
      return !this.imageUse || this.storeImageOption === STORE_IMAGE_OPTIONS.DO_NOT_STORE;
    }
  },
  methods: {
    beforeOpen({ params: { consentsResolver } }) {
      this.consentsResolver = consentsResolver;
    },
    closeModal() {
      this.consentsResolver({});

      this.hideModal();
    },
    hideModal() {
      this.$modal.hide('image-use-consent-modal');
    },
    updateImageUse(imageUse) {
      this.imageUse = imageUse;

      this.storeImageOption = imageUse ? STORE_IMAGE_OPTIONS.STORE : '';

      if (!imageUse) {
        this.clinicResearch = false;
      }
    },
    updateStoreImage(storeImageOption) {
      this.storeImageOption = storeImageOption;

      if (storeImageOption === STORE_IMAGE_OPTIONS.DO_NOT_STORE) {
        this.clinicResearch = false;
      }
    },
    resolveConsents() {
      this.consentsResolver({
        imageUse: this.imageUse,
        storeImageOption: this.storeImageOption,
        clinicResearch: this.clinicResearch
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../../assets/scss/helpers/colors';
@import '../../../../../../assets/scss/common/buttons';
@import '../../../../../../assets/css/common/icons';

.image-use-consent-header {
  height: 50px;
  padding: 0 20px;
}

.vertical-divider {
  height: 50px;
  margin: 0 15px;
}

.icon-close {
  padding: 10px;
  background-size: 12px;
}

.consent-header {
  &__header-text {
    font-size: 15px;
    line-height: 17px;

    color: $text-color;
  }
}

.image-use-consent {
  padding: 0 60px;
  flex-grow: 1;

  &__container {
    height: 700px;
    text-align: left;
  }

  &__title {
    font-size: 15px;
    font-weight: 500;
    line-height: 19px;
    color: $text-color;

    margin-bottom: 25px;

    &--disabled {
      opacity: 0.5;
    }
  }

  &__image-use-constraint {
    margin-bottom: 25px;
  }

  &__store-image {
    margin-bottom: 43px;
  }

  &__footer {
    height: 70px;
  }
}

.image-constraint {
  &__label {
    font-size: 15px;
    line-height: 17px;

    color: $text-color;
  }
}

.store-image-radio-button {
  margin-bottom: 28px;

  &__label {
    font-size: 13px;
    line-height: 15px;
    letter-spacing: normal;
    color: $text-color;

    vertical-align: top;
  }
}

@media (max-width: 767px) {
  .modal-container {
    min-height: 100vh;
    height: auto;

    border-radius: 0;
  }

  .image-use-consent {
    padding: 30px 20px;

    &__container {
      height: auto;

      padding-bottom: 200px;
    }
  }

  .consent-header {
    &__arrow_icon {
      margin-right: 10px;
    }
  }

  .image-use-consent-header {
    padding-left: 15px;
  }

  .image-constraint {
    &__label {
      font-size: 13px;
      line-height: 15px;
    }
  }
}
</style>
