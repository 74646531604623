<template>
  <timeline-section
    class="treatment-created-section treatment-timeline-tabs-section"
    :is-active="isActive"
  >
    <timeline-section-header
      slot="header"
      slot-scope="{ isShown }"
      :timeline-date="treatment.creationDate"
      :timeline-header="$t('dashboard.label.treatmentCreated')"
      :is-shown="isShown"
      :can-open-section="canOpenSection"
    />
    <div slot="content" class="treatment-timeline-tabs-section__content">
      <treatment-timeline-tabs
        class="treatment-timeline-tabs-section__tabs"
        :tabs="$options.treatmentCreateStatusTabs"
        :active-tab="activeTreatmentTimelineTab"
        custom-tabs
        tabs-align="center"
        is-label-key
        @select-tab="selectTreatmentTimelineTab"
      >
        <div slot="tab-content">
          <component :is="activeTreatmentTimelineTab" :treatment="treatment"></component>
        </div>
      </treatment-timeline-tabs>
    </div>
  </timeline-section>
</template>

<script>
import TabInfo from '@/modules/dashboard/components/common/TabInfo';

import TimelineSection from '@/modules/dashboard/components/treatment-history-tab/treatment-details/treatment-timeline-sections/TimelineSection';
import TimelineSectionHeader from '@/modules/dashboard/components/treatment-history-tab/treatment-details/treatment-timeline-sections/TimelineSectionHeader';
import DirectionsOfUseTab from '@/modules/dashboard/components/treatment-history-tab/DirectionsOfUseTab';
import TreatmentTimelineTabs from '@/modules/dashboard/components/treatment-history-tab/treatment-details/treatment-timeline-sections/TreatmentTimelineTabs';
import TreatmentCompositionTab from '@/modules/dashboard/components/treatment-history-tab/TreatmentCompositionTab';

import {
  TIMELINE_SECTION_DEFAULT_PROPS,
  TREATMENT_TIMELINE_TAB_NAMES
} from '@/modules/dashboard/api/constants';

const TREATMENT_CREATED_STATUS_TABS = [
  new TabInfo(
    TREATMENT_TIMELINE_TAB_NAMES.TREATMENT_COMPOSITION,
    'dashboard.summary.treatmentComposition'
  ),
  new TabInfo(TREATMENT_TIMELINE_TAB_NAMES.DIRECTIONS_OF_USE, 'dashboard.summary.directionsOfUse')
];

export default {
  name: 'TreatmentCreatedTimelineSection',
  components: {
    TreatmentCompositionTab,
    TreatmentTimelineTabs,
    DirectionsOfUseTab,
    TimelineSectionHeader,
    TimelineSection
  },
  treatmentCreateStatusTabs: TREATMENT_CREATED_STATUS_TABS,
  props: {
    ...TIMELINE_SECTION_DEFAULT_PROPS
  },
  data() {
    return {
      activeTreatmentTimelineTab: TREATMENT_TIMELINE_TAB_NAMES.TREATMENT_COMPOSITION
    };
  },
  methods: {
    selectTreatmentTimelineTab(tabName) {
      this.activeTreatmentTimelineTab = tabName;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../../assets/scss/treatment-history/timeline-section';
</style>
