import { render, staticRenderFns } from "./Anomaly.vue?vue&type=template&id=539df180&scoped=true&"
import script from "./Anomaly.vue?vue&type=script&lang=js&"
export * from "./Anomaly.vue?vue&type=script&lang=js&"
import style0 from "./Anomaly.vue?vue&type=style&index=0&id=539df180&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "539df180",
  null
  
)

export default component.exports