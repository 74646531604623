<template>
  <div class="diagnostic-details">
    <diagnostic-header
      :diagnostic="diagnostic"
      :photo="photo"
      :validated="diagnostic.validated"
    ></diagnostic-header>
    <div class="diagnostic-details__anomalies flex-column">
      <ul class="anomaly-list">
        <anomaly
          v-for="anomaly in anomalies"
          :key="`anomaly${anomaly.index}`"
          :anomaly="anomaly"
          :active="isActive(anomaly)"
        ></anomaly>
        <anomaly
          v-for="validation in validations"
          :key="`validation${validation.index}`"
          :anomaly="validation"
        ></anomaly>
      </ul>
    </div>
    <div class="diagnostic-details__controls flex--space-between--center">
      <button class="rounded-button-white diagnostic-details__next-button" @click="validate">
        {{ $t('dashboard.diagnostic.nextValidation') }}
      </button>

      <div
        class="diagnostic-details__footer-button diagnostic-details__footer-button_delete no-search-field"
      >
        <span
          v-tooltip="{
            content: $t('dashboard.label.diagnosticCannotBeDeleted'),
            classes: 'delete-diagnostic-tooltip custom-tooltip',
            placement: tooltipPlacement,
            trigger: tooltipTrigger
          }"
          :class="{ none: !hasTreatment }"
        >
          <span class="flex--center--center">
            <span class="far fa-trash-alt"></span>
            <span class="onlyXS diagnostic-details__footer-button_delete__label">
              {{ $t('dashboard.action.deleteDiagnostic') }}
            </span>
          </span>
        </span>
        <span
          class="flex--center--center"
          :class="{ none: hasTreatment }"
          @click="showDeleteDiagnosticModal"
        >
          <span class="flex--center--center">
            <span class="far fa-trash-alt"></span>
            <span class="onlyXS diagnostic-details__footer-button_delete__label">
              {{ $t('dashboard.action.deleteDiagnostic') }}
            </span>
          </span>
        </span>
        <delete-diagnostic-modal
          :patient-id="currentPatient.id"
          :diagnostic-id="diagnostic.id"
        ></delete-diagnostic-modal>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { first } from 'lodash';

import DeleteDiagnosticModal from '@/modules/dashboard/components/diagnostic-tab/DeleteDiagnosticModal';
import Anomaly from '@/modules/dashboard/components/diagnostic-tab/Anomaly';
import DiagnosticHeader from '@/modules/dashboard/components/diagnostic-tab/DiagnosticHeader';

import { isMobileDevice } from '@/modules/dashboard/utils';

import { types as dashTypes } from '@/modules/dashboard/store/types';
import { PHOTO_VUE_TYPES } from '@/modules/dashboard/api/constants';

export default {
  name: 'DiagnosticIssues',
  components: { DiagnosticHeader, Anomaly, DeleteDiagnosticModal },
  props: {
    diagnostic: {
      type: Object,
      required: true
    },
    photo: PHOTO_VUE_TYPES
  },
  computed: {
    ...mapGetters({
      currentPatient: dashTypes.getters.CURRENT_PATIENT
    }),
    anomalies() {
      return this.diagnostic.anomalies.filter(({ present }) => present);
    },
    validations() {
      return this.diagnostic.validations.filter(({ present }) => present);
    },
    tooltipPlacement() {
      return isMobileDevice() ? 'top' : 'left';
    },
    tooltipTrigger() {
      return isMobileDevice() ? 'click' : 'hover';
    },

    hasTreatment() {
      return this.diagnostic.hasTreatment;
    }
  },
  methods: {
    ...mapActions({
      validateDiagnostic: dashTypes.actions.VALIDATE_DIAGNOSTIC
    }),
    async validate() {
      if (await this.$validator.validateAll()) {
        await this.validateDiagnostic({ diagnostic: this.diagnostic });
      }
    },
    isActive({ index }) {
      return first(this.anomalies).index === index;
    },
    showDeleteDiagnosticModal() {
      this.$modal.show('delete-diagnostic-modal');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/helpers/colors';
@import '../../../../assets/scss/common/buttons';

.diagnostic-details {
  width: 100%;
  min-width: 320px;
  padding: 15px 30px 30px;
  background: $white;
  border-radius: 8px;

  &__footer-button {
    margin-right: 20px;
    &_delete {
      margin-left: auto;
      margin-right: 0;
      outline: none;

      cursor: pointer;

      span {
        color: $grey-color;
      }
    }
  }
}

.anomaly-list {
  padding: 0;
  margin-bottom: 30px;
  list-style: none;
}

@media (max-width: 767px) {
  .diagnostic-details {
    min-width: auto;
    padding: 0;
    background: transparent;
    &__next-button {
      padding: 7px 20px;
      margin-left: 15px;
    }

    &__footer-button {
      &_delete {
        margin-right: 20px;
        font-size: 13px;
        line-height: 15px;
      }

      .fa-trash-alt {
        margin-right: 5px;
      }
    }
  }
}
</style>
