<template>
  <div class="face-icon flex-column--align-center">
    <div class="custom-icon cursor-default" :class="iconClass"></div>
    <div
      class="face-icon__correct custom-icon cursor-default"
      :class="{ 'icon-green-check-bold': correct, 'icon-red-cross-bold': !correct }"
    ></div>
    <span class="face-icon__label">{{ label }}</span>
  </div>
</template>

<script>
import { GENDER } from '@/modules/questionnaire/api/constants';

export default {
  name: 'FaceIconWithLabel',
  props: {
    icon: {
      type: String,
      required: true
    },
    gender: {
      type: String,
      default: GENDER.FEMALE
    },
    correct: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    }
  },
  computed: {
    iconClass() {
      return `icon-add-photo-${this.icon}-${this.gender}`;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../../assets/css/common/icons.css';
@import '../../../../../../assets/scss/helpers/colors';

.icon-add-photo-first {
  margin-top: 13px;
}

.face-icon {
  width: 86px;
  margin: 0 10px;

  cursor: default;

  &__correct {
    margin: 15px 0 7px 0;
  }

  &__label {
    max-width: 100%;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    font-style: italic;
    color: $grey-color;
  }
}

.icon-green-check-bold {
  margin-top: 30px;
}

@media (max-width: 767px) {
  .face-icon {
    width: 76px;
    margin: 0 5px;

    &__correct {
      margin: 8px 0 5px 0;
    }

    &__label {
      font-size: 10px;
      line-height: 12px;
    }
  }

  .icon-green-check-bold {
    margin-top: 20px;
  }
}
</style>
