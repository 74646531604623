<template>
  <div class="photo-analysis">
    <component
      :is="headerComponent"
      :class="{ 'modal-header-with-previous': isHeaderWithBackButton }"
      :previous-modal-name="previousModalName"
      :previous-modal-params="$options.previousModalParams"
      current-modal-name="photo-analysis-add-photo-modal"
      return-to-opened-patient
    >
      <span slot="header-title" class="photo-analysis__title">
        {{ $t('dashboard.label.addPhoto') }}
      </span>
    </component>
    <main class="add-photo main-text flex-column--align-center">
      <span class="add-photo__hint hint-text ie-text-modal">
        {{ $t('dashboard.label.bestSkinDiagnostic') }}
      </span>
      <face-icons class="add-photo__face-icons" />
      <div class="add-photo__divider divider otherXS"></div>
      <upload-photo
        is-photo-analysis
        @handle-photo-validation-error="hideModal"
        @photo-uploaded="handlePhotoUpload"
      />
    </main>
  </div>
</template>

<script>
import ModalHeaderWithPrevious from '@/modules/dashboard/components/dashboard/common/ModalHeaderWithPrevious';
import ModalHeader from '@/modules/dashboard/components/dashboard/common/ModalHeader';
import FaceIcons from '@/modules/dashboard/components/dashboard/create-treatment/photo-analysis/FaceIcons';
import UploadPhoto from '@/modules/dashboard/components/dashboard/common/UploadPhoto';

import { handleRoutingInfo } from '@/modules/dashboard/utils';

import {
  NEW_TREATMENT_FLOW_TYPES,
  ANALYZING_ROUTING_INFO
} from '@/modules/dashboard/api/constants';

const PREVIOUS_MODAL_PARAMS = {
  newTreatmentFlowType: NEW_TREATMENT_FLOW_TYPES.TREATMENT_FROM_PHOTO_ANALYSIS
};

export default {
  name: 'PhotoAnalysisAddPhoto',
  components: {
    UploadPhoto,
    FaceIcons,
    ModalHeaderWithPrevious,
    ModalHeader
  },
  previousModalParams: PREVIOUS_MODAL_PARAMS,
  props: {
    isHeaderWithBackButton: {
      type: Boolean,
      default: false
    },
    previousModalName: {
      type: String,
      default: ''
    }
  },
  computed: {
    headerComponent() {
      return this.isHeaderWithBackButton ? ModalHeaderWithPrevious : ModalHeader;
    }
  },
  methods: {
    handlePhotoUpload() {
      handleRoutingInfo(
        { ...ANALYZING_ROUTING_INFO, params: { canUploadAnother: true } },
        this.$router,
        this.$modal
      );

      this.hideModal();
    },
    hideModal() {
      this.$modal.hide('photo-analysis-add-photo-modal');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../../assets/scss/helpers/colors';
@import '../../../../../../assets/scss/common/text';
@import '../../../../../../assets/scss/modals/modal-header-with-previous-shared';

.photo-analysis {
  &__title {
    font-size: 15px;
    line-height: 17px;
    color: $text-color;
  }
}

.add-photo {
  padding: 20px 40px 55px 40px;

  &__hint {
    margin-bottom: 50px;

    text-align: center;
  }

  &__divider {
    margin: 55px 0;
    width: 85%;
  }
}

@media (max-width: 767px) {
  .photo-analysis {
    background-color: white;
    min-height: 100vh;

    &__title {
      font-size: 13px;
      line-height: 15px;
    }
  }

  .add-photo {
    padding: 10px 30px 0 30px;

    &__hint {
      padding: 0 10px;
      margin-bottom: 30px;
    }

    &__face-icons {
      margin-bottom: 40px;
    }
  }
}
</style>
