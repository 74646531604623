<template>
  <timeline-section
    class="offer-created-section treatment-timeline-tabs-section"
    :is-active="isActive"
  >
    <timeline-section-header
      slot="header"
      slot-scope="{ isShown }"
      :timeline-date="treatment.creationDate"
      :timeline-header="timelineTitle"
      :is-shown="isShown"
      :can-open-section="canOpenSection"
    />
    <div slot="content" class="treatment-timeline-tabs-section__content">
      <treatment-timeline-tabs
        class="treatment-timeline-tabs-section__tabs"
        :tabs="$options.offerCreatedTimelineTabs"
        :active-tab="activeOfferTimelineTab"
        is-label-key
        custom-tabs
        tabs-align="center"
        @select-tab="selectOfferTimelineTab"
      >
        <div slot="tab-content">
          <component
            :is="activeOfferTimelineTab"
            :treatment="treatment"
            display-initial-treatment
            should-calculate-composition-total
          ></component>
        </div>
      </treatment-timeline-tabs>
      <div class="offer-created-section__footer flex--space-between">
        <button
          v-if="isTreatmentOfferCreated"
          class="offer-created-section__resend-button rounded-button-gray block"
          @click="onResendOfferClick"
        >
          {{ $t('dashboard.action.resendOffer') }}
        </button>
        <div
          v-if="hasTreatmentOfferLink"
          class="offer-created-section__link flex--space-between--center"
        >
          <a :href="treatment.offerLink" target="_blank" class="copy-link">
            {{ $t('dashboard.action.offerLink') }}
          </a>
          <copy-to-clipboard :text-to-copy="treatment.offerLink">
            <div slot="copy-button">
              <div class="icon-copy custom-icon"></div>
            </div>
          </copy-to-clipboard>
        </div>
      </div>

      <review-send-offer-message-modal />
      <finish-send-offer-modal />
    </div>
  </timeline-section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import TabInfo from '@/modules/dashboard/components/common/TabInfo';
import CopyToClipboard from '@/modules/dashboard/components/common/CopyToClipboard';
import TimelineSection from '@/modules/dashboard/components/treatment-history-tab/treatment-details/treatment-timeline-sections/TimelineSection';
import TimelineSectionHeader from '@/modules/dashboard/components/treatment-history-tab/treatment-details/treatment-timeline-sections/TimelineSectionHeader';
import TreatmentTimelineTabs from '@/modules/dashboard/components/treatment-history-tab/treatment-details/treatment-timeline-sections/TreatmentTimelineTabs';
import TreatmentCompositionTab from '@/modules/dashboard/components/treatment-history-tab/TreatmentCompositionTab';
import ReviewSendOfferMessageModal from '@/modules/dashboard/components/create-treatment-tab/send-offer/ReviewSendOfferMessageModal';
import FinishSendOfferModal from '@/modules/dashboard/components/create-treatment-tab/send-offer/FinishSendOfferModal';

import { isMobileDevice } from '@/modules/dashboard/utils';
import { isAutopilotTreatment } from '@/modules/dashboard/utils/treatment-utils';

import { types } from '@/modules/dashboard/store/types';

import {
  TIMELINE_SECTION_DEFAULT_PROPS,
  TREATMENT_ORDERS_STATUSES,
  REVIEW_TREATMENT_MESSAGE_TYPE
} from '@/modules/dashboard/api/constants';

const OFFER_COMPOSITION_TAB_NAME = 'TreatmentCompositionTab';

const OFFER_CREATED_TIMELINE_TABS = [
  new TabInfo(OFFER_COMPOSITION_TAB_NAME, 'dashboard.label.offerComposition')
];

export default {
  name: 'TreatmentOfferCreatedTimelineSection',
  components: {
    TreatmentCompositionTab,
    TreatmentTimelineTabs,
    TimelineSectionHeader,
    TimelineSection,
    ReviewSendOfferMessageModal,
    FinishSendOfferModal,
    CopyToClipboard
  },
  offerCreatedTimelineTabs: OFFER_CREATED_TIMELINE_TABS,
  props: {
    ...TIMELINE_SECTION_DEFAULT_PROPS
  },
  data() {
    return {
      activeOfferTimelineTab: OFFER_COMPOSITION_TAB_NAME
    };
  },
  computed: {
    ...mapGetters({
      currentPatientId: types.getters.CURRENT_PATIENT_ID
    }),
    timelineTitle() {
      return isAutopilotTreatment(this.treatment)
        ? 'dashboard.treatmentTag.createdByUniverskinTeam'
        : 'dashboard.label.treatmentOfferCreatedAnsSent';
    },
    isTreatmentOfferCreated() {
      return this.treatment.orderStatus === TREATMENT_ORDERS_STATUSES.TREATMENT_OFFER_CREATED;
    },
    hasTreatmentOfferLink() {
      return !!this.treatment.offerLink;
    }
  },
  methods: {
    ...mapActions({
      setSummaryTreatmentId: types.actions.SET_SUMMARY_TREATMENT_ID
    }),
    selectOfferTimelineTab(tabName) {
      this.activeOfferTimelineTab = tabName;
    },
    async onResendOfferClick() {
      await this.setSummaryTreatmentId(this.treatment.id);

      if (isMobileDevice()) {
        this.$router.push({
          name: 'ReviewPatientInviteTabs',
          params: {
            id: this.currentPatientId,
            templateType: REVIEW_TREATMENT_MESSAGE_TYPE.RESEND_OFFER
          }
        });

        return;
      }

      this.$modal.show('review-send-offer-message-modal', {
        isModalWithPrevious: false,
        templateType: REVIEW_TREATMENT_MESSAGE_TYPE.RESEND_OFFER
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../../assets/scss/common/buttons';
@import '../../../../../../assets/css/common/icons.css';
@import '../../../../../../assets/scss/treatment-history/timeline-section';
@import '../../../../../../assets/scss/helpers/colors';

.offer-created-section {
  &__footer {
    margin-top: 30px;
  }

  &__resend-button {
    margin: 0;
  }

  &__link {
    &:only-child {
      margin-left: auto;
    }
  }
}

.copy-link {
  margin-right: 10px;
  font-size: 15px;
  line-height: 18px;
  color: $links-color;
  text-decoration: underline;
}

@media (max-width: 767px) {
  .copy-link {
    font-size: 13px;
  }
}
</style>
