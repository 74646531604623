<template>
  <div v-if="isWebCameraCompatible" class="web-camera flex-column--align-center">
    <span class="web-camera__hint hint-text">{{ $t('info.webcamUpload') }}</span>
    <button class="rounded-button-white" @click="showWebCameraModal">
      {{ $t('dashboard.button.takePhoto') }}
    </button>
    <web-camera-modal @picture-selected="uploadPhoto" />
  </div>
</template>

<script>
import WebCameraModal from '@/modules/dashboard/components/dashboard/common/WebCameraModal';

export default {
  name: 'WebCamera',
  components: { WebCameraModal },
  computed: {
    isWebCameraCompatible() {
      return !!navigator.mediaDevices && !!navigator.mediaDevices.getUserMedia;
    }
  },
  methods: {
    async showWebCameraModal() {
      this.$emit('activate-web-camera');
      this.$modal.show('web-camera-modal');
    },
    uploadPhoto(files) {
      this.$emit('files-uploaded', files);
      this.$modal.hide('web-camera-modal');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../assets/scss/common/buttons';
@import '../../../../../assets/scss/common/text';

.web-camera {
  &__hint {
    margin-bottom: 35px;
  }
}
</style>
