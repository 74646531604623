<template>
  <timeline-section
    class="treatment-paid-online-section treatment-timeline-tabs-section"
    :is-active="isActive"
  >
    <timeline-section-header
      slot="header"
      slot-scope="{ isShown }"
      :timeline-date="treatment.paymentDate"
      :is-shown="isShown"
      :can-open-section="canOpenSection"
    >
      <span slot="header-title" class="timeline-header-title">
        {{ $t('dashboard.label.treatmentPaidOnline') }}
        <span v-if="isTreatmentModified" class="timeline-header-title__modified">
          {{ $t('dashboard.label.modified') }}
        </span>
      </span>
    </timeline-section-header>
    <div slot="content" class="treatment-timeline-tabs-section__content">
      <treatment-timeline-tabs
        class="treatment-timeline-tabs-section__tabs"
        :tabs="$options.treatmentPaidOnlineTimelineTabs"
        :active-tab="activeTreatmentPaidTimelineTab"
        custom-tabs
        is-label-key
        tabs-align="center"
        @select-tab="selectTreatmentPaidTimelineTab"
      >
        <div slot="tab-content">
          <component
            :is="activeTreatmentPaidTimelineTab"
            :treatment="treatment"
            display-multipliers
          ></component>
        </div>
      </treatment-timeline-tabs>
    </div>
  </timeline-section>
</template>

<script>
import TabInfo from '@/modules/dashboard/components/common/TabInfo';
import TimelineSection from '@/modules/dashboard/components/treatment-history-tab/treatment-details/treatment-timeline-sections/TimelineSection';
import TimelineSectionHeader from '@/modules/dashboard/components/treatment-history-tab/treatment-details/treatment-timeline-sections/TimelineSectionHeader';
import TreatmentTimelineTabs from '@/modules/dashboard/components/treatment-history-tab/treatment-details/treatment-timeline-sections/TreatmentTimelineTabs';
import TreatmentCompositionTab from '@/modules/dashboard/components/treatment-history-tab/TreatmentCompositionTab';
import DirectionsOfUseTab from '@/modules/dashboard/components/treatment-history-tab/DirectionsOfUseTab';

import {
  TIMELINE_SECTION_DEFAULT_PROPS,
  MODIFIED_TREATMENT_PRODUCT_STATUSES
} from '@/modules/dashboard/api/constants';

const TREATMENT_PAID_ONLINE_TAB_NAMES = {
  ORDER_COMPOSITION: 'TreatmentCompositionTab',
  DIRECTIONS_OF_USE: 'DirectionsOfUseTab'
};

const TREATMENT_PAID_ONLINE_TIMELINE_TABS = [
  new TabInfo(
    TREATMENT_PAID_ONLINE_TAB_NAMES.ORDER_COMPOSITION,
    'dashboard.label.orderComposition'
  ),
  new TabInfo(TREATMENT_PAID_ONLINE_TAB_NAMES.DIRECTIONS_OF_USE, 'dashboard.label.directionOfUse')
];

export default {
  name: 'TreatmentPaidOnlineTimelineSection',
  components: {
    DirectionsOfUseTab,
    TreatmentCompositionTab,
    TreatmentTimelineTabs,
    TimelineSectionHeader,
    TimelineSection
  },
  treatmentPaidOnlineTimelineTabs: TREATMENT_PAID_ONLINE_TIMELINE_TABS,
  props: {
    ...TIMELINE_SECTION_DEFAULT_PROPS
  },
  data() {
    return {
      activeTreatmentPaidTimelineTab: TREATMENT_PAID_ONLINE_TAB_NAMES.ORDER_COMPOSITION
    };
  },
  computed: {
    isTreatmentModified() {
      const {
        treatmentDetails: {
          treatFace,
          treatEyes,
          cleanse: { products: cleanseProducts },
          moisturize: { products: moisturizeProducts },
          protect: { products: protectProducts }
        }
      } = this.treatment;

      return [
        treatFace,
        treatEyes,
        ...cleanseProducts,
        ...moisturizeProducts,
        ...protectProducts
      ].some(({ status }) => MODIFIED_TREATMENT_PRODUCT_STATUSES.includes(status));
    }
  },
  methods: {
    selectTreatmentPaidTimelineTab(tabName) {
      this.activeTreatmentPaidTimelineTab = tabName;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../../assets/scss/helpers/colors';
@import '../../../../../../assets/scss/treatment-history/timeline-section';

.timeline-header-title {
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;

  color: $text-color;

  &__modified {
    color: $danube;
  }
}
</style>
