<template>
  <div class="invite-tabs-container">
    <div class="send-patient-email__header send-email-header flex--space-between--center">
      <span class="send-email-header__title">{{ $t('dashboard.label.sendAnInvite') }}</span>
      <div class="icon-close custom-icon otherXS" @click="close"></div>
      <div class="icon-close custom-icon onlyXS" @click="returnToPatientCard"></div>
    </div>
    <div class="divider noneXS"></div>
    <tabs
      class="send-patient-invite"
      :tabs="tabs"
      :active-tab="activeInviteTab"
      is-labels-are-keys
      @select-tab="selectTab"
    >
      <div
        ref="patientInviteTabsContent"
        slot="tab-content"
        class="send-patient-invite-tabs-content"
      >
        <keep-alive>
          <component
            :is="activeInviteTab"
            :patient-email-consent="emailConsent"
            :patient-sms-consent="smsConsent"
            :is-footer-visible="false"
            :should-redirect-to-dashboard="false"
            has-patient-consent
            :language="language"
            :template-errors="templateErrors"
            should-show-unsubscribe-message
            @update-email-consent="updateEmailConsent"
            @update-sms-consent="updateSmsConsent"
            @update-template-text-email="updateTemplateTextEmail"
            @update-template-text-sms="updateTemplateTextSms"
            @invite-template-sent-modal-close="onInviteTemplateSentModalClose"
            @update-language="updateLanguage"
          ></component>
        </keep-alive>
      </div>
    </tabs>
    <div class="divider noneXS"></div>
    <div class="send-patient-email__footer flex--align-center">
      <button
        class="rounded-button-orange"
        :disabled="!isPatientSubscribed"
        @click="sendEmailInvite()"
      >
        {{ $t('dashboard.action.sendEmail') }}
      </button>
      <button
        class="rounded-button-orange"
        :disabled="isSmsInviteAccepted || isSmsLimitReached"
        @click="sendSmsInvite()"
      >
        {{ $t('dashboard.action.sendSms') }}
      </button>
      <div class="flex--center--center rounded-button-orange__container"></div>
      <button
        class="rounded-button-orange"
        :disabled="!isPatientSubscribed || isSmsInviteAccepted || isSmsLimitReached"
        @click="sendBothInvite"
      >
        {{ $t('dashboard.action.sendBoth') }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import PerfectScrollbar from 'perfect-scrollbar';
import { pick, get } from 'lodash';

import TabInfo from '@/modules/dashboard/components/common/TabInfo';

import SendPatientInviteWithEmail from '@/modules/dashboard/components/patient-card/invite/SendPatientInviteWithEmail';
import SendPatientInviteWithTextMessage from '@/modules/dashboard/components/patient-card/invite/SendPatientInviteWithTextMessage';
import Tabs from '@/modules/dashboard/components/common/Tabs';

import { sendMarketingTemplateInvites, updatePatientInfo } from '@/modules/dashboard/api';
import {
  isMobileDevice,
  getTemplateErrorsFromVeeValidationErrors,
  hasTemplateError
} from '@/modules/dashboard/utils';
import { getPatientPhone } from '@/utils';

import rootTypes from '@/store/types';
import { types as dashTypes } from '@/modules/dashboard/store/types';
import {
  DEFAULT_PERFECT_SCROLLBAR_OPTIONS,
  INVITE_TAB_NAMES,
  MESSAGE_TEMPLATE_TYPES,
  UPDATE_PATIENT_FIELDS
} from '@/modules/dashboard/api/constants';

const TABS_BINDING = [
  new TabInfo(
    INVITE_TAB_NAMES.EMAIL_MESSAGE,
    'dashboard.messageEmail',
    'SendPatientInviteWithEmail'
  ),
  new TabInfo(
    INVITE_TAB_NAMES.SMS_MESSAGE,
    'dashboard.messageSms',
    'SendPatientInviteWithTextMessage'
  )
];

export default {
  name: 'SendPatientInviteTabs',
  components: {
    SendPatientInviteWithEmail,
    SendPatientInviteWithTextMessage,
    Tabs
  },
  data() {
    return {
      tabs: TABS_BINDING,
      scrollbar: null,
      isEmailListValid: true,
      emailConsent: false,
      smsConsent: false,
      marketingTemplateEmail: null,
      marketingTemplateSms: null,
      customSmsCounter: 0,
      customSmsLimit: 125,
      language: '',
      templateErrors: {}
    };
  },
  computed: {
    ...mapGetters({
      userId: rootTypes.getters.GET_USER_ID,
      activeInviteTab: dashTypes.getters.ACTIVE_INVITE_TAB,
      currentPatient: dashTypes.getters.CURRENT_PATIENT,
      patientLocale: dashTypes.getters.CURRENT_PATIENT_LOCALE
    }),
    isPatientSubscribed() {
      return get(this.currentPatient, 'marketingEmailAccepted', false);
    },
    isSmsInviteAccepted() {
      if (this.currentPatient) {
        return !this.currentPatient.smsAccepted || !this.currentPatient.phone;
      }

      return false;
    },
    patientPhone() {
      return getPatientPhone(this.currentPatient);
    },
    isSmsLimitReached() {
      return this.customSmsLimit - this.customSmsCounter <= 0;
    }
  },
  created() {
    this.language = this.patientLocale;
  },
  mounted() {
    this.scrollbar = new PerfectScrollbar(
      '.send-patient-invite-tabs-content',
      DEFAULT_PERFECT_SCROLLBAR_OPTIONS
    );
  },
  destroyed() {
    this.setActiveInviteTab(INVITE_TAB_NAMES.EMAIL_MESSAGE);
  },
  methods: {
    ...mapActions({
      setLoading: rootTypes.actions.SET_LOADING,
      setActiveInviteTab: dashTypes.actions.SET_ACTIVE_INVITE_TAB
    }),
    updateEmailConsent(emailConsent) {
      this.emailConsent = emailConsent;
    },
    updateSmsConsent(smsConsent) {
      this.smsConsent = smsConsent;
    },
    updateLanguage(language) {
      this.language = language;
    },
    updateTemplateTextEmail(marketingTemplateEmail) {
      this.marketingTemplateEmail = marketingTemplateEmail;

      this.updateSmsCounter(marketingTemplateEmail);
    },
    updateTemplateTextSms(marketingTemplateSms) {
      this.marketingTemplateSms = marketingTemplateSms;

      this.updateSmsCounter(marketingTemplateSms);
    },
    updateSmsCounter({ customSmsCounter, customSmsLimit }) {
      this.customSmsCounter = customSmsCounter;
      this.customSmsLimit = customSmsLimit;
    },
    onInviteTemplateSentModalClose() {
      if (!isMobileDevice()) {
        return;
      }

      this.$router.push({ name: 'PatientCard', params: { id: this.currentPatient.id } });
    },
    async getEmailConsentValidation() {
      if (!this.emailConsent) {
        this.setActiveInviteTab(INVITE_TAB_NAMES.EMAIL_MESSAGE);

        await this.$nextTick();

        this.scrollTabToTheTop();

        await this.$validator.validate('patientEmailConstraint');
      }

      return this.emailConsent;
    },
    async getSmsConsentValidation() {
      if (!this.smsConsent) {
        this.setActiveInviteTab(INVITE_TAB_NAMES.SMS_MESSAGE);

        await this.$nextTick();

        this.scrollTabToTheTop();

        await this.$validator.validate('patientSmsConstraint');
      }

      return this.smsConsent;
    },
    async updatePatientLanguage() {
      const patientData = {
        ...pick(this.currentPatient, UPDATE_PATIENT_FIELDS),
        locale: this.language
      };

      await updatePatientInfo(this.currentPatient.id, patientData);
    },
    async sendEmailInvite(closeModal = true) {
      await this.$validator.validate();

      this.templateErrors = getTemplateErrorsFromVeeValidationErrors(this.errors);

      if (!(await this.getEmailConsentValidation())) {
        return false;
      }

      if (hasTemplateError(this.templateErrors)) {
        return;
      }

      this.setLoading(true);

      await sendMarketingTemplateInvites(this.userId, {
        messageTemplateDto: this.marketingTemplateEmail,
        destinations: [this.currentPatient.email],
        wayOfSend: MESSAGE_TEMPLATE_TYPES.EMAIL
      });

      this.setLoading(false);

      if (closeModal) {
        await this.updatePatientLanguage();
        this.showInvitationsSentModal();
      }

      return true;
    },
    async sendSmsInvite(closeModal = true) {
      if (!(await this.getSmsConsentValidation())) {
        return false;
      }

      this.setLoading(true);

      await sendMarketingTemplateInvites(this.userId, {
        messageTemplateDto: this.marketingTemplateSms,
        destinations: [this.patientPhone],
        wayOfSend: MESSAGE_TEMPLATE_TYPES.SMS
      });

      this.setLoading(false);

      if (closeModal) {
        await this.updatePatientLanguage();
        this.showInvitationsSentModal();
      }

      return true;
    },
    showInvitationsSentModal() {
      if (!this.hasErrors) {
        this.$modal.hide('send-patient-invite-modal');
        this.$modal.show('invite-template-sent-modal');
      }
    },
    async validateEmailAndSms() {
      if (!this.emailConsent) {
        await this.getEmailConsentValidation();

        return;
      }

      await this.getSmsConsentValidation();
    },
    async sendBothInvite() {
      if (!this.emailConsent || !this.smsConsent) {
        await this.validateEmailAndSms();

        return;
      }

      const isEmailsSent = await this.sendEmailInvite(false);

      if (!isEmailsSent) {
        return;
      }

      const isSmsSent = await this.sendSmsInvite(false);

      if (!isSmsSent) {
        return;
      }

      await this.updatePatientLanguage();
      this.showInvitationsSentModal();
    },
    selectTab(selectedTabName) {
      this.setActiveInviteTab(selectedTabName);

      this.scrollTabToTheTop();
    },
    scrollTabToTheTop() {
      this.$refs.patientInviteTabsContent.scrollTop = 0;
    },
    close() {
      this.$modal.hide('send-patient-invite-modal');
    },
    returnToPatientCard() {
      this.$router.push({ name: 'PatientCard' });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../assets/scss/common/buttons';
@import '../../../../../assets/scss/helpers/colors';
@import '../../../../../assets/css/common/icons.css';
@import '~perfect-scrollbar/css/perfect-scrollbar.css';

.send-patient-email {
  font-size: 15px;
  line-height: 17px;
  color: $text-color;
  position: relative;

  &__header {
    height: 50px;
    padding: 0 20px 0 25px;
  }

  &__footer {
    padding: 16px 25px;

    .rounded-button-orange {
      margin-right: 20px;
      border: 0.75px solid $light-blue;
      box-sizing: border-box;
      border-radius: 50px;
    }
  }

  &__email {
    margin: 30px 0 20px 0;

    &__title {
      margin-bottom: 15px;
      font-size: 13px;
      line-height: 15px;
    }
  }
}

.send-patient-invite-tabs-content {
  height: 530px;
  position: relative;
  max-height: calc(100vh - 180px);
}

.rounded-input {
  width: auto;
  min-height: 36px;
}

/deep/ .send-patient-invite .tabs__container {
  margin: 0 25px 20px;
  width: auto;
}

/deep/ .send-patient-invite .tabs {
  justify-content: flex-start;
  width: 100%;

  .tab {
    padding-left: 0;
    padding-right: 0;
    margin-right: 20px;
  }
}

.icon-close {
  padding: 10px;
  background-size: 12px;
}

@media (max-width: 767px) {
  /deep/ .send-patient-invite .tabs__container {
    margin: 0 0 20px;
  }

  /deep/ .send-patient-invite .tabs {
    .tab {
      top: 0;
      padding: 28px 0 7px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .send-email-header__title {
    font-size: 16px;
    line-height: 19px;
  }

  .send-patient-invite {
    background-color: $white;
  }

  /deep/ .rounded-input {
    margin-bottom: 0;
  }

  .send-patient-invite-tabs-content {
    height: calc(100vh - 230px);
    padding-bottom: 30px;
    min-height: unset;
  }

  .send-patient-email {
    font-size: 13px;
    line-height: 15px;
    background-color: $white;

    &__header {
      flex-direction: row-reverse;
      justify-content: flex-end;
      padding: 0 15px;
      font-size: 13px;
      line-height: 15px;
      color: $main-title-color;
      background-color: $background;

      .icon-close {
        margin-right: 16px;
      }
    }

    &__footer {
      justify-content: center;
      flex-wrap: wrap;
      padding: 16px 20px;
      box-shadow: 0px -6px 7px rgba(148, 166, 176, 0.15);

      .rounded-button-orange {
        margin-right: 0;
        font-size: 13px;
        margin-bottom: 10px;

        &__container {
          width: 100%;
        }

        &:first-child {
          margin-right: 20px;
        }

        &:last-child {
          margin: 0;
        }
      }
    }
  }
}

@media (max-width: 340px) {
  .send-patient-email {
    &__footer {
      flex-direction: column;

      .rounded-button-orange {
        &:first-child {
          margin-right: 0;
        }
      }
    }
  }
}
</style>
