<template>
  <div v-if="directionsOfUse" ref="scrollable-dropdown" class="summary-direction-of-use__container">
    <div v-if="directionsOfUse.morningRoutine" class="summary-direction-of-use__morning-routine">
      <span class="summary-direction-of-use__list-item-title">
        {{ $t('dashboard.use.morningRoutine') }}:
      </span>
      <span class="summary-direction-of-use__list-item-text">
        {{ directionsOfUse.morningRoutine }}
      </span>
    </div>

    <div v-if="directionsOfUse.eveningRoutine" class="summary-direction-of-use__evening-routine">
      <span class="summary-direction-of-use__list-item-title">
        {{ $t('dashboard.use.eveningRoutine') }}:
      </span>
      <span class="summary-direction-of-use__list-item-text">
        {{ directionsOfUse.eveningRoutine }}
      </span>
    </div>

    <div v-if="hasHowToApply" class="summary-direction-of-use__how-to-apply">
      <span class="summary-direction-of-use__list-item-title">
        {{ $t('dashboard.use.howToApply') }}:
      </span>
      <ul class="summary-direction-of-use__list">
        <li
          v-for="{ name, description } in directionsOfUse.howToApply"
          :key="name"
          class="summary-direction-of-use__list-item"
        >
          <span v-if="name" class="summary-direction-of-use__list-item-title">{{ name }}:</span>
          <span v-if="description" class="summary-direction-of-use__list-item-text">
            {{ description }}
          </span>
        </li>
      </ul>
    </div>

    <div v-if="hasTipsAndTricks" class="summary-direction-of-use__tips-and-tricks">
      <span class="summary-direction-of-use__list-item-title">
        {{ $t('dashboard.use.tipsAndTricks') }}:
      </span>
      <ul class="summary-direction-of-use__list">
        <li
          v-for="tip in directionsOfUse.tipsAndTricks"
          :key="tip"
          class="summary-direction-of-use__list-item"
        >
          <span class="summary-direction-of-use__list-item-text">
            {{ tip }}
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { isEmpty } from 'lodash';
import PerfectScrollbar from 'perfect-scrollbar';

export default {
  name: 'SummaryDirectionsOfUseTab',
  props: {
    directionsOfUse: {
      type: Object,
      default: null
    }
  },
  computed: {
    hasHowToApply() {
      return !isEmpty(this.directionsOfUse.howToApply);
    },
    hasTipsAndTricks() {
      return !isEmpty(this.directionsOfUse.tipsAndTricks);
    }
  },
  async mounted() {
    this.scrollbar = new PerfectScrollbar(this.$refs['scrollable-dropdown'], {
      suppressScrollX: true,
      wheelPropagation: false
    });
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/css/common/icons.css';
@import '../../../../assets/scss/helpers/colors';
@import '../../../../assets/scss/common/buttons';

.summary-direction-of-use {
  &__container {
    position: relative;
    max-height: 545px;
    padding: 25px 20px 0 10px;

    color: $text-color;
  }

  &__morning-routine,
  &__evening-routine,
  &__list-item {
    margin-bottom: 20px;
  }

  &__how-to-apply,
  &__tips-and-tricks {
    margin-bottom: 5px;
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__list-inner {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__list-item-title {
    font-size: 13px;
    line-height: 150%;
    color: $text-color;
    font-weight: bold;
    &_wide {
      display: block;
    }
  }

  &__list-item-text {
    font-size: 13px;
    line-height: 150%;
    color: $text-color;
    font-weight: 300;
  }
}

@media (max-width: 991px) and (max-height: 750px) {
  .summary-direction-of-use {
    &__container {
      max-height: calc(100vh - 140px);
    }
  }
}

@media (max-width: 767px) {
  .summary-direction-of-use {
    &__container {
      padding: 25px 19px 0 15px;

      font-size: 13px;
      line-height: 19px;
    }
  }
}
</style>
