<template>
  <div class="result flex-column">
    <modal-header-with-previous
      class="result__modal-header"
      current-modal-name="photo-analysis-result-update-modal"
      previous-modal-name="analysis-results-overview-modal"
      is-custom-close-handler
      @close-modal="beforeCloseModal"
    >
      <span slot="header-title" class="header__title">
        {{ $t('dashboard.photoAnalysis.label.stepOne') }}
      </span>
    </modal-header-with-previous>
    <main class="result__content">
      <section class="result__questions flex-column">
        <zoomable-photo v-if="photoUrl" class="zoomable-photo-mobile" :photo="photoUrl" />
        <div ref="age-group-container" class="field-container personal-data-select">
          <div class="field-container__title">
            {{ $t('dashboard.photoAnalysis.age') }}
            <i>({{ $t('dashboard.photoAnalysis.label.specify') }})</i>
          </div>
          <q-select
            group-label="ageGroup"
            group-values="ages"
            :group-select="false"
            :options="ageSelectOptions"
            :value="selectFieldsData.ageGroup"
            @input="onFieldChange('ageGroup', $event)"
          />
        </div>
        <div
          v-for="field in personalDataFields"
          :key="field.label"
          class="field-container personal-data-select"
        >
          <div class="field-container__title">
            {{ field.label }}
          </div>
          <span v-if="field.tooltip" v-tooltip="field.tooltip" class="custom-icon icon-info"></span>
          <q-select
            label="label"
            :allow-empty="false"
            :placeholder="$t('dashboard.medicalData.selectOption')"
            :value="selectFieldsData[field.propertyName]"
            :options="field.options"
            track-by="label"
            @input="onFieldChange(field.propertyName, $event)"
          />
        </div>
        <zoomable-photo
          v-if="photoUrl"
          class="zoomable-photo-desktop"
          :photo="photoUrl"
          type="big"
        />
      </section>
      <section ref="scores-section" class="result__scores">
        <div v-for="section in sectionsBinding" :key="section.name" class="result__section">
          <div class="section__container">
            <section class="section__score">
              <span class="section__section-name">{{ section.name }}</span>
              <diagnostic-dysfunction-slider
                :value="sectionsScores[section.propertyName]"
                :color="section.color"
                @change="updateScore(section.propertyName, $event)"
              />
            </section>
            <section class="section__questions">
              <div
                v-for="question in section.questions"
                :key="question.label"
                class="field-container question__field"
              >
                <div class="question__label">
                  {{ question.label }}
                </div>
                <q-select
                  label="label"
                  :allow-empty="false"
                  :placeholder="$t('dashboard.medicalData.selectOption')"
                  :value="selectFieldsData[question.propertyName]"
                  :options="question.options"
                  track-by="label"
                  @input="onFieldChange(question.propertyName, $event)"
                />
              </div>
            </section>
          </div>
        </div>
      </section>
    </main>
    <mobile-fixed-footer class="result__footer flex--center--center">
      <button slot="footer" class="rounded-button-white" @click="onNextClick">
        {{ $t('action.next') }}
      </button>
    </mobile-fixed-footer>

    <quit-photo-analysis-warning-modal class="onlyXS" return-to-opened-patient />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import PerfectScrollbar from 'perfect-scrollbar';
import { get } from 'lodash';

import ModalHeaderWithPrevious from '@/modules/dashboard/components/dashboard/common/ModalHeaderWithPrevious';
import QSelect from '@/modules/dashboard/components/common/Select';
import DiagnosticDysfunctionSlider from '@/modules/dashboard/components/common/DiagnosticDysfunctionSlider';
import MobileFixedFooter from '@/modules/dashboard/components/dashboard/common/MobileFixedFooter';
import ZoomablePhoto from '@/modules/dashboard/components/dashboard/create-treatment/photo-analysis/ZoomablePhoto';
import QuitPhotoAnalysisWarningModal from '@/modules/dashboard/components/dashboard/create-treatment/photo-analysis/QuitPhotoAnalysisWarningModal';

import SelectDataBinding from '@/components/common/SelectDataBinding';
import PhotoAnalysisResultSection from '@/components/common/PhotoAnalysisResultSection';
import RoutingInfo from '@/modules/dashboard/components/common/RoutingInfo';

import { fetchPatientPhotoByPhotoId } from '@/modules/dashboard/api';

import {
  buildImageUrl,
  getMedicalDataSelectOptionsWithLabel,
  getSelectOptionsWithLabel,
  handleRoutingInfo,
  isMobileDevice
} from '@/modules/dashboard/utils';

import { GENDER_VALUES } from '@/modules/questionnaire/api/constants';

import {
  ACTIVES,
  DYSFUNCTIONS_COLORS,
  PERSON_DATA_FIELDS_DEFAULT_VALUES,
  SECTION_DATA_FIELDS_DEFAULT_VALUES,
  PERSON_DATA_FIELDS_NAMES,
  SELECT_DATA_FIELDS
} from '@/modules/dashboard/api/constants';

import {
  PHOTOTYPE,
  SKIN_THICKNESS,
  YES_NO,
  NO_MODERATE_SEVERE,
  NO_MILD_MODERATE_SEVERE
} from '@/modules/dashboard/api/medicalData';

import { types } from '@/modules/dashboard/store/types';

const SELECT_OPTIONS_FIELDS = {
  genderSelectOptions: null,
  ageGroupOptions: null,
  phototypeSelectOptions: null,
  skinThinknessSelectOptions: null,
  yesNoSelectOptions: null,
  noModerateSevereSelectOptions: null,
  noMildModerateSevereSelectOptions: null
};

const PHOTO_ANALYSIS_SELECT_OPTIONS_BINDING = [
  { propertyName: 'genderSelectOptions', options: GENDER_VALUES, keyPrefix: 'dashboard.gender.' },
  {
    propertyName: 'skinThinknessSelectOptions',
    options: SKIN_THICKNESS,
    keyPrefix: 'dashboard.photoAnalysis.'
  }
];

const MEDICAL_DATA_SELECT_OPTIONS_BINDING = [
  { propertyName: 'phototypeSelectOptions', options: PHOTOTYPE },
  { propertyName: 'yesNoSelectOptions', options: YES_NO },
  { propertyName: 'noModerateSevereSelectOptions', options: NO_MODERATE_SEVERE },
  { propertyName: 'noMildModerateSevereSelectOptions', options: NO_MILD_MODERATE_SEVERE }
];

const SECTIONS_SCORE_DEFAULT_VALUES = {
  yellow: 0,
  pink: 0,
  red: 0,
  blue: 0,
  orange: 0,
  grey: 0,
  green: 0,
  brown: 0
};

const SECTIONS_SCORES_FIELDS = Object.keys(SECTIONS_SCORE_DEFAULT_VALUES);

const CLARIFICATION_ROUNTING_INFO = new RoutingInfo(
  'clarification-modal',
  'PhotoAnalysisClarification'
);

const FIELD_TO_OPTIONS_PROPERTY_BINDING = {
  gender: 'genderSelectOptions',
  phototype: 'phototypeSelectOptions',
  skinThickness: 'skinThinknessSelectOptions',
  hasAcneScars: 'yesNoSelectOptions',
  wrinklesDepth: 'noModerateSevereSelectOptions',
  hasRosacea1: 'yesNoSelectOptions',
  hasCouperose: 'yesNoSelectOptions',
  dryness: 'noMildModerateSevereSelectOptions',
  dehydration: 'noMildModerateSevereSelectOptions',
  hasAcneVulgaris: 'yesNoSelectOptions',
  hasAcneRosacea: 'yesNoSelectOptions',
  hasAcneNodular: 'yesNoSelectOptions',
  comesFromPIH: 'yesNoSelectOptions',
  comesFromAgeSpotsMelasma: 'yesNoSelectOptions',
  sensitiveSkin: 'yesNoSelectOptions'
};

export const AGE_GROUPS = [
  {
    ageGroup: 'young',
    // Ages from 12 to 19.
    ages: [...Array.from(Array(8)).keys()].map(key => key + 12)
  },
  {
    ageGroup: 'youngAdult',
    // Ages from 20 to 29.
    ages: [...Array.from(Array(10)).keys()].map(key => key + 20)
  },
  {
    ageGroup: 'adult',
    // Ages from 30 to 49.
    ages: [...Array.from(Array(20)).keys()].map(key => key + 30)
  },
  {
    ageGroup: 'senior',
    // Ages from 50 to 120.
    ages: [...Array.from(Array(71)).keys()].map(key => key + 50)
  }
];

const TOOLTIP_CONFIG = {
  sensitiveSkin: {
    content: 'dashboard.dysfunction.sensitiveSkin.hint'
  }
};

export default {
  name: 'PhotoAnalysisResultUpdate',
  components: {
    QuitPhotoAnalysisWarningModal,
    ZoomablePhoto,
    ModalHeaderWithPrevious,
    QSelect,
    DiagnosticDysfunctionSlider,
    MobileFixedFooter
  },
  data() {
    return {
      scrollbar: null,
      ageSelectOptions: [],
      personalDataFields: [],
      selectFieldsBinding: [],
      sectionsBinding: [],
      selectOptions: SELECT_OPTIONS_FIELDS,
      selectFieldsData: {
        ...PERSON_DATA_FIELDS_DEFAULT_VALUES,
        ...SECTION_DATA_FIELDS_DEFAULT_VALUES,
        ageGroup: null,
        ethnicity: null
      },
      sectionsScores: {
        ...SECTIONS_SCORE_DEFAULT_VALUES
      },
      ageGroupScrollbar: null,
      scoreSectionScrollbar: null,
      photoUrl: null
    };
  },
  computed: {
    ...mapGetters({
      currentPatientId: types.getters.CURRENT_PATIENT_ID,
      photoAnalysisPhotoId: types.getters.PHOTO_ANALYSIS_PHOTO_ID,
      initialUpdatedResults: types.getters.PHOTO_ANALYSIS_UPDATED_RESULTS
    })
  },
  async mounted() {
    if (!isMobileDevice()) {
      const ageGroupRef = this.$refs['age-group-container'].querySelector(
        '.multiselect__content-wrapper'
      );

      const scoreSectionRef = this.$refs['scores-section'];

      const scrollbarOptions = {
        suppressScrollX: true,
        wheelPropagation: false
      };

      this.ageGroupScrollbar = new PerfectScrollbar(ageGroupRef, scrollbarOptions);
      this.scoreSectionScrollbar = new PerfectScrollbar(scoreSectionRef, scrollbarOptions);
    }

    this.createSelectOptions();
    this.selectFieldsBinding = this.getSelectFieldsBinding();
    this.sectionsBinding = this.getSectionsBinding();
    this.setInitialResultData();
    this.personalDataFields = this.getPersonalDataFields();

    const { data: photo } = await fetchPatientPhotoByPhotoId(
      this.currentPatientId,
      this.photoAnalysisPhotoId
    );

    this.photoUrl = buildImageUrl(photo);
  },
  methods: {
    ...mapActions({
      updatePhotoAnalysisResults: types.actions.SET_PHOTO_ANALYSIS_UPDATED_RESULTS
    }),
    beforeCloseModal() {
      this.$modal.show('quit-photo-analysis-warning-modal', {
        noAnswerHandler: () => {
          this.$modal.show('photo-analysis-result-update-modal');
        }
      });

      this.$modal.hide('photo-analysis-result-update-modal');
    },
    setInitialResultData() {
      const { ethnicity, ageGroup } = this.initialUpdatedResults;
      const ageGroupValue = Number.isInteger(ageGroup)
        ? ageGroup
        : this.$t(`dashboard.photoAnalysis.label.${this.initialUpdatedResults.ageGroup}`);

      this.selectFieldsData.ethnicity = ethnicity;
      this.selectFieldsData.ageGroup = ageGroupValue;

      SELECT_DATA_FIELDS.forEach(fieldName => {
        const initialValue = this.initialUpdatedResults[fieldName];
        const optionsPropertyName = FIELD_TO_OPTIONS_PROPERTY_BINDING[fieldName];

        this.selectFieldsData[fieldName] =
          this.selectOptions[optionsPropertyName].find(({ value }) => value === initialValue) ||
          null;
      });

      SECTIONS_SCORES_FIELDS.forEach(scoreField => {
        this.sectionsScores[scoreField] = this.initialUpdatedResults[scoreField];
      });
    },
    getPersonalDataFields() {
      return PERSON_DATA_FIELDS_NAMES.map(field => ({
        ...this.selectFieldsBinding[field],
        tooltip: this.$t(get(TOOLTIP_CONFIG, [field, 'content']))
      }));
    },
    createSelectOptions() {
      this.ageSelectOptions = AGE_GROUPS.map(({ ageGroup, ages }) => ({
        ageGroup: this.$t(`dashboard.photoAnalysis.label.${ageGroup}`),
        ages
      }));

      PHOTO_ANALYSIS_SELECT_OPTIONS_BINDING.forEach(({ propertyName, options, keyPrefix }) => {
        this.selectOptions[propertyName] = getSelectOptionsWithLabel({
          $t: this.$t,
          options,
          keyPrefix
        });
      });

      MEDICAL_DATA_SELECT_OPTIONS_BINDING.forEach(binding => {
        this.selectOptions[binding.propertyName] = getMedicalDataSelectOptionsWithLabel(
          this.$t,
          binding.options
        );
      });
    },
    getSelectFieldsBinding() {
      const {
        genderSelectOptions,
        phototypeSelectOptions,
        skinThinknessSelectOptions,
        yesNoSelectOptions,
        noModerateSevereSelectOptions,
        noMildModerateSevereSelectOptions
      } = this.selectOptions;

      return {
        gender: new SelectDataBinding(
          this.$t('dashboard.patientCard.gender'),
          'gender',
          genderSelectOptions
        ),
        phototype: new SelectDataBinding(
          this.$t('dashboard.medicalData.phototype'),
          'phototype',
          phototypeSelectOptions
        ),
        skinThickness: new SelectDataBinding(
          this.$t('dashboard.medicalData.skinThickness'),
          'skinThickness',
          skinThinknessSelectOptions
        ),
        hasAcneScars: new SelectDataBinding(
          this.$t('dashboard.photoAnalysis.label.acneScars'),
          'hasAcneScars',
          yesNoSelectOptions
        ),
        wrinklesDepth: new SelectDataBinding(
          this.$t('dashboard.photoAnalysis.label.wrinkles'),
          'wrinklesDepth',
          noModerateSevereSelectOptions
        ),
        hasRosacea1: new SelectDataBinding(
          this.$t('dashboard.photoAnalysis.label.rosacea'),
          'hasRosacea1',
          yesNoSelectOptions
        ),
        hasCouperose: new SelectDataBinding(
          this.$t('dashboard.photoAnalysis.label.couperose'),
          'hasCouperose',
          yesNoSelectOptions
        ),
        dryness: new SelectDataBinding(
          this.$t('dashboard.photoAnalysis.label.dryness'),
          'dryness',
          noMildModerateSevereSelectOptions
        ),
        dehydration: new SelectDataBinding(
          this.$t('dashboard.photoAnalysis.label.dehydration'),
          'dehydration',
          noMildModerateSevereSelectOptions
        ),
        hasAcneVulgaris: new SelectDataBinding(
          this.$t('dashboard.photoAnalysis.label.acneVulgaris'),
          'hasAcneVulgaris',
          yesNoSelectOptions
        ),
        hasAcneRosacea: new SelectDataBinding(
          this.$t('dashboard.photoAnalysis.label.acneRosacea'),
          'hasAcneRosacea',
          yesNoSelectOptions
        ),
        hasAcneNodular: new SelectDataBinding(
          this.$t('dashboard.photoAnalysis.label.acneNodular'),
          'hasAcneNodular',
          yesNoSelectOptions
        ),
        comesFromPIH: new SelectDataBinding(
          this.$t('dashboard.photoAnalysis.label.pih'),
          'comesFromPIH',
          yesNoSelectOptions
        ),
        comesFromAgeSpotsMelasma: new SelectDataBinding(
          this.$t('dashboard.photoAnalysis.ageSpot'),
          'comesFromAgeSpotsMelasma',
          yesNoSelectOptions
        ),
        sensitiveSkin: new SelectDataBinding(
          this.$t('dashboard.dysfunction.sensitiveSkin.title'),
          'sensitiveSkin',
          yesNoSelectOptions
        )
      };
    },
    getSectionsBinding() {
      const {
        hasAcneScars,
        wrinklesDepth,
        hasRosacea1,
        hasCouperose,
        dryness,
        dehydration,
        hasAcneVulgaris,
        hasAcneRosacea,
        hasAcneNodular,
        comesFromPIH,
        comesFromAgeSpotsMelasma
      } = this.selectFieldsBinding;

      return [
        new PhotoAnalysisResultSection({
          name: this.$t(`dashboard.diagnostic.${ACTIVES.OXIDATIVE_STRESS}`),
          propertyName: 'yellow',
          color: DYSFUNCTIONS_COLORS[ACTIVES.OXIDATIVE_STRESS]
        }),
        new PhotoAnalysisResultSection({
          name: this.$t(`dashboard.diagnostic.${ACTIVES.SKIN_APPEARANCE}`),
          propertyName: 'pink',
          color: DYSFUNCTIONS_COLORS[ACTIVES.SKIN_APPEARANCE],
          questions: [hasAcneScars, wrinklesDepth]
        }),
        new PhotoAnalysisResultSection({
          name: this.$t(`dashboard.diagnostic.${ACTIVES.SKIN_REDNESS}`),
          propertyName: 'red',
          color: DYSFUNCTIONS_COLORS[ACTIVES.SKIN_REDNESS],
          questions: [hasRosacea1, hasCouperose]
        }),
        new PhotoAnalysisResultSection({
          name: this.$t(`dashboard.diagnostic.${ACTIVES.SKIN_DRYNESS}`),
          propertyName: 'blue',
          color: DYSFUNCTIONS_COLORS[ACTIVES.SKIN_DRYNESS],
          questions: [dryness, dehydration]
        }),
        new PhotoAnalysisResultSection({
          name: this.$t(`dashboard.diagnostic.${ACTIVES.SKIN_OILINESS}`),
          propertyName: 'orange',
          color: DYSFUNCTIONS_COLORS[ACTIVES.SKIN_OILINESS]
        }),
        new PhotoAnalysisResultSection({
          name: this.$t(`dashboard.diagnostic.${ACTIVES.SKIN_TEXTURE}`),
          propertyName: 'grey',
          color: DYSFUNCTIONS_COLORS[ACTIVES.SKIN_TEXTURE]
        }),
        new PhotoAnalysisResultSection({
          name: this.$t(`dashboard.diagnostic.${ACTIVES.PIMPLES}`),
          propertyName: 'green',
          color: DYSFUNCTIONS_COLORS[ACTIVES.PIMPLES],
          questions: [hasAcneVulgaris, hasAcneRosacea, hasAcneNodular]
        }),
        new PhotoAnalysisResultSection({
          name: this.$t(`dashboard.diagnostic.${ACTIVES.SKIN_PIGMENTATION}`),
          propertyName: 'brown',
          color: DYSFUNCTIONS_COLORS[ACTIVES.SKIN_PIGMENTATION],
          questions: [comesFromPIH, comesFromAgeSpotsMelasma]
        })
      ];
    },
    onFieldChange(propertyName, option) {
      this.selectFieldsData[propertyName] = option;
    },
    updateScore(propertyName, value) {
      this.sectionsScores[propertyName] = value;
    },
    async onNextClick() {
      const selectFieldsValues = Object.entries(this.selectFieldsData).reduce(
        (values, [key, option]) => {
          let value = get(option, 'value', option);

          if (key === 'ageGroup' && !Number.isInteger(value)) {
            value = this.initialUpdatedResults.ageGroup;
          }

          return {
            ...values,
            [key]: value
          };
        },
        {}
      );

      await this.updatePhotoAnalysisResults({ ...selectFieldsValues, ...this.sectionsScores });

      handleRoutingInfo(CLARIFICATION_ROUNTING_INFO, this.$router, this.$modal);
      this.closeModal();
    },
    closeModal() {
      this.$modal.hide('photo-analysis-result-update-modal');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~perfect-scrollbar/css/perfect-scrollbar.css';
@import '../../../../../../assets/css/common/perfect-scrollbar-styles';
@import '../../../../../../assets/scss/common/buttons';
@import '../../../../../../assets/scss/helpers/colors';
@import '../../../../../../assets/css/common/icons.css';
@import '../../../../../../assets/css/common/tooltips.css';

.result {
  &__content {
    height: 580px;

    display: flex;

    padding-top: 30px;

    background-color: $white;
    color: $text-color;
  }

  &__questions {
    width: 350px;
    padding-right: 20px;
  }

  &__scores {
    width: 450px;
    position: relative;

    padding-right: 40px;
  }

  &__section {
    width: 100%;

    display: flex;
    justify-content: center;

    padding: 20px 0;

    &:not(:last-child) {
      border-bottom: 1px solid $dim-white;
    }
  }

  &__footer {
    height: 70px;

    background-color: $white;
    border-top: 1px solid $dim-white;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}

.header {
  &__step {
    color: $grey-color;
    font-weight: bold;
  }
}

.section {
  &__container {
    width: 410px;

    display: flex;
    flex-direction: column;
    align-items: flex-end;

    padding: 0 60px 0 5px;
  }

  &__section-name {
    font-size: 15px;
    line-height: 17px;

    margin-right: 15px;

    color: $text-color;
    text-align: right;
  }

  &__score {
    display: flex;
    align-items: center;
  }
}

.field-container {
  width: 100%;
  height: 36px;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  margin-bottom: 5px;

  &__title {
    font-size: 13px;
    text-align: right;
    font-weight: 300;
  }

  .select-container {
    min-width: 180px;
    width: 180px;

    margin-left: 35px;
  }

  &.personal-data-select {
    .select-container {
      min-width: 180px;
      width: 180px;
      margin-right: 0;
    }
  }
}

.zoomable-photo-desktop {
  margin: 15px 0 0 40px;
}

.question {
  &__label {
    font-size: 13px;
    text-align: right;
    font-weight: 300;
  }

  &__field {
    position: relative;
    right: -17px;

    &:first-child {
      margin-top: 15px;
    }

    .select-container {
      margin-left: 47px;
    }
  }
}

@media (min-width: 768px) {
  .zoomable-photo-mobile {
    display: none;
  }

  .result {
    &__section {
      &:nth-child(3) {
        padding-top: 6px;
      }
    }
  }
}

@media (max-width: 767px) {
  .result {
    min-height: 100vh;

    &__modal-header {
      position: fixed;
      top: 0;

      z-index: 51;

      background-color: $background;
    }

    &__content {
      height: auto;

      flex-direction: column;
      flex: 1;

      padding-top: 70px;
    }

    &__questions {
      width: 100%;
      align-items: center;

      padding-right: 0;
    }

    &__scores {
      width: 100%;

      display: flex;
      flex-direction: column;
      align-items: center;

      padding: 0 0 60px 0;
    }

    &__footer {
      height: 60px;

      z-index: 50;

      border-top: 0;
      box-shadow: 0px -6px 7px rgba(148, 166, 176, 0.15);
    }
  }

  .header {
    &__title,
    &__step {
      color: $main-title-color;
      font-weight: initial;
    }
  }

  .section {
    &__container {
      width: 320px;
    }

    &__score {
      justify-content: flex-end;

      margin-bottom: 0;
    }

    &__section-name {
      font-size: 13px;
      line-height: 15px;
    }

    &__questions {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .select-container {
        margin-left: 12px;
      }
    }
  }

  .field-container {
    width: 260px;

    &__title {
      font-weight: normal;
    }

    .select-container {
      margin: 0 -13px 0 5px;
    }
  }

  .zoomable-photo-desktop {
    display: none;
  }

  .zoomable-photo-mobile {
    margin-bottom: 30px;
  }

  .question {
    &__label {
      font-size: 12px;
    }

    &__field {
      width: 260px;
    }
  }
}
</style>
