<template>
  <div class="upload-photo__container flex-column--align-center">
    <button class="upload-photo rounded-button-white" @click="handleUploadClick">
      {{ $t('dashboard.button.uploadPhoto') }}
    </button>
    <web-camera
      v-if="isWebSectionVisible"
      class="web-camera"
      @activate-web-camera="activateWebCameraMode"
      @files-uploaded="uploadFiles"
    />
    <input
      ref="files"
      type="file"
      accept="image/*"
      class="d-none"
      @change="uploadFiles($event.target.files)"
    />

    <photo-validation-modal class="onlyXS" @try-again="tryAgain" />
    <image-use-consent-modal :has-image-store-options="isPhotoAnalysis" />
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import WebCamera from '@/modules/dashboard/components/dashboard/common/WebCamera';
import PhotoValidationModal from '@/modules/dashboard/components/dashboard/common/PhotoValidationModal';
import ImageUseConsentModal from '@/modules/dashboard/components/dashboard/create-treatment/photo-analysis/ImageUseConsentModal';

import { isAndroidDevice } from '@/modules/questionnaire/utils';
import { isAppleMobileDevice } from '@/utils/featureDetection';
import { validatePhotoFile } from '@/modules/dashboard/utils';

import rootTypes from '@/store/types';
import { types as dashTypes } from '@/modules/dashboard/store/types';

const UPLOAD_PHOTO_MODS = {
  FILE_UPLOAD: 'fileUpload',
  WEB_CAMERA_PHOTO: 'webCameraPhoto'
};

export default {
  name: 'UploadPhoto',
  components: { ImageUseConsentModal, PhotoValidationModal, WebCamera },
  props: {
    isPhotoAnalysis: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      activeMode: null
    };
  },
  computed: {
    isWebSectionVisible() {
      return !isAppleMobileDevice() && !isAndroidDevice();
    }
  },
  methods: {
    ...mapActions({
      setLoading: rootTypes.actions.SET_LOADING,
      uploadPhoto: dashTypes.actions.UPLOAD_CURRENT_PATIENT_PHOTO
    }),
    handleUploadClick() {
      this.activeMode = UPLOAD_PHOTO_MODS.FILE_UPLOAD;
      this.$refs.files.click();
    },
    async uploadFiles(files) {
      if (!files || files.length === 0) {
        return;
      }

      const [file] = files;
      const validationError = validatePhotoFile(file);

      this.$refs.files.value = '';

      if (validationError) {
        this.$emit('handle-photo-validation-error');
        this.$modal.show('photo-validation-modal', { validationError });

        return;
      }

      const { imageUse, storeImageOption, clinicResearch } = await this.getImageUseConsents();

      if (!imageUse) {
        return;
      }

      this.setLoading(true);
      await this.uploadPhoto({
        file,
        isPhotoAnalysis: true,
        storePhoto: this.isPhotoAnalysis ? storeImageOption : true,
        allowResearch: clinicResearch
      });
      this.setLoading(false);

      this.$emit('photo-uploaded');
    },
    activateWebCameraMode() {
      this.activeMode = UPLOAD_PHOTO_MODS.WEB_CAMERA_PHOTO;
    },
    tryAgain() {
      switch (this.activeMode) {
        case UPLOAD_PHOTO_MODS.FILE_UPLOAD: {
          this.handleUploadClick();
          break;
        }
        case UPLOAD_PHOTO_MODS.WEB_CAMERA_PHOTO: {
          this.$modal.show('web-camera-modal');
          break;
        }
        default: {
          this.handleUploadClick();
        }
      }
    },
    getImageUseConsents() {
      return new Promise(resolve => {
        this.$modal.show('image-use-consent-modal', { consentsResolver: resolve });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../assets/scss/common/buttons.scss';

.upload-photo {
  margin-bottom: 35px;
}

@media (max-width: 1024px) {
  .web-camera {
    display: none;
  }
}

@media (max-width: 767px) {
  .upload-photo {
    margin-bottom: 0;
  }
}
</style>
