import { render, staticRenderFns } from "./FaceIconWithLabel.vue?vue&type=template&id=3984b03a&scoped=true&"
import script from "./FaceIconWithLabel.vue?vue&type=script&lang=js&"
export * from "./FaceIconWithLabel.vue?vue&type=script&lang=js&"
import style0 from "./FaceIconWithLabel.vue?vue&type=style&index=0&id=3984b03a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3984b03a",
  null
  
)

export default component.exports