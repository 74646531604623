<template>
  <div class="treatment flex--space-between--center pointer" :class="{ active: isActive }">
    <div class="treatment__title flex--align-center">
      <p class="first-letter" :class="{ 'first-letter--dashed': isTreatmentOffer }">
        {{ treatmentNumber }}
        <span v-if="shouldDisplayTreatmentTag" class="treatment__tag" :class="treatmentTagClasses">
          {{ $t(`dashboard.tag.${treatmentTagKeyPostfix}`) }}
        </span>
      </p>
      <span class="treatment__title__label">{{ treatmentLabel }}</span>
      <div v-if="basedOnLabel" class="treatment__based-container flex--align-center">
        <span class="treatment__based-text">{{ $t('dashboard.label.basedOn') }}</span>
        <p
          class="first-letter"
          :class="{
            'treatment__photo-diagnostic-circle': isPhotoBasedDiagnostic,
            'treatment__treatment-directory-circle': isBasedOnTreatmentDirectory
          }"
        >
          {{ basedOnLabel }}
        </p>
      </div>
    </div>
    <div class="treatment__detail flex--align-center">
      <div class="custom-icon" :class="statusIcon"></div>
      <span class="fas fa-chevron-right more-info-icon"></span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { first } from 'lodash';

import { types as dashTypes } from '@/modules/dashboard/store/types';

import {
  DIAGNOSTIC_TYPES,
  TREATMENT_ICONS,
  TREATMENT_ORDERS_STATUSES,
  TREATMENT_STATUSES,
  TREATMENT_TYPES
} from '@/modules/dashboard/api/constants';

export default {
  name: 'Treatment',
  props: {
    treatment: {
      type: Object,
      required: true
    },
    treatmentDiagnostic: {
      type: Object,
      required: true
    },
    number: {
      type: Number,
      required: true
    },
    isActive: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    ...mapGetters({
      treatments: dashTypes.getters.CURRENT_PATIENT_TREATMENTS
    }),
    isNewTreatment() {
      return this.treatment.statusEvent === TREATMENT_STATUSES.TREATMENT_STARTS_IN;
    },
    isTreatmentOffer() {
      const { orderStatus } = this.treatment;

      return (
        this.isNewTreatment && orderStatus === TREATMENT_ORDERS_STATUSES.TREATMENT_OFFER_CREATED
      );
    },
    diagnosticNumber() {
      return this.treatmentDiagnostic.number && `D${this.treatmentDiagnostic.number}`;
    },
    isPhotoBasedDiagnostic() {
      return this.treatmentDiagnostic.type === DIAGNOSTIC_TYPES.PHOTO_BASED;
    },
    isBasedOnTreatmentDirectory() {
      return this.treatment.type === TREATMENT_TYPES.TREATMENT_DIRECTORY;
    },
    basedOnLabel() {
      return this.isBasedOnTreatmentDirectory ? 'TD' : this.diagnosticNumber;
    },
    treatmentNumber() {
      return `T${this.number}`;
    },
    orderStatus() {
      return this.treatment.orderStatus;
    },
    treatmentLabel() {
      const labelKeyPostfix = this.isTreatmentOffer ? 'label.treatmentOffer' : 'details.treatment';
      const treatmentLabelPrefix = this.$t(`dashboard.${labelKeyPostfix}`);

      return `${treatmentLabelPrefix} ${this.number}`;
    },
    isTreatmentPaidOffline() {
      return this.orderStatus === TREATMENT_ORDERS_STATUSES.TREATMENT_PAID_OFFLINE;
    },
    isOrderCancelled() {
      return this.orderStatus === TREATMENT_ORDERS_STATUSES.CANCELLED;
    },
    treatmentTagKeyPostfix() {
      if (this.isOrderCancelled) {
        return 'cancelled';
      }

      return this.isTreatmentPaidOffline ? 'paidAtClinic' : 'paidOnline';
    },
    treatmentTagClasses() {
      return {
        'treatment__tag--paid-online': !this.isTreatmentPaidOffline,
        'treatment__tag--cancelled': this.isOrderCancelled
      };
    },
    shouldDisplayTreatmentTag() {
      return this.treatment.paid || this.isOrderCancelled;
    },
    isLastExpired() {
      const { id: lastTreatmentId } = first(this.treatments);
      const { expired, id: currentTreatmentId } = this.treatment;

      return expired && lastTreatmentId === currentTreatmentId;
    },
    statusIcon() {
      const { orderStatus, statusEvent } = this.treatment;

      if (this.isOrderCancelled) {
        return TREATMENT_ICONS.ICON_CANCELLED;
      }

      const isUnderPreparation = [
        TREATMENT_ORDERS_STATUSES.UNDER_PREPARATION,
        TREATMENT_ORDERS_STATUSES.RESEND_OPERATION
      ].includes(orderStatus);

      if (isUnderPreparation) {
        return TREATMENT_ICONS.ICON_UNDER_PREPARATION;
      }

      if (this.isNewTreatment) {
        return this.getNewTreatmentIcon(orderStatus);
      }

      if (this.isLastExpired) {
        return TREATMENT_ICONS.ICON_EXPIRED;
      }

      if (statusEvent === TREATMENT_STATUSES.ONGOING_TREATMENT) {
        return TREATMENT_ICONS.ICON_STARTED_GRAY;
      }

      return TREATMENT_ICONS.ICON_EXPIRED_GRAY;
    }
  },
  methods: {
    getNewTreatmentIcon(orderStatus) {
      if (orderStatus === TREATMENT_ORDERS_STATUSES.TREATMENT_OFFER_CREATED) {
        return TREATMENT_ICONS.ICON_OFFER_SENT;
      }

      return TREATMENT_ICONS.ICON_CLOCKS;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/common/first-letter';
@import '../../../../assets/scss/helpers/colors';
@import '../../../../assets/css/common/errors.css';

.treatment {
  min-height: 60px;
  padding: 10px 25px;

  &__title {
    flex-wrap: wrap;

    &__label {
      font-size: 15px;
      font-weight: bold;
      line-height: 19px;
      margin-left: 10px;
      margin-right: 15px;
      color: $main-title-color;
    }
  }

  &__based-text {
    margin-right: 6px;
  }

  &__photo-diagnostic-circle {
    background-color: $cream-color;
  }

  &__treatment-directory-circle {
    font-weight: 500;
    background-color: $concrete-light;
    border-color: $concrete-light;
  }

  &__tag {
    position: absolute;
    left: 24px;
    top: -4px;

    padding: 2px 6px 1px 7px;

    font-size: 10px;
    line-height: 12px;
    font-weight: 500;
    color: $green-color;
    text-transform: none;

    border-radius: 10px;
    background-color: $light-green-color;

    white-space: nowrap;

    &--paid-online {
      color: $danube;
      background-color: $onahau;
    }

    &--cancelled {
      color: $error-color;
      background-color: $azalea-color;
    }
  }
}

.more-info-icon {
  color: $light-grey-color;
  margin-left: 30px;
  font-size: 12px;
}

.validated {
  color: $icon-dim-grey-color;
}

@media (min-width: 768px) {
  .active {
    background-color: $white;
    border-radius: 8px 0 0 8px;
  }
}

@media (max-width: 767px) {
  .treatment {
    padding: 0 17px 0 13px;

    &__title {
      &__label {
        min-width: 85px;
        margin: 0 7px 0 12px;

        font-size: 12px;
        line-height: 14px;
      }
    }

    &__tag {
      top: -6px;
      left: 18px;

      padding: 1px 4px 0 5px;

      min-width: 30px;
      line-height: 12px;
    }

    &__based-text {
      font-size: 12px;
      font-weight: 300;
      line-height: 18px;
    }
  }

  .first-letter {
    position: relative;
    font-size: 12px;
    font-weight: 600;
    line-height: 29px;
  }

  .more-info-icon {
    margin-left: 20px;

    font-size: 9px;
    color: $dim-grey-color;
  }
}
</style>
